import noop from 'lodash/noop';
import React, { InputHTMLAttributes, MutableRefObject, ReactNode } from 'react';
import { DateInput } from '@ver-uds/uswds-react';
import { CustomClassValue } from '../../../utils/types';

export interface FormattedDateInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'className'> {
  id: string;
  label: string;
  errorMessage?: string;
  hint?: string;
  labelHint?: ReactNode;
  note?: ReactNode;
  required?: boolean;
  className?: CustomClassValue;
  monthRef?: MutableRefObject<HTMLInputElement | null> | ((instance: HTMLInputElement | null) => void) | null;
  formattedDate?: string;
  onUpdate?: (value: string) => void;
}

export default function FormattedDateInput({
  id,
  label,
  errorMessage = undefined,
  hint = undefined,
  labelHint = undefined,
  note = undefined,
  required = false,
  className = undefined,
  monthRef = undefined,
  formattedDate = '--',
  onUpdate = noop,
}: FormattedDateInputProps): React.JSX.Element {
  const [year, month, day] = formattedDate.split('-').map((segment) => segment);

  const isMonthField = (fieldName: string): boolean => fieldName === 'month';
  const isDayField = (fieldName: string): boolean => fieldName === 'day';

  const isSingleDigitMonth = (value: string): boolean => parseInt(value, 10) > 1 && parseInt(value, 10) < 10;
  const isSingleDigitDay = (value: string): boolean => parseInt(value, 10) > 3 && parseInt(value, 10) < 10;

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const dateParts: { [k: string]: string } = { year, month, day };
    if (isMonthField(event.target.name) && isSingleDigitMonth(event.target.value)) {
      dateParts[event.target.name] = `0${parseInt(event.target.value, 10)}`; // parseInt to remove any manually-entered leading 0 before adding one
    } else if (isDayField(event.target.name) && isSingleDigitDay(event.target.value)) {
      dateParts[event.target.name] = `0${parseInt(event.target.value, 10)}`; // parseInt to remove any manually-entered leading 0 before adding one
    } else {
      dateParts[event.target.name] = event.target.value;
    }
    onUpdate(`${dateParts.year}-${dateParts.month}-${dateParts.day}`);
  };

  // TODO: Replace DateInput with Memorable Date
  return (
    <DateInput
      monthRef={monthRef}
      id={id}
      label={label}
      errorMessage={errorMessage}
      hint={hint}
      labelHint={labelHint}
      note={note}
      required={required}
      className={className as string}
      year={year}
      month={month}
      day={day}
      onChange={handleOnChange}
    />
  );
}
