import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import EnvironmentVariables from './EnvironmentVariables';

const ENV_VARIABLES_FILE_PATH = `${process.env.PUBLIC_URL}/env.json`;

export interface EnvironmentState {
  isLoading: boolean;
  environmentVariables: EnvironmentVariables | null;
}

const DEFAULT_STATE: EnvironmentState = {
  isLoading: false,
  environmentVariables: null,
};

/**
 * Not intended for use outside of "mock" provider creation
 * See MockEnvironmentContext
 */
export const EnvironmentContext = React.createContext<EnvironmentState>(DEFAULT_STATE);

export const useEnvironment = (): EnvironmentState => useContext(EnvironmentContext);

export const EnvironmentConsumer = EnvironmentContext.Consumer;

export type EnvironmentProviderProps = {
  children: React.ReactNode;
};

export function EnvironmentProvider({ children }: EnvironmentProviderProps): React.JSX.Element {
  const [environmentState, setEnvironmentState] = useState<EnvironmentState>(DEFAULT_STATE);

  useEffect(() => {
    let mounted = true;
    const loadInEnvironmentVariables = async (): Promise<void> => {
      try {
        setEnvironmentState((prevState) => ({
          ...prevState,
          isLoading: true,
        }));

        const response = await axios.get<EnvironmentVariables>(ENV_VARIABLES_FILE_PATH);
        if (mounted) {
          setEnvironmentState({
            isLoading: false,
            environmentVariables: response.data,
          });
        }
      } catch (unusedError) {
        // fail silently
      }
    };

    loadInEnvironmentVariables();

    return (): void => {
      mounted = false;
    };
  }, []);

  return <EnvironmentContext.Provider value={environmentState}>{children}</EnvironmentContext.Provider>;
}
