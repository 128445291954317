import React from 'react';
import PageContentLayout from '../layouts/PageContentLayout/PageContentLayout';
import CreateAccount from '../components/CreateAccount/CreateAccount';

function CreateAccountPage(): React.JSX.Element {
  return (
    <PageContentLayout title="Create Account">
      <CreateAccount />
    </PageContentLayout>
  );
}

export default CreateAccountPage;
