import { Case, RawCaseStatus, ReadableCaseStatus } from '../../services/CaseApi';
import { mapRawCaseStatusToReadableCaseStatus } from '../../services/CaseApi/CaseApiUtil';

const openCaseStatuses: ReadableCaseStatus[] = [
  ReadableCaseStatus.CHECK_BACK_LATER,
  ReadableCaseStatus.CONTINUANCE,
  ReadableCaseStatus.EMPLOYEE_REFERRED_CONTINUANCE,
  ReadableCaseStatus.EMPLOYEE_REFERRED_DHS,
  ReadableCaseStatus.EMPLOYEE_REFERRED_SSA,
  ReadableCaseStatus.EMPLOYEE_REFERRED_DHS_AND_SSA,
  ReadableCaseStatus.EMPLOYEE_REFERRED_DHS_OR_SSA,
  ReadableCaseStatus.TENTATIVE_NONCONFIRMATION_DHS,
  ReadableCaseStatus.TENTATIVE_NONCONFIRMATION_SSA,
  ReadableCaseStatus.TENTATIVE_NONCONFIRMATION_DHS_AND_SSA,
  ReadableCaseStatus.TENTATIVE_NONCONFIRMATION_DHS_OR_SSA,
];

export interface OpenCaseStatus {
  uploadCount: number;
  caseStatus: RawCaseStatus | null;
}
export interface OpenCase extends Case {
  openCaseStatus: OpenCaseStatus;
}

/* eslint-disable import/prefer-default-export */
export const isCaseOpen = (caseData: Case): boolean => {
  const readableCaseStatus = mapRawCaseStatusToReadableCaseStatus(caseData.caseStatus);
  return openCaseStatuses.includes(readableCaseStatus);
};
