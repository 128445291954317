import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { get } from 'lodash';
import { Button, ButtonGroup, PageHeader } from '@ver-uds/react';
import { Card, CardGroup, Alert } from '@ver-uds/uswds-react';
import CaseTracker from '../components/CaseTracker/CaseTracker';
import CaseHistory from '../components/CaseHistory';
import { AuthenticationContext } from '../context/Authentication/AuthenticationContext';
import Hero from '../components/Hero/Hero';
import BasicLayout from '../layouts/BasicLayout/BasicLayout';
import myCasesLogo from '../assets/homepage-card-logos/mycases-logo.png';
import selfCheckLogo from '../assets/homepage-card-logos/selfcheck-logo.png';
import selfLockLogo from '../assets/homepage-card-logos/selflock-logo.png';
import CreateAccountButton from '../components/Actions/CreateAccountButton/CreateAccountButton';
import SignInButton from '../components/Actions/SignInButton';
import LoginRouter from '../routing/LoginRouter';
import WelcomeMessage from '../components/WelcomeMessage/WelcomeMessage';
import Divider from '../components/Divider/Divider';
import paths from '../routing/paths';
import { UserContext } from '../context/User/UserContext';
import { useEnvironment } from '../context/Environment/EnvironmentContext';
import MaintenanceBanner from '../components/MaintenanceBanner/MaintenanceBanner';
import IEDeprecationAlert from '../components/IEDeprecationAlert/IEDeprecationAlert';

const baseClassName = 'home-page';
const CLASS_NAMES = {
  base: baseClassName,
  caseTrackerSection: classNames(
    `${baseClassName}__case-tracker-section`,
    'usa-section',
    'bg-base-lightest',
    'tablet:padding-y-4',
  ),
  caseTracker: classNames(`${baseClassName}__case-tracker`, 'grid-container'),
};

function Home(): React.JSX.Element {
  const { isAuthenticated } = useContext(AuthenticationContext);
  const [isMaintenanceModeEnabled, setMaintenanceModeEnabled] = useState(false);
  const { environmentVariables } = useEnvironment();
  const { userInfoResponseData } = useContext(UserContext);
  const location = useLocation();
  const caseTrackerRef = useRef<HTMLElement>(null);
  const navigate = useNavigate();
  const bypassLoginRouting = get(navigate, 'location.state.bypassLoginRouting');

  useEffect(() => {
    if (environmentVariables?.IS_MAINTENANCE_MODE_ENABLED) {
      setMaintenanceModeEnabled(environmentVariables?.IS_MAINTENANCE_MODE_ENABLED);
    }
  }, [environmentVariables, setMaintenanceModeEnabled]);

  useLayoutEffect(() => {
    if (caseTrackerRef && caseTrackerRef.current && location.hash === '#case-tracker') {
      caseTrackerRef.current.scrollIntoView();
    }
  }, [caseTrackerRef, location]);

  return (
    <>
      {isAuthenticated() && !bypassLoginRouting && <LoginRouter />}
      <BasicLayout title="Home">
        {!isAuthenticated() && (
          <Alert status="info" slim>
            <Alert.Text>
              myE-Verify enables you to view your E-Verify case history, resolve cases, lock your SSN, and confirm your
              employment eligibility. myE-Verify does NOT enable you to change your citizenship or immigration status.
            </Alert.Text>
          </Alert>
        )}
        {!isAuthenticated() && isMaintenanceModeEnabled && <MaintenanceBanner />}
        {(!isAuthenticated() && <Hero />) || <PageHeader>Home</PageHeader>}
        <section ref={caseTrackerRef} className={CLASS_NAMES.caseTrackerSection}>
          <div className={CLASS_NAMES.caseTracker}>
            {isAuthenticated() && <WelcomeMessage />}
            <IEDeprecationAlert />
            {!isMaintenanceModeEnabled && <CaseTracker />}
          </div>
        </section>
        <div className="grid-container">
          {isAuthenticated() && (
            <div className="usa-section padding-top-3">
              {!userInfoResponseData.isLoading && !userInfoResponseData.isPivotRecordNull && (
                <CaseHistory className="margin-top-3" />
              )}
              <div className="margin-bottom-4 margin-top-6">
                <h2>Self Lock</h2>
                <p>Lock my Social Security number.</p>
                <Button variant="secondary" as="a" href={paths.selfLock}>
                  Manage my SSN
                </Button>
              </div>
              <Divider />
              <div className="margin-top-3">
                <h2>Self Check</h2>
                <p>Confirm my employment eligibility.</p>
                <Button variant="secondary" as="a" href={paths.selfCheck}>
                  Verify with Self Check
                </Button>
              </div>
            </div>
          )}
          {!isAuthenticated() && (
            <>
              <div style={{ textAlign: 'center', paddingBottom: '50px', paddingTop: '50px' }}>
                <h2>Benefits of myE-Verify</h2>
                <p>
                  myE‑Verify has something of value for everyone who works or is looking for a job in the United States.
                </p>
              </div>
              <CardGroup>
                <Card className="tablet:grid-col-6 flex-1">
                  <Card.Header as="h3">Self Lock</Card.Header>
                  <Card.Media>
                    <img src={selfLockLogo} alt="" />
                  </Card.Media>
                  <Card.Body>
                    <p>
                      Protect your identity by preventing unauthorized use of your Social Security number in E-Verify.
                    </p>
                  </Card.Body>
                </Card>
                <Card className="tablet:grid-col-6 flex-1">
                  <Card.Header as="h3">My Cases</Card.Header>
                  <Card.Media>
                    <img src={myCasesLogo} alt="" />
                  </Card.Media>
                  <Card.Body>
                    <p>
                      Track open cases and upload documents to E-Verify. See past use of your Social Security number in
                      E-Verify and Self Check.
                    </p>
                  </Card.Body>
                </Card>
                <Card className="tablet:grid-col-6 flex-1">
                  <Card.Header as="h3">Self Check</Card.Header>
                  <Card.Media>
                    <img src={selfCheckLogo} alt="" />
                  </Card.Media>
                  <Card.Body>
                    <p>
                      Verify your employment eligibility records are in order, and, if a mismatch occurs, learn how to
                      make updates.
                    </p>
                  </Card.Body>
                </Card>
              </CardGroup>
              <div style={{ paddingBottom: '50px' }}>
                <p style={{ textAlign: 'center' }}>To use these features: </p>
                <ButtonGroup className="flex-justify-center">
                  <CreateAccountButton />
                  <SignInButton />
                </ButtonGroup>
              </div>
            </>
          )}
        </div>
      </BasicLayout>
    </>
  );
}

export default Home;
