import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { usePatchUserInfo, UserInfoUpdateRequest } from '../../services/UserApi';
import { ConfirmIdentityFormData } from './IdentityConstants';
import paths from '../../routing/paths';
import ConfirmIdentityMarkup from './ConfirmIdentityMarkup';

function ConfirmIdentity(): React.JSX.Element {
  const [prevEnteredData, setPrevEnteredData] = useState<ConfirmIdentityFormData>({});

  const {
    error: patchUserInfoError,
    mutate: patchUserInfoMutate,
    isPending: isSubmissionLoading,
    isSuccess: isSubmissionSuccess,
  } = usePatchUserInfo();

  const submitUpdateUserInformation = (data: UserInfoUpdateRequest): void => {
    setPrevEnteredData(data);
    patchUserInfoMutate(data);
  };

  const getError = (): Error | undefined => {
    if (patchUserInfoError) {
      return patchUserInfoError;
    }
    return undefined;
  };

  const getPrevEnteredData = (): ConfirmIdentityFormData | undefined => {
    if (Object.keys(prevEnteredData).length > 0) {
      return prevEnteredData;
    }
    return undefined;
  };

  if (isSubmissionSuccess) {
    return <Navigate to={paths.home} state={{ bypassLoginRouting: true }} />;
  }

  return (
    <div>
      {!isSubmissionLoading && (
        <ConfirmIdentityMarkup
          error={getError()}
          onSubmit={submitUpdateUserInformation}
          prevEnteredData={getPrevEnteredData()}
        />
      )}
    </div>
  );
}

export default ConfirmIdentity;
