import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { SelfCheckForm } from '../../context/Form/SelfCheck/SelfCheckFormContext';
import paths from '../../routing/paths';
import { useGetOpenSelfCheckCaseQuery } from '../../services/CaseApi';
import { CaseStatus, SelfCheckCaseResponseData, useGetCaseDetailsByCaseNumberQuery } from '../../services/SelfCheckApi';
import { SelfLockState, useSelfLockQuery } from '../../services/SelfLockApi';
import { injectURLParams } from '../../utils/routing';
import withSelfCheckFormConsumer from './hoc/withSelfCheckFormConsumer';
import SelfCheckFormContainer, { hasFormBeenStarted } from './SelfCheckForm/SelfCheckFormContainer';
import { REVERIFY_CASE_STATUS } from './SelfCheckForm/SelfCheckFormReview/SelfCheckFormReviewMarkup';
import SelfCheckNoticeModal from './SelfCheckNoticeModal';

export interface SelfCheckOpenCaseGateProps extends SelfCheckForm {
  caseNumber?: string;
}

const isCaseStatusMatch = (selfCheckCase?: SelfCheckCaseResponseData, caseStatus?: CaseStatus): boolean =>
  selfCheckCase?.caseStatus === caseStatus;

const isCaseClosed = (selfCheckCase?: SelfCheckCaseResponseData): boolean =>
  isCaseStatusMatch(selfCheckCase, CaseStatus.CLOSED);

const isCaseReverify = (selfCheckCase?: SelfCheckCaseResponseData): boolean =>
  isCaseStatusMatch(selfCheckCase, REVERIFY_CASE_STATUS);

const isCaseFNC = (selfCheckCase?: SelfCheckCaseResponseData): boolean =>
  isCaseStatusMatch(selfCheckCase, CaseStatus.FINAL_NONCONFIRMATION);

const isCaseCloseAndResubmit = (selfCheckCase?: SelfCheckCaseResponseData): boolean =>
  isCaseStatusMatch(selfCheckCase, CaseStatus.CLOSE_CASE_AND_RESUBMIT);

function SelfCheckOpenCaseGate({
  caseNumber = undefined,
  formData,
  ...selfCheckFormProps
}: SelfCheckOpenCaseGateProps): React.JSX.Element {
  const {
    data: selfCheckCase,
    isError,
    isFetching,
    isLoading,
    isSuccess,
  } = useGetCaseDetailsByCaseNumberQuery(caseNumber);
  const isIdle = !isError && !isFetching && !isLoading && !isSuccess;

  if (isError) {
    return <Navigate to={paths.error} />;
  }

  const showReverifyView = isCaseReverify(selfCheckCase) && hasFormBeenStarted(formData);
  if (
    !caseNumber ||
    isCaseClosed(selfCheckCase) ||
    isCaseFNC(selfCheckCase) ||
    isCaseCloseAndResubmit(selfCheckCase) ||
    showReverifyView
  ) {
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    return <SelfCheckFormContainer {...selfCheckFormProps} formData={formData} />;
  }

  return <div>{!isIdle && !isLoading && <Navigate to={injectURLParams(paths.caseDetail, { caseNumber })} />}</div>;
}

const withOpenSelfCheckCase = (
  Component: (
    props: {
      caseNumber?: string;
    } & SelfCheckForm,
  ) => React.JSX.Element,
) =>
  function OpenSelfCheckCase(selfCheckFormProps: SelfCheckForm): React.JSX.Element {
    const { data: openCase, isError, isFetching, isLoading, isSuccess, error } = useGetOpenSelfCheckCaseQuery();
    const isIdle = !isError && !isFetching && !isLoading && !isSuccess;
    const navigate = useNavigate();
    const { data } = useSelfLockQuery();
    const [isSelfCheckNoticeModal, setIsSelfCheckNoticeModalsetIsHelpModalOpen] = useState(false);

    useEffect(() => {
      setIsSelfCheckNoticeModalsetIsHelpModalOpen(data?.state === SelfLockState.LOCKED);
    }, [data]);

    if (!isSelfCheckNoticeModal && isError) {
      /* eslint-disable-next-line no-console */
      console.log('The useGetOpenSelfCheckCaseQuery action Encountered an Error: ', error);
      return <Navigate to={paths.error} />;
    }

    const handleSelfCheckModalClose = (): void => {
      navigate(paths.home);
      setIsSelfCheckNoticeModalsetIsHelpModalOpen(false);
    };

    return (
      <div>
        {isSelfCheckNoticeModal ? (
          <SelfCheckNoticeModal isOpen={isSelfCheckNoticeModal} handleClose={handleSelfCheckModalClose} />
        ) : (
          <div>
            {!isIdle && !isLoading && (
              /* eslint-disable-next-line react/jsx-props-no-spreading */
              <Component {...selfCheckFormProps} caseNumber={openCase?.caseNumber} />
            )}
          </div>
        )}
      </div>
    );
  };

export const UnwrappedSelfCheck = withOpenSelfCheckCase(SelfCheckOpenCaseGate);
export default withSelfCheckFormConsumer(UnwrappedSelfCheck);
