import React from 'react';
import classNames from 'classnames';
import { Button } from '@ver-uds/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { CustomClassValue } from '../../../../../../../../../utils/types';
import ReturnHomeButton from '../../../../../../../../Actions/ReturnHomeButton';
import ActionFooter from '../../../../../../../../ActionFooter/ActionFooter';

export interface SelfCheckCaseDetailReturnHomeActionSectionProps {
  className?: CustomClassValue;
}

const baseClassName = 'case-detail-self-check-action-section--return-home';
const CLASS_NAMES = {
  base: baseClassName,
};

function SelfCheckCaseDetailReturnHomeActionSection({
  className = undefined,
}: SelfCheckCaseDetailReturnHomeActionSectionProps): React.JSX.Element {
  return (
    <ActionFooter
      className={classNames(CLASS_NAMES.base, className)}
      cancelButton={<ReturnHomeButton variant="primary" />}
      submitButton={
        <Button variant="secondary" onClick={window.print} iconLeft={<FontAwesomeIcon icon={faPrint as IconProp} />}>
          Print Result
        </Button>
      }
    />
  );
}

export default SelfCheckCaseDetailReturnHomeActionSection;
