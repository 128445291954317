import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal, ButtonGroup } from '@ver-uds/react';
import noop from 'lodash/noop';
import { Alert } from '@ver-uds/uswds-react';
import { AuthenticationContext } from '../../context/Authentication/AuthenticationContext';
import { LAST_AUTHENTICATED_TIME_STORAGE_KEY } from '../../services/Authentication';
import { useGetUserInfo } from '../../services/UserApi';

// Time until the user is prompted to extend their session.
// 10 minutes
const WARNING_TIME_MILLISECONDS = 10 * 60 * 1000;

// Time until the user is logged out after prompted with a logout warning.
// 5 minutes
const LOGOUT_TIME_MILLISECONDS = 5 * 60 * 1000;
const LOGOUT_TIME_SECONDS = LOGOUT_TIME_MILLISECONDS / 1000;

const CLASS_NAMES = {
  base: 'navigation-prompt',
};

function LogoutWarningModal(): React.JSX.Element {
  const { refetch } = useGetUserInfo({ disabled: true, disableAuthRefresh: true });

  const [isOpen, setIsOpen] = useState(false);
  const { isAuthenticated, getLastAuthenticatedTime, refreshLastAuthenticatedTime, logout } =
    useContext(AuthenticationContext);
  const authenticated = isAuthenticated();
  const [count, setCount] = useState(LOGOUT_TIME_SECONDS);

  const toTimeFormat = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const second = seconds - minutes * 60;

    if (second < 10) {
      return `${minutes}:0${second}.`;
    }
    return `${minutes}:${second}.`;
  };

  useEffect(() => {
    const checkTimer = (millisecondsToWait: number, makeLogoutDecision: () => void): ReturnType<typeof setTimeout> =>
      setTimeout(() => {
        makeLogoutDecision();
      }, millisecondsToWait);

    const makeLogoutDecision = (): (() => void) => {
      if (!authenticated) {
        localStorage.removeItem(LAST_AUTHENTICATED_TIME_STORAGE_KEY);
        return noop;
      }

      const lastAuthenticatedTime = getLastAuthenticatedTime();
      let recheckTimeout: ReturnType<typeof setTimeout>;
      const now = Date.now();

      if (lastAuthenticatedTime) {
        const lastAuthenticatedAt = parseInt(lastAuthenticatedTime, 10);
        const timeToWarning = lastAuthenticatedAt - now + WARNING_TIME_MILLISECONDS;
        const timeToLogout = timeToWarning + LOGOUT_TIME_MILLISECONDS;

        if (timeToLogout < 0) {
          logout();
        } else if (timeToWarning < 0) {
          setIsOpen(true);
          const timer = setInterval(() => {
            if (count > 0) {
              setCount(count - 1);
            }
            clearInterval(timer);
          }, 1000);
          if (count === 0) {
            logout();
          }
          recheckTimeout = checkTimer(timeToLogout, makeLogoutDecision);
        } else {
          setIsOpen(false);
          recheckTimeout = checkTimer(timeToWarning, makeLogoutDecision);
        }
      } else {
        refreshLastAuthenticatedTime();
        recheckTimeout = checkTimer(WARNING_TIME_MILLISECONDS, makeLogoutDecision);
      }

      return (): void => {
        clearTimeout(recheckTimeout);
      };
    };

    // Kickstart the process by triggering this once
    return makeLogoutDecision();
  }, [authenticated, getLastAuthenticatedTime, logout, refreshLastAuthenticatedTime, count]);

  const continueAction = async (): Promise<void> => {
    await refetch();
    refreshLastAuthenticatedTime();
    setCount(LOGOUT_TIME_SECONDS);
    setIsOpen(false);
  };

  const cancelAction = (): void => {
    continueAction();
  };

  const handleLogout = (): void => {
    logout();
  };

  return (
    <Modal id={CLASS_NAMES.base} header="Session Expiring" open={isOpen} close={cancelAction}>
      <Modal.Content>
        <Alert status="warning">
          <Alert.Heading>
            Your session will expire in <b id="countDownClock">{toTimeFormat(count)}</b>
          </Alert.Heading>
          <Alert.Text>To keep your session active, press Continue. To end your session, press Sign Out.</Alert.Text>
        </Alert>
      </Modal.Content>
      <Modal.Footer>
        <ButtonGroup>
          <Button type="button" variant="secondary" onClick={handleLogout}>
            Sign Out
          </Button>
          <Button type="button" variant="primary" onClick={continueAction}>
            Continue
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
}

export default LogoutWarningModal;
