import React from 'react';
import { PathRouteProps } from 'react-router-dom';
import CaseDetailPage from '../pages/CaseDetailPage';
import DocumentUploadPage from '../pages/DocumentUploadPage';
import ErrorPage from '../pages/ErrorPage';
import HomePage from '../pages/HomePage';
import NotFoundPage from '../pages/NotFoundPage';
import SelfCheckPage from '../pages/SelfCheckPage';
import SelfLockPage from '../pages/SelfLockPage';
import SelfLockLockPage from '../pages/SelfLockLockPage';
import paths from './paths';
import CreateAccountPage from '../pages/CreateAccountPage';
import ConfirmIdentityPage from '../pages/ConfirmIdentityPage';

export interface CustomRouteProps extends PathRouteProps {
  path: string;
  isPublic: boolean;
  children: React.ReactNode;
}

const routes: { [key: string]: CustomRouteProps } = {
  error: {
    path: paths.error,
    children: <ErrorPage />,
    isPublic: true,
  },
  caseDetail: {
    path: paths.caseDetail,
    children: <CaseDetailPage />,
    isPublic: true,
  },
  createAccount: {
    path: paths.createAccount,
    children: <CreateAccountPage />,
    isPublic: false,
  },
  documentUpload: {
    path: paths.documentUpload,
    children: <DocumentUploadPage />,
    isPublic: false,
  },
  selfCheck: {
    path: paths.selfCheck,
    children: <SelfCheckPage />,
    isPublic: false,
  },
  selfLock: {
    path: paths.selfLock,
    children: <SelfLockPage />,
    isPublic: false,
  },
  selfLockLock: {
    path: paths.selfLockLock,
    children: <SelfLockLockPage />,
    isPublic: false,
  },
  confirmIdentity: {
    path: paths.confirmIdentity,
    children: <ConfirmIdentityPage />,
    isPublic: false,
  },
  home: {
    path: paths.home,
    children: <HomePage />,
    isPublic: true,
  },
  notFound: {
    path: paths.catchAll,
    children: <NotFoundPage />,
    isPublic: true,
  },
};

export default routes;
