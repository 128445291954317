import get from 'lodash/get';
import {
  USCISANumberFieldName,
  countryOfIssuanceFieldName,
  documentExpirationDateFieldName,
  documentTypeFieldName,
  FieldName,
  foreignPassportNumberFieldName,
  i551NumberFieldName,
  i766NumberFieldName,
  i94NumberFieldName,
  SelfCheckFormData,
  SelfCheckFormPage,
  usPassportNumberFieldName,
  visaNumberFieldName,
} from '../constants';
import { CitizenshipStatus, DocumentType, SubmitSelfCheckCaseRequest } from '../../../../services/SelfCheckApi';
import {
  dobFieldName,
  firstNameFieldName,
  lastNameFieldName,
  middleNameFieldName,
  ssnFieldName,
} from '../../../../services/UserApi';

export const getDataByFieldName = (name: FieldName, data: SelfCheckFormData = {}): string | undefined => {
  const selectorPaths = [];
  switch (name) {
    case firstNameFieldName:
    case lastNameFieldName:
    case middleNameFieldName:
    case ssnFieldName:
    case dobFieldName:
      selectorPaths.push(SelfCheckFormPage.CONFIRM_IDENTITY);
      break;
    case USCISANumberFieldName:
    case documentTypeFieldName:
    case i94NumberFieldName:
      selectorPaths.push(SelfCheckFormPage.DOCUMENT_SELECTION);
      break;
    case countryOfIssuanceFieldName:
    case foreignPassportNumberFieldName:
      selectorPaths.push(SelfCheckFormPage.DOCUMENT_SELECTION, SelfCheckFormPage.DOCUMENT_DETAILS);
      break;
    case documentExpirationDateFieldName:
    case i551NumberFieldName:
    case i766NumberFieldName:
    case usPassportNumberFieldName:
    case visaNumberFieldName:
      selectorPaths.push(SelfCheckFormPage.DOCUMENT_DETAILS);
      break;
    default:
      return undefined;
  }

  return selectorPaths.reduce((value, selectorPath) => get(data, [selectorPath, name], value), undefined);
};

/* eslint-disable import/prefer-default-export */
export const mapSelfCheckFormDataToSubmitCaseRequest = (data?: SelfCheckFormData): SubmitSelfCheckCaseRequest => {
  const firstNameData = getDataByFieldName(firstNameFieldName, data);
  if (typeof firstNameData !== 'string') {
    throw new Error('firstName is not a string');
  }
  const lastNameData = getDataByFieldName(lastNameFieldName, data);
  if (typeof lastNameData !== 'string') {
    throw new Error('lastName is not a string');
  }

  return {
    firstName: firstNameData,
    lastName: lastNameData,
    middleName: getDataByFieldName(middleNameFieldName, data),
    uscisANumber: getDataByFieldName(USCISANumberFieldName, data),
    citizenshipStatus: get(data, SelfCheckFormPage.CITIZENSHIP_STATUS) as CitizenshipStatus,
    countryOfIssuance: getDataByFieldName(countryOfIssuanceFieldName, data),
    documentExpirationDate: getDataByFieldName(documentExpirationDateFieldName, data),
    documentType: getDataByFieldName(documentTypeFieldName, data) as DocumentType,
    i551Number: getDataByFieldName(i551NumberFieldName, data),
    i766Number: getDataByFieldName(i766NumberFieldName, data),
    i94Number: getDataByFieldName(i94NumberFieldName, data),
    usPassportNumber: getDataByFieldName(usPassportNumberFieldName, data),
    foreignPassportNumber: getDataByFieldName(foreignPassportNumberFieldName, data),
    visaNumber: getDataByFieldName(visaNumberFieldName, data),
  };
};
