import classNames from 'classnames';
import React from 'react';
import { Controller, get, useForm } from 'react-hook-form';
import { Form } from '@ver-uds/uswds-react';
import { getFieldError } from '../../../../../utils/forms';
import ActionFooter from '../../../../ActionFooter/ActionFooter';
import FormattedDateInput from '../../../../Form/FormattedDateInput/FormattedDateInput';
import { documentExpirationDateFieldName, fieldDefinitions, I94FormData } from '../../constants';
import { DocumentMarkupComponentProps } from '../DocumentDetailsFormTypes';

const baseClassName = 'self-check-i94-document-details';
const CLASS_NAMES = {
  base: classNames(baseClassName, 'margin-top-2'),
  descriptionHeader: classNames(`${baseClassName}__description-heading`, 'margin-bottom-2', 'text-bold'),
  actionFooter: classNames(`${baseClassName}__action-footer`, 'margin-top-4'),
  form: `${baseClassName}__form`,
};

function I94DocumentMarkup({ data, onSubmit, onCancel }: DocumentMarkupComponentProps<I94FormData>): React.JSX.Element {
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm<I94FormData>({
    mode: 'onSubmit',
    defaultValues: { ...(data || {}) },
  });

  const handleFormSubmit = handleSubmit((formData) => {
    onSubmit(formData);
  });

  const dateInputErrorMessage = getFieldError(
    errors.documentExpirationDate,
    fieldDefinitions[documentExpirationDateFieldName].errorMessages,
  );

  return (
    <div className={CLASS_NAMES.base}>
      <p className={CLASS_NAMES.descriptionHeader}>Arrival/Departure Record (Form I-94) with temporary I-551 stamp</p>
      <Form className={CLASS_NAMES.form} onSubmit={handleFormSubmit} variant="large">
        <Controller
          control={control}
          name={documentExpirationDateFieldName}
          rules={{
            required: true,
            validate: get(fieldDefinitions[documentExpirationDateFieldName], 'validate'),
          }}
          render={({ field }): React.JSX.Element => (
            <FormattedDateInput
              id={documentExpirationDateFieldName}
              name={documentExpirationDateFieldName}
              label={fieldDefinitions[documentExpirationDateFieldName].label}
              hint={!dateInputErrorMessage ? fieldDefinitions[documentExpirationDateFieldName].hint : undefined}
              errorMessage={dateInputErrorMessage}
              required
              defaultValue={get(data, documentExpirationDateFieldName)}
              formattedDate={field.value}
              onUpdate={field.onChange}
            />
          )}
        />
      </Form>
      <ActionFooter
        className={CLASS_NAMES.actionFooter}
        onCancel={onCancel}
        cancelButtonText="Back"
        onSubmit={handleFormSubmit}
        submitButtonText="Next"
      />
    </div>
  );
}

export default I94DocumentMarkup;
