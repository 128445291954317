import React from 'react';
import classNames from 'classnames';
import { CustomClassValue } from '../../../../../utils/types';
import SelfCheckFormConfirmIdentityMarkupUserFields, {
  SelfCheckFormConfirmIdentityMarkupUserFieldsProps,
} from '../../SelfCheckFormConfirmIdentity/SelfCheckFormConfirmIdentityMarkupUserFieldGroup';

export interface PersonalInfoSectionProps
  extends Pick<SelfCheckFormConfirmIdentityMarkupUserFieldsProps, 'userInfo' | 'hideSsn'> {
  className?: CustomClassValue;
}

const baseClassName = 'self-check-review-personal-info-section';
const CLASS_NAMES = {
  base: baseClassName,
  userFields: classNames(`${baseClassName}__user-fields`, 'margin-top-2'),
};

function PersonalInfoSection({
  className = undefined,
  hideSsn,
  userInfo,
}: PersonalInfoSectionProps): React.JSX.Element {
  return (
    <div className={classNames(CLASS_NAMES.base, className)}>
      <h2>Personal Information</h2>
      <SelfCheckFormConfirmIdentityMarkupUserFields
        className={CLASS_NAMES.userFields}
        hideSsn={hideSsn}
        userInfo={userInfo}
      />
    </div>
  );
}

export default PersonalInfoSection;
