import React from 'react';
import classNames from 'classnames';
import { CustomClassValue } from '../../../../../../../utils/types';
import SelfCheckReferredDownloadRDCButton from './SelfCheckReferredDownloadRDCButton';

export interface SelfCheckCaseDetailReferredContentProps {
  className?: CustomClassValue;
  dhsReferralStatus?: string | null;
  ssaReferralStatus?: string | null;
}

const baseClassName = 'case-detail-content-self-check--referred';
const CLASS_NAMES = {
  base: baseClassName,
  rdcDownloadButton: classNames(`${baseClassName}__download-rdc-button`, 'margin-top-3'),
};

function SelfCheckCaseDetailReferredContent({
  className = undefined,
  dhsReferralStatus = undefined,
  ssaReferralStatus = undefined,
}: SelfCheckCaseDetailReferredContentProps): React.JSX.Element {
  let agencyFullName: string;
  let agencyAbbr: string;
  if (dhsReferralStatus === 'REFERRED' && ssaReferralStatus === 'REFERRED') {
    agencyFullName = 'Department of Homeland Security (DHS) and Social Security Administration (SSA)';
    agencyAbbr = 'DHS and SSA';
  } else if (dhsReferralStatus === 'REFERRED') {
    agencyFullName = 'Department of Homeland Security (DHS)';
    agencyAbbr = 'DHS';
  } else if (ssaReferralStatus === 'REFERRED') {
    agencyFullName = 'Social Security Administration (SSA)';
    agencyAbbr = 'SSA';
  } else {
    // should never happen
    agencyFullName = 'Department of Homeland Security (DHS)';
    agencyAbbr = 'DHS';
  }

  const showUploadStatement = (): string =>
    agencyAbbr === 'DHS' ? 'To proceed, please upload documents that show your employment eligibility.' : '';

  return (
    <div className={classNames(CLASS_NAMES.base, className)}>
      <div className="grid-col-9">
        <p>
          You have decided to take action on this Self Check case, therefore your case has been referred to the{' '}
          {agencyFullName}. {showUploadStatement()}
          <strong>
            {' '}
            You have 8 federal working days from the date of referral to contact {agencyAbbr} to resolve this issue.
          </strong>
        </p>
      </div>
      <SelfCheckReferredDownloadRDCButton className={CLASS_NAMES.rdcDownloadButton} />
    </div>
  );
}

export default SelfCheckCaseDetailReferredContent;
