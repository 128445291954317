import React, { ComponentPropsWithoutRef } from 'react';
import classNames from 'classnames';
import { StepIndicator } from '@ver-uds/uswds-react';
import styled from 'styled-components';

export type CustomStepIndicatorProps = Pick<
  ComponentPropsWithoutRef<typeof StepIndicator>,
  'className' | 'stepIndex' | 'labels'
>;

const StepIndicatorNoBackground = styled.div`
  > .usa-step-indicator {
    background-color: transparent;
  }
`;

const baseClassName = 'custom-step-indicator';
const CLASS_NAMES = {
  base: classNames(baseClassName, 'margin-bottom-3'),
};

function CustomStepIndicator({ className, stepIndex, labels }: CustomStepIndicatorProps): React.JSX.Element {
  return (
    <StepIndicatorNoBackground className={classNames(CLASS_NAMES.base, className)}>
      <StepIndicator counters="small" headerAs="h1" labels={labels} stepIndex={stepIndex} />
    </StepIndicatorNoBackground>
  );
}

export default CustomStepIndicator;
