import React from 'react';
import classNames from 'classnames';
import { CustomClassValue } from '../../../../../../utils/types';

export interface GenericUploadSuccessModalContentProps {
  className?: CustomClassValue;
}

const baseClassName = 'upload-success-modal-content--generic';
const CLASS_NAMES = {
  base: baseClassName,
};

function GenericUploadSuccessModalContent({
  className = undefined,
}: GenericUploadSuccessModalContentProps): React.JSX.Element {
  return (
    <p className={classNames(CLASS_NAMES.base, className)}>
      We received your documents. <strong>The U.S. Department of Homeland Security (DHS) will review</strong> your
      submitted case documents and update the case status. Please allow 3 business days for our team to review your
      case. You may use Case Tracker to check the status of your case or follow up with your employer.
    </p>
  );
}

export default GenericUploadSuccessModalContent;
