import React from 'react';
import classNames from 'classnames';
import { CustomClassValue } from '../../../../../utils/types';
import { SelfCheckCaseDetailVariant } from '../constants';
import { CaseTncTypeData, ReadableCaseStatus } from '../../../../../services/CaseApi';
import CaseUploadActionSection from './variants/CaseUploadCaseDetailActionSection';
import GenericCaseDetailActionSection from './variants/GenericCaseDetailActionSection';
import SelfCheckCaseDetailActionSection from './variants/SelfCheckCaseDetailActionSection';
import { isCaseUploadAccessAllowed } from './util';

export interface CaseDetailActionSectionMarkupProps {
  className?: CustomClassValue;
  caseNumber: string;
  caseStatus: ReadableCaseStatus;
  caseTncTypeData?: CaseTncTypeData;
  selfCheckCaseDetailVariant?: SelfCheckCaseDetailVariant;
}

const baseClassName = 'case-detail-action-section';
const CLASS_NAMES = {
  base: baseClassName,
};

function CaseDetailActionSectionMarkup({
  className = undefined,
  caseNumber,
  caseStatus,
  caseTncTypeData = undefined,
  selfCheckCaseDetailVariant = undefined,
}: CaseDetailActionSectionMarkupProps): React.JSX.Element {
  return (
    <div className={classNames(CLASS_NAMES.base, className)}>
      {caseTncTypeData && isCaseUploadAccessAllowed(caseStatus, selfCheckCaseDetailVariant) ? (
        <CaseUploadActionSection caseNumber={caseNumber} caseTncTypeData={caseTncTypeData} />
      ) : (
        <div>
          {selfCheckCaseDetailVariant ? (
            <SelfCheckCaseDetailActionSection
              caseNumber={caseNumber}
              selfCheckCaseDetailVariant={selfCheckCaseDetailVariant}
            />
          ) : (
            <GenericCaseDetailActionSection />
          )}
        </div>
      )}
    </div>
  );
}

export default CaseDetailActionSectionMarkup;
