/* eslint-disable no-unused-expressions */
import { get } from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate, useNavigate } from 'react-router-dom';
import paths from '../../routing/paths';
import {
  CreateUserQuizRequest,
  useGetUserInfo,
  usePatchUserInfo,
  usePostUserQuiz,
  UserQuizAnswersRequest,
  UserQuizAnswersRequestData,
  UserQuizResponseData,
  useUserSubmitQuizAnswers,
} from '../../services/UserApi';
import CustomStepIndicator from '../CustomStepIndicator/CustomStepIndicator';
import {
  CreateAccountFormPage,
  createAccountPageTitles,
  httpStatusMessages,
  initialCreateUserQuizRequestObject,
  STEP_INDICATOR_LABELS,
} from './constants';
import CreateAccountCancellationModal from './CreateAccountCancellationModal/CreateAccountCancellationModal';
import AttemptsExceeded from './Errors/AttemptsExceeded/AttemptsExceeded';
import FailedAttempt from './Errors/FailedAttempt/FailedAttempt';
import GenerateQuizError from './Errors/GenerateQuizError/GenerateQuizError';
import PersonalInformationMarkup from './PersonalInformation/PersonalInformationMarkup';
import ReviewInformationMarkup from './ReviewInformation/ReviewInformationMarkup';
import { TermsAndConditionsFormFields } from './TermsAndConditions/TermsAndConditionsForm';
import TermsAndConditionsMarkup from './TermsAndConditions/TermsAndConditionsMarkup';
import VerifyIdentityMarkup from './VerifyIdentity/VerifyIdentityMarkup';

export interface CreateAccountProps {
  page?: string;
}

function CreateAccount(): React.JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [formPage, setFormPage] = useState(CreateAccountFormPage.POLICY_AGREEMENTS);
  const [personalInfo, setPersonalInfo] = useState<CreateUserQuizRequest>(initialCreateUserQuizRequestObject);
  const GENERATE_QUIZ_ERROR_FORM_PAGE_NUM = -1;
  const FAILED_ATTEMPT_FORM_PAGE_NUM = -2;
  const ATTEMPTS_EXCEEDED_FORM_PAGE_NUM = -3;
  const MAX_ID_PROOFING_ATTEMPTS = 3;

  const navigate = useNavigate();
  const pageRedirect = get(navigate, 'location.state.createAccountFormPage');
  const {
    isError,
    isFetching,
    isLoading: isGetUserInfoLoading,
    isSuccess,
    data: getUserInfoData,
    refetch,
  } = useGetUserInfo();
  const { error: patchUserInfoError, mutate: patchUserInfoMutate } = usePatchUserInfo();
  const isGetUserInfoIdle = !isError && !isFetching && !isGetUserInfoLoading && !isSuccess;

  /**
   * Form page navigation helper functions -
   * steps forward or back through the create account multi-page form
   */
  const setFormPageNum = (pageNum: number): void => {
    setFormPage(pageNum);
  };

  const stepFormPage = useCallback(
    (step: 1 | -1 = 1) => {
      setFormPage(formPage.valueOf() + step);
    },
    [formPage],
  );

  /**
   * Helper functions for hooks
   */
  const onPostUserQuizSuccess = async (resp: UserQuizResponseData): Promise<void> => {
    if (!resp || !resp.questions || resp.questions.length === 0) {
      const refetchedData = (await refetch()).data;
      // eslint-disable-next-line no-unused-expressions
      refetchedData != null && get(refetchedData, 'idProofingAttempts', 0) >= MAX_ID_PROOFING_ATTEMPTS
        ? setFormPageNum(ATTEMPTS_EXCEEDED_FORM_PAGE_NUM)
        : setFormPageNum(GENERATE_QUIZ_ERROR_FORM_PAGE_NUM);
    } else {
      refetch();
      stepFormPage();
    }
  };

  const hasExceededAttempts = useCallback(
    (): boolean => get(getUserInfoData, 'idProofingAttempts', 0) >= MAX_ID_PROOFING_ATTEMPTS,
    [getUserInfoData],
  );

  const onPostUserQuizError = (error: Error): void => {
    if (hasExceededAttempts()) {
      setFormPageNum(ATTEMPTS_EXCEEDED_FORM_PAGE_NUM);
    }
    if (
      error.name === httpStatusMessages.SERVICE_UNAVAILABLE_MESSAGE ||
      error.name === httpStatusMessages.GATEWAY_TIMEOUT_MESSAGE
    ) {
      setFormPageNum(GENERATE_QUIZ_ERROR_FORM_PAGE_NUM);
    }
  };

  const onUserSubmitQuizAnswers = (error: Error): void => {
    if (
      error.name === httpStatusMessages.SERVICE_UNAVAILABLE_MESSAGE ||
      error.name === httpStatusMessages.GATEWAY_TIMEOUT_MESSAGE
    ) {
      setFormPageNum(FAILED_ATTEMPT_FORM_PAGE_NUM);
    }
  };

  /**
   * Hooks
   */
  const {
    mutate: postUserQuizMutate,
    data: userQuizResponseData,
    reset: resetUserQuizResponseData,
  } = usePostUserQuiz({
    onSuccess: onPostUserQuizSuccess,
    onError: onPostUserQuizError,
  });

  const {
    mutate: userSubmitQuizAnswersMutate,
    isSuccess: userSubmitQuizAnswersSuccess,
    data: userInfoResponseData,
    reset: resetSubmitQuizAnswerData,
  } = useUserSubmitQuizAnswers({ onError: onUserSubmitQuizAnswers });

  useEffect(() => {
    if (userSubmitQuizAnswersSuccess && !userInfoResponseData?.hasCompletedIdProofing) {
      if (hasExceededAttempts()) {
        setFormPageNum(ATTEMPTS_EXCEEDED_FORM_PAGE_NUM);
      } else {
        resetSubmitQuizAnswerData();
        setFormPageNum(FAILED_ATTEMPT_FORM_PAGE_NUM);
      }
    }
  }, [
    userSubmitQuizAnswersSuccess,
    hasExceededAttempts,
    userInfoResponseData,
    FAILED_ATTEMPT_FORM_PAGE_NUM,
    ATTEMPTS_EXCEEDED_FORM_PAGE_NUM,
    resetSubmitQuizAnswerData,
  ]);

  useEffect(() => {
    if (pageRedirect) {
      setFormPageNum(pageRedirect);
    }
  }, [pageRedirect]);

  const stepToPersonalInformation = (): void => {
    setFormPage(CreateAccountFormPage.PERSONAL_INFORMATION);
    resetUserQuizResponseData();
  };

  const showStepIndicator = (): boolean =>
    formPage !== CreateAccountFormPage.FAILED_ATTEMPT &&
    formPage !== CreateAccountFormPage.ATTEMPTS_EXCEEDED &&
    formPage !== CreateAccountFormPage.GEN_QUIZ_ERROR;

  /**
   * Submit - Policy Agreements / Terms & Conditions
   */
  const submitTermsAndConditions = (data: TermsAndConditionsFormFields): void => {
    patchUserInfoMutate(data);

    // Optimistically proceed to the next page. The user will be taken to an error page if it ends up failing.
    stepFormPage();
  };

  if (patchUserInfoError) {
    return <Navigate to={paths.error} />;
  }

  /**
   * Submit - Personal Information to be reviewed on the following screen
   */
  const submitPersonalInformation = (data: CreateUserQuizRequest): void => {
    setPersonalInfo(data);
    stepFormPage();
  };

  /**
   * Submitted - Reviewed Personal Information
   * the user's personal information is sent to try to generate an identity proofing quiz.
   *
   * On success:  steps forward and presents the user with the identity proofing quiz questions
   * On error:    if the user has exceeded the maximum number of quiz attempts they will be redirected
   *              to the Attempts Exceeded screen, if they have not exceeded attempts and there was a
   *              problem with the service they will be shown a quiz generation error screen
   */
  const submitReviewInformation = (): void => {
    postUserQuizMutate(personalInfo);
  };

  /**
   * Submit - Identity Proofing Quiz answers
   * On success:  because the API returns a 200 (success) even if the user chooses the incorrect answers
   *              we need to check the hasCompletedIdProofing property on the userInfoResponseData object returned from
   *              the POST /user/quiz/answers endpoint. If the hasCompletedIdProofing is true then redirect the user to
   *              the home dashboard, otherwiise check if they have exceeded their maximum number of quiz attempts and
   *              redirect to attempts exceeded screen, otherwise show the failed quiz attempt screen
   * On error:    These should not occur if the user submits incorrect quiz answers rather they should only occur when
   *              the user exceeds the maximum quiz timeout, this will count as quiz failure and they will be redirected
   *              to the failed quiz attempt screen
   */
  const redirectToFailedAttempt = (): void => {
    setFormPage(FAILED_ATTEMPT_FORM_PAGE_NUM);
  };

  const submitQuizAnswers = (answers: UserQuizAnswersRequestData[]): void => {
    const payload: UserQuizAnswersRequest = {
      answers,
    };

    userSubmitQuizAnswersMutate(payload);
  };

  const cancelAccountCreation = (): void => {
    setIsModalOpen(true);
  };

  const resumeAccountCreation = (): void => {
    setIsModalOpen(false);
  };

  if (userSubmitQuizAnswersSuccess && userInfoResponseData?.hasCompletedIdProofing) {
    return (
      <Navigate
        to={paths.home}
        state={{ bypassLoginRouting: true }}
        // TODO: Determin if "replace" is necessary for this action
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>{createAccountPageTitles[formPage]}</title>
      </Helmet>
      <CreateAccountCancellationModal isOpen={isModalOpen} onClose={resumeAccountCreation} />
      {showStepIndicator() && <CustomStepIndicator stepIndex={formPage} labels={STEP_INDICATOR_LABELS} />}
      {formPage === CreateAccountFormPage.POLICY_AGREEMENTS && (
        <TermsAndConditionsMarkup onSubmit={submitTermsAndConditions} onCancel={cancelAccountCreation} />
      )}
      {formPage === CreateAccountFormPage.PERSONAL_INFORMATION && (
        <PersonalInformationMarkup
          data={personalInfo}
          onSubmit={submitPersonalInformation}
          onCancel={cancelAccountCreation}
        />
      )}
      {formPage === CreateAccountFormPage.REVIEW_INFORMATION && (
        <ReviewInformationMarkup
          data={personalInfo}
          onBack={(): void => stepFormPage(-1)}
          onSubmit={submitReviewInformation}
        />
      )}
      {!isGetUserInfoIdle && !isGetUserInfoLoading && formPage === CreateAccountFormPage.VERIFY_IDENTITY && (
        <VerifyIdentityMarkup
          quiz={userQuizResponseData}
          onSubmit={submitQuizAnswers}
          userInfo={getUserInfoData}
          timeoutRedirect={redirectToFailedAttempt}
        />
      )}
      {/* Error conditions */}
      {formPage === CreateAccountFormPage.GEN_QUIZ_ERROR && (
        <GenerateQuizError onSubmit={stepToPersonalInformation} userInfo={getUserInfoData} />
      )}
      {formPage === CreateAccountFormPage.ATTEMPTS_EXCEEDED && <AttemptsExceeded userInfo={getUserInfoData} />}
      {formPage === CreateAccountFormPage.FAILED_ATTEMPT && (
        <FailedAttempt onSubmit={stepToPersonalInformation} userInfo={getUserInfoData} />
      )}
    </>
  );
}

export default CreateAccount;
