export const bytesToMegabytes = 10 ** 6;

export const is4MegabytesOrUnder = (files: FileList): boolean =>
  Array.from(files).every((file) => file.size <= 4 * bytesToMegabytes);

export const isOver0Bytes = (files: FileList): boolean => Array.from(files).every((file) => file.size > 0);

export const getFiles = (data: Record<string | number | symbol, FileList>): File[] =>
  Object.values(data).reduce((filesAcc: File[], fileList: FileList) => {
    for (let i = 0; i < fileList.length; i += 1) {
      const file = fileList[i];
      if (file && file.size) {
        filesAcc.push(file);
      }
    }
    return filesAcc;
  }, []);

export const createFile = ({ size = 0 }: { size?: number } = { size: 0 }): File => {
  if (size < 0) {
    throw new Error('cannot create file with negative size');
  }
  return new File(['x'.repeat(size)], `${size > 0 ? `${size}b` : 'empty'}.jpg`, { type: 'image/jpg' });
};

export const createEmptyFile = (): File => createFile();
