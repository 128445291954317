import { Tag } from '@ver-uds/react';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { CellProps, Column } from 'react-table';
import { EMPTY_VAL_CHAR } from '../../../constants';
import { AccessorMap } from '../../../context/Table/PaginationState/constants';
import paths from '../../../routing/paths';
import { Case, RawCaseStatus } from '../../../services';
import { mapRawCaseStatusToReadableCaseStatus } from '../../../services/CaseApi/CaseApiUtil';
import { formatDate } from '../../../utils/dates';
import { injectURLParams } from '../../../utils/routing';
import { isCaseUploadAccessAllowed } from '../../CaseDetail/CaseDetailMarkup/CaseDetailContent/CaseDetailActionSection/util';
import Link from '../../Link';
import { OpenCaseStatus } from '../util';
import { mapReadableCaseStatusToCaseHistoryTableStatusText } from './util';

const SELF_CHECK_CASE_TYPE = 'Self Check';

/**
 * Avoid TS linting error for an
 * interface not being compatible with Record<string, unknown>
 * which ver-uds Table expects, as of ver 0.47.1.
 */
export type TableData = Case & Record<string, unknown>;

// Remove after testing
// const potentiallyEmptyCellRendererLegacy = ({ value }: { value: string }): string | React.ReactNode => {
//   return !isEmpty(value) ? value : <span title="N/A">{EMPTY_VAL_CHAR}</span>;
// };

const potentiallyEmptyCellRenderer = ({ value }: CellProps<TableData, unknown>) => (
  <span title={isEmpty(value) ? 'N/A' : undefined}>{isEmpty(value) ? EMPTY_VAL_CHAR : (value as string)}</span>
);

// Remove after testing
// const openCaseStatusCellRendererLegacy = ({
//   value: { uploadCount, caseStatus },
// }: {
//   value: OpenCaseStatus;
// }):React.JSX.Element => {
//   if (uploadCount > 0) {
//     return <Tag variant="success">Documents Submitted</Tag>;
//   }
//   if (!isCaseUploadAccessAllowed(mapRawCaseStatusToReadableCaseStatus(caseStatus))) {
//     return <Tag>Pending</Tag>;
//   }
//   return <Tag variant="warning">Action Needed</Tag>;
// };

const openCaseStatusCellRenderer = ({ value }: CellProps<TableData, unknown>): React.JSX.Element => {
  const { uploadCount, caseStatus } = value as OpenCaseStatus;
  if (uploadCount > 0) {
    return <Tag variant="success">Documents Submitted</Tag>;
  }
  if (!isCaseUploadAccessAllowed(mapRawCaseStatusToReadableCaseStatus(caseStatus))) {
    return <Tag>Pending</Tag>;
  }
  return <Tag variant="warning">Action Needed</Tag>;
};

// TODO: Remove after thorough testing
// const caseStatusCellRendererLegacy = ({ value }: { value: RawCaseStatus }): string | React.ReactNode => {
//   return mapReadableCaseStatusToCaseHistoryTableStatusText(mapRawCaseStatusToReadableCaseStatus(value));
// };

const caseStatusCellRenderer = ({ value }: CellProps<TableData, unknown>) => {
  const statusText = mapReadableCaseStatusToCaseHistoryTableStatusText(
    mapRawCaseStatusToReadableCaseStatus(value as RawCaseStatus),
  );
  return <span>{statusText}</span>;
};

const isCompanyNameEmpty = ({ companyName, type }: { companyName: string | null; type: string }): boolean =>
  isEmpty(companyName) || type === SELF_CHECK_CASE_TYPE;

export const dataTransformers: AccessorMap<TableData> = {
  state: ({ state }): string => state || '',
  companyName: ({ companyName, type }): string => (!isCompanyNameEmpty({ companyName, type }) ? companyName : ''),
};

const sharedColumns: Column<TableData>[] = [
  {
    Header: 'Date',
    accessor: 'date',
    maxWidth: 130,
    Cell: ({ value }: CellProps<TableData, unknown>) => {
      const formattedDate = formatDate(value as string) || '';
      return <span>{formattedDate}</span>;
    },
  },
  {
    Header: 'E-Verify Case Number',
    accessor: 'caseNumber',
    minWidth: 175,
    maxWidth: 200,
    Cell: ({ value }: CellProps<TableData, unknown>) => {
      const caseNumber = value as Case['caseNumber'];
      return <Link to={injectURLParams(paths.caseDetail, { caseNumber })}>{caseNumber}</Link>;
    },
  },
  {
    Header: 'Company Name',
    id: 'companyName',
    accessor: dataTransformers.companyName,
    Cell: potentiallyEmptyCellRenderer,
  },
  {
    Header: 'State',
    id: 'state',
    maxWidth: 100,
    accessor: dataTransformers.state,
    Cell: potentiallyEmptyCellRenderer,
  },
  {
    Header: 'Type',
    accessor: 'type',
    maxWidth: 120,
  },
];

export const openCasesColumns: Column<TableData>[] = [...sharedColumns];
openCasesColumns.splice(4, 0, {
  Header: 'Case Status',
  id: 'openCaseStatus',
  accessor: 'openCaseStatus',
  minWidth: 230,
  Cell: openCaseStatusCellRenderer,
});

export const closedCasesColumns: Column<TableData>[] = [...sharedColumns];
closedCasesColumns.splice(4, 0, {
  Header: 'Case Result',
  id: 'caseStatus',
  accessor: 'caseStatus',
  minWidth: 230,
  Cell: caseStatusCellRenderer,
});
