import React from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { CustomClassValue } from '../../../../../../../../../../utils/types';
import ActionFooter from '../../../../../../../../../ActionFooter/ActionFooter';
import paths from '../../../../../../../../../../routing/paths';
import {
  SelfCheckCaseClosureReason,
  useCloseSelfCheckCaseMutation,
} from '../../../../../../../../../../services/SelfCheckApi';
import ReturnHomeButton from '../../../../../../../../../Actions/ReturnHomeButton';

export const START_NEW_CASE_BUTTON_TEXT = 'Start New Case';

export interface StartNewCaseActionFooterProps {
  className?: CustomClassValue;
  caseNumber: string;
  shouldCloseCaseOnSubmit?: boolean;
}

const baseClassName = 'start-new-case-action-footer';
const CLASS_NAMES = {
  base: baseClassName,
};

function StartNewCaseActionFooter({
  className = undefined,
  caseNumber,
  shouldCloseCaseOnSubmit = undefined,
}: StartNewCaseActionFooterProps): React.JSX.Element {
  const navigate = useNavigate();
  const { mutateAsync } = useCloseSelfCheckCaseMutation(caseNumber);

  const redirectToSelfCheck = (): void | Promise<void> => navigate(paths.selfCheck);

  const handleStartNewCaseClick = async (): Promise<void> => {
    if (!shouldCloseCaseOnSubmit) {
      return redirectToSelfCheck();
    }

    try {
      await mutateAsync({
        closureReason: SelfCheckCaseClosureReason.DUPLICATE_CASE_DATA_EXISTS,
      });
      return redirectToSelfCheck();
    } catch (_err) {
      return navigate(paths.error);
    }
  };

  return (
    <ActionFooter
      className={classNames(CLASS_NAMES.base, className)}
      cancelButton={<ReturnHomeButton />}
      submitButtonText={START_NEW_CASE_BUTTON_TEXT}
      onSubmit={handleStartNewCaseClick}
    />
  );
}

export default StartNewCaseActionFooter;
