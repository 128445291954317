import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import toArray from 'lodash/toArray';
import omit from 'lodash/omit';
import { Form } from '@ver-uds/uswds-react';
import type { CustomClassValue } from '../../../../utils/types';
import paths from '../../../../routing/paths';
import { SelfLockChallengeQuestion } from '../../../../services/SelfLockApi';
import ActionFooter from '../../../ActionFooter/ActionFooter';
import SelfLockLockFormSection from './SelfLockLockFormSection';
import { getAvaibleQuestionOptions, SelfLockLockFormData } from './selfLockLockFormUtil';

export interface SelfLockLockFormProps {
  className?: CustomClassValue;
  onSubmit: SubmitHandler<SelfLockChallengeQuestion[]>;
}

const baseClassName = 'self-lock-lock-form';
const CLASS_NAMES = {
  base: baseClassName,
  divider: classNames(`${baseClassName}__divider`, 'border-top-1px', 'border-base-lighter', 'margin-top-3'),
  formSection: `${baseClassName}__form-section`,
  actionSection: classNames(`${baseClassName}__action-section`, 'margin-top-3'),
};

function SelfLockLockForm({ className = undefined, onSubmit }: SelfLockLockFormProps): React.JSX.Element {
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    watch,
  } = useForm<SelfLockLockFormData>({
    mode: 'all',
  });

  const formData = getValues();
  const watchQuestionSelects = watch(['question1.question', 'question2.question', 'quesiton3.question']);
  const [availableOptions, setAvailableOptions] = useState<string[][]>([]);

  useEffect(() => {
    setAvailableOptions([
      getAvaibleQuestionOptions(omit(formData, 0)),
      getAvaibleQuestionOptions(omit(formData, 1)),
      getAvaibleQuestionOptions(omit(formData, 2)),
    ]);
  }, [watchQuestionSelects, formData]);

  const handleFormSubmit = handleSubmit((data) => {
    onSubmit(toArray(data));
  });

  return (
    <>
      <Form
        className={classNames(CLASS_NAMES.base, className)}
        autoComplete="off"
        onSubmit={handleFormSubmit}
        variant="large"
      >
        {watchQuestionSelects && (
          <SelfLockLockFormSection
            className={CLASS_NAMES.formSection}
            index={0}
            register={register}
            errors={errors}
            availableQuestionOptions={availableOptions[0] || []}
          />
        )}
        <div className={CLASS_NAMES.divider} />
        {watchQuestionSelects && (
          <SelfLockLockFormSection
            className={classNames(CLASS_NAMES.formSection, 'margin-top-4')}
            index={1}
            register={register}
            errors={errors}
            availableQuestionOptions={availableOptions[1] || []}
          />
        )}
        <div className={CLASS_NAMES.divider} />
        {watchQuestionSelects && (
          <SelfLockLockFormSection
            className={classNames(CLASS_NAMES.formSection, 'margin-top-4')}
            index={2}
            register={register}
            errors={errors}
            availableQuestionOptions={availableOptions[2] || []}
          />
        )}
      </Form>
      <ActionFooter
        className={CLASS_NAMES.actionSection}
        cancelButton={
          <Link className="usa-button usa-button--outline ver-button" to={paths.selfLock}>
            Cancel
          </Link>
        }
        submitButtonText="Lock My SSN"
        onSubmit={handleFormSubmit}
      />
    </>
  );
}

export default SelfLockLockForm;
