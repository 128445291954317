import React from 'react';
import { SubmitHandler } from 'react-hook-form';
import classNames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import { Alert } from '@ver-uds/uswds-react';
import { Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { CustomClassValue } from '../../../../utils/types';
import { COLUMN_9_CLASS_NAME } from '../../../../constants';
import {
  USCISANumberFieldName,
  countryOfIssuanceFieldName,
  foreignPassportNumberFieldName,
  i94NumberFieldName,
  SelfCheckFormData,
  SelfCheckFormPage,
  selfCheckPageTitles,
} from '../constants';
import {
  CaseStatus,
  CitizenshipStatus,
  DocumentType,
  SelfCheckCaseResponseData,
  SubmitSelfCheckCaseRequest,
  useGetReverificationFieldsQuery,
} from '../../../../services/SelfCheckApi';
import ActionFooter from '../../../ActionFooter/ActionFooter';
import Divider from '../../../Divider/Divider';
import { mapSelfCheckFormDataToSubmitCaseRequest } from './util';
import PersonalInfoSection from './PersonalInfoSection/PersonalInfoSection';
import CitizenshipStatusSection from './CitizenshipStatusSection/CitizenshipStatusSection';
import DocumentDetailsSection from './DocumentDetailsSection/DocumentDetailsSection';
import ReverifySection from './ReverifySection/ReverifySection';
import { firstNameFieldName, lastNameFieldName } from '../../../../services/UserApi';
import paths from '../../../../routing/paths';

export const REVERIFY_CASE_STATUS = CaseStatus.UNCONFIRMED_DATA;

export interface SelfCheckFormReviewMarkupProps {
  caseData?: SelfCheckCaseResponseData;
  className?: CustomClassValue;
  data: SelfCheckFormData;
  documentType: DocumentType | undefined;
  onCancel: () => void;
  onSubmit: SubmitHandler<SubmitSelfCheckCaseRequest>;
  onReverifySuccess: (caseData?: SelfCheckCaseResponseData) => void;
}

const baseClassName = 'self-check-review';
const CLASS_NAMES = {
  base: baseClassName,
  infoAlert: classNames(`${baseClassName}__info-alert`, 'margin-top-2', 'margin-bottom-3'),
  infoAlertText: classNames(`${baseClassName}__info-alert-text`, COLUMN_9_CLASS_NAME),
  divider: classNames(`${baseClassName}__divider`, 'margin-bottom-3'),
  citizenshipStatusSection: classNames(`${baseClassName}__citizenship-status-section`, 'margin-bottom-4'),
  actionFooter: classNames(`${baseClassName}__action-footer`, 'margin-top-4'),
};

function SelfCheckFormReviewMarkup({
  caseData = undefined,
  className = undefined,
  data,
  documentType,
  onCancel,
  onSubmit,
  onReverifySuccess,
}: SelfCheckFormReviewMarkupProps): React.JSX.Element {
  const {
    isError,
    isFetching,
    isLoading: isGetReverificationFieldsLoading,
    isSuccess,
    data: reverificationFields = [],
    error: getReverificationFieldsError,
  } = useGetReverificationFieldsQuery(caseData?.caseNumber || '');

  const isGetReverificationFieldsIdle = !isError && !isFetching && !isSuccess && !isGetReverificationFieldsLoading;

  if (getReverificationFieldsError) {
    return <Navigate to={paths.error} />;
  }

  const handleSubmit = (): void => {
    onSubmit(mapSelfCheckFormDataToSubmitCaseRequest(data));
  };

  const userInfo = get(data, SelfCheckFormPage.CONFIRM_IDENTITY);
  const citizenshipStatus = get(data, SelfCheckFormPage.CITIZENSHIP_STATUS) as CitizenshipStatus; // citizenship status must be defined in order to reach this view
  const documentDetailsData = {
    ...pick(get(data, SelfCheckFormPage.DOCUMENT_SELECTION, {}), [
      USCISANumberFieldName,
      i94NumberFieldName,
      foreignPassportNumberFieldName,
      countryOfIssuanceFieldName,
    ]),
    ...get(data, SelfCheckFormPage.DOCUMENT_DETAILS, {}),
  };

  const isReverifyMode = caseData?.caseStatus === REVERIFY_CASE_STATUS;
  if (isReverifyMode && (isGetReverificationFieldsIdle || isGetReverificationFieldsLoading)) {
    return <div />;
  }

  // if firstName or lastName are flagged, we assume both are going to be flagged for reverification
  const isUserInfoFlagged = reverificationFields.some((reverificationField) =>
    [firstNameFieldName, lastNameFieldName].includes(reverificationField),
  );

  return (
    <div className={classNames(CLASS_NAMES.base, className)}>
      <Helmet>
        <title>
          {isReverifyMode ? selfCheckPageTitles.REVERIFY_ALL_INFORMATION : selfCheckPageTitles.CONFIRM_ALL_INFORMATION}
        </title>
      </Helmet>
      <Alert className={CLASS_NAMES.infoAlert} status="info">
        <Alert.Heading>Accuracy of Data</Alert.Heading>
        <Alert.Text className={CLASS_NAMES.infoAlertText}>
          Carefully review the information entered below for accuracy before you hit submit. This may help prevent an
          incorrect Self Check result.
        </Alert.Text>
      </Alert>
      {!isUserInfoFlagged && (
        <>
          <PersonalInfoSection hideSsn={documentType !== DocumentType.LIST_B_C_DOCUMENTS} userInfo={userInfo} />
          <Divider className={CLASS_NAMES.divider} />
        </>
      )}
      <CitizenshipStatusSection
        className={CLASS_NAMES.citizenshipStatusSection}
        citizenshipStatus={citizenshipStatus}
      />
      {!isReverifyMode && (
        <>
          {!isEmpty(documentDetailsData) && (
            <>
              <Divider className={CLASS_NAMES.divider} />
              <DocumentDetailsSection data={documentDetailsData} />
            </>
          )}
          <ActionFooter
            className={CLASS_NAMES.actionFooter}
            onCancel={onCancel}
            cancelButtonText="Back"
            onSubmit={handleSubmit}
            submitButtonVariant="submit"
          />
        </>
      )}
      {isReverifyMode && caseData && (
        <>
          <Divider className={CLASS_NAMES.divider} />
          <ReverifySection
            caseNumber={caseData.caseNumber}
            data={data}
            onSuccess={onReverifySuccess}
            reverificationFields={reverificationFields}
          />
        </>
      )}
    </div>
  );
}

export default SelfCheckFormReviewMarkup;
