import React from 'react';
import { Alert } from '@ver-uds/uswds-react';

declare global {
  interface Document {
    documentMode?: number;
  }
}

function IEDeprecationAlert(): React.JSX.Element | null {
  // Detect Internet Explorer 6-11
  // https://stackoverflow.com/a/68857400
  const isIE = !!document.documentMode;

  return isIE ? (
    <Alert status="error" className="margin-bottom-5">
      <Alert.Heading>Your browser is not supported.</Alert.Heading>
      <Alert.Text>
        Internet Explorer is not supported by myE-Verify as of May 1st, 2022. Please click the links below to download
        the latest versions of the supported browsers:
      </Alert.Text>
      <ul>
        <li>
          <a
            href="https://www.microsoft.com/en-us/edge"
            aria-label="Download Microsoft Edge"
            target="_blank"
            rel="noopener noreferrer"
          >
            Microsoft Edge
          </a>
        </li>
        <li>
          <a
            href="https://www.google.com/chrome/downloads/"
            aria-label="Download Google Chrome"
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Chrome
          </a>
        </li>
        <li>
          <a
            href="https://www.mozilla.org/en-US/firefox/new/"
            aria-label="Download Mozilla Firefox"
            target="_blank"
            rel="noopener noreferrer"
          >
            Mozilla Firefox
          </a>
        </li>
        <li>
          <a
            href="https://support.apple.com/downloads/safari"
            aria-label="Download Safari"
            target="_blank"
            rel="noopener noreferrer"
          >
            Safari
          </a>
        </li>
      </ul>
    </Alert>
  ) : null;
}

export default IEDeprecationAlert;
