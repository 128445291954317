import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import get from 'lodash/get';
import paths from '../../routing/paths';
import { useCaseTncTypeQuery } from '../../services/CaseApi';
import DocumentUploadMarkup from './DocumentUploadMarkup';

function DocumentUpload(): React.JSX.Element {
  const urlParams = useParams();
  const caseNumber = get(urlParams, 'caseNumber', null);
  const {
    data: caseTncTypeData,
    isError,
    isFetching,
    isLoading: isCaseTncTypeLoading,
    isSuccess,
  } = useCaseTncTypeQuery(caseNumber);
  const isCaseTncTypeIdle = !isCaseTncTypeLoading && !isError && !isFetching && !isSuccess;
  if (isError || (isSuccess && !get(caseTncTypeData, 'uploadDocumentType'))) {
    return <Navigate to={paths.error} />;
  }

  return (
    <div>
      {!isCaseTncTypeIdle && !isCaseTncTypeLoading && caseTncTypeData?.uploadDocumentType && (
        <DocumentUploadMarkup caseNumber={caseNumber} uploadDocumentType={caseTncTypeData.uploadDocumentType} />
      )}
    </div>
  );
}

export default DocumentUpload;
