import React from 'react';
import classNames from 'classnames';
import noop from 'lodash/noop';
import { Modal } from '@ver-uds/react';
import { CustomClassValue } from '../../../../utils/types';
import ReturnHomeButton from '../../../Actions/ReturnHomeButton';
import { UploadDocumentType } from '../../../../services/CaseApi';
import { UploadSuccessModalVariant } from './constants';
import GenericUploadSuccessModalContent from './content/GenericUploadSuccessModalContent';
import NatzUploadSuccessModalContent from './content/NatzUploadSuccessModalContent';
import SelfCheckUploadSuccessModalContent from './content/SelfCheckUploadSuccessModalContent';
import { getUploadSuccessModalVariant } from './util';

export interface DocumentUploadSuccessModalMarkupProps {
  className?: CustomClassValue;
  isOpen: boolean;
  isSelfCheckCase?: boolean;
  uploadDocumentType: UploadDocumentType;
}

const baseClassName = 'document-upload-success-modal';
const CLASS_NAMES = {
  base: baseClassName,
  content: `${baseClassName}__content`,
};

function DocumentUploadSuccessModalMarkup({
  className = undefined,
  isOpen,
  isSelfCheckCase = undefined,
  uploadDocumentType,
}: DocumentUploadSuccessModalMarkupProps): React.JSX.Element {
  const contentVariant = getUploadSuccessModalVariant(uploadDocumentType, isSelfCheckCase);
  return (
    <div className={classNames(CLASS_NAMES.base, className)}>
      <Modal id={CLASS_NAMES.base} header="Thank you" open={isOpen} hasDismissButton={false} close={noop}>
        <Modal.Content className={CLASS_NAMES.content}>
          {contentVariant === UploadSuccessModalVariant.SELF_CHECK && <SelfCheckUploadSuccessModalContent />}
          {contentVariant === UploadSuccessModalVariant.NATZ && <NatzUploadSuccessModalContent />}
          {contentVariant === UploadSuccessModalVariant.GENERIC && <GenericUploadSuccessModalContent />}
        </Modal.Content>
        <Modal.Footer>
          <ReturnHomeButton variant="primary" />
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default DocumentUploadSuccessModalMarkup;
