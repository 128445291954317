import React from 'react';
import classNames from 'classnames';
import { Accordion } from '@ver-uds/uswds-react';
import { UserInfoResponseData } from '../../../../services/UserApi';
import IdentityProofingAttemptCounter from '../../VerifyIdentity/IdentityProofingAttemptCounter';
import ActionFooter from '../../../ActionFooter/ActionFooter';
import ConfirmCreditInformationHelpSection from '../../../HelpSections/ConfirmCreditInformationHelpSection/ConfirmCreditInformationHelpSection';
import ConfirmGovernmentRecordsHelpSection from '../../../HelpSections/ConfirmGovernmentRecordsHelpSection/ConfirmGovernmentRecordsHelpSection';
import FailedAttemptErrorAlert from './FailedAttemptErrorAlert';

export interface FailedAttemptMarkupProps {
  userInfo?: UserInfoResponseData;
  onSubmit: () => void;
  onCancel: () => void;
}

const baseClassName = 'failed-attempt-markup';
export const CLASS_NAMES = {
  base: baseClassName,
  actionFooter: classNames(`${baseClassName}__action-footer`, 'margin-top-4'),
  helpSection: classNames(`${baseClassName}__help-section`, 'margin-top-6'),
};
const SUBMIT_BUTTON_TEXT = 'Try Again';

function FailedAttemptMarkup({
  userInfo = undefined,
  onSubmit,
  onCancel,
}: FailedAttemptMarkupProps): React.JSX.Element {
  return (
    <div className={baseClassName}>
      <h2>Failed Attempt</h2>
      <IdentityProofingAttemptCounter idProofingAttempts={userInfo?.idProofingAttempts} />
      <FailedAttemptErrorAlert />
      <ActionFooter
        className={CLASS_NAMES.actionFooter}
        onCancel={onCancel}
        onSubmit={onSubmit}
        submitButtonText={SUBMIT_BUTTON_TEXT}
      />
      <Accordion className={CLASS_NAMES.helpSection} bordered>
        <ConfirmCreditInformationHelpSection />
        <ConfirmGovernmentRecordsHelpSection />
      </Accordion>
    </div>
  );
}

export default FailedAttemptMarkup;
