import React from 'react';
import { SubmitHandler } from 'react-hook-form';
import classNames from 'classnames';
import { Alert } from '@ver-uds/uswds-react';
import type { CustomClassValue } from '../../../utils/types';
import { SelfLockChallengeQuestion } from '../../../services/SelfLockApi';
import SelfLockLockForm from './Form/SelfLockLockForm';

export interface SelfLockLockMarkupProps {
  className?: CustomClassValue;
  onSubmit: SubmitHandler<SelfLockChallengeQuestion[]>;
}

const baseClassName = 'self-lock-lock';
const CLASS_NAMES = {
  base: baseClassName,
  form: classNames(`${baseClassName}__form`, 'margin-top-4'),
  header: `${baseClassName}__header`,
  infoAlert: classNames(`${baseClassName}__info-alert`, 'margin-top-3'),
  instructionsSection: classNames(`${baseClassName}__instructions-section`, 'margin-top-2', 'grid-col-9'),
};

function SelfLockLockMarkup({ className = undefined, onSubmit }: SelfLockLockMarkupProps): React.JSX.Element {
  return (
    <div className={classNames(CLASS_NAMES.base, className)}>
      <header className={CLASS_NAMES.header}>
        <h1>Self Lock Challenge Questions</h1>
      </header>
      <div className={CLASS_NAMES.instructionsSection}>
        <p>
          To lock your Social Security number (SSN), complete the information below and select and answer the Self Lock
          challenge questions. You must correctly answer these questions to verify your identity if you receive a Self
          Lock DHS Tentative Nonconfirmation (Mismatch).
        </p>
        <p>
          Select one challenge question from each of the three drop down lists and provide an answer to each question in
          the space provided. Your answer should be one you can easily remember. Your challenge questions differ from
          your password security questions.
        </p>
      </div>
      <Alert status="info" className={classNames(CLASS_NAMES.infoAlert)}>
        <Alert.Text>
          <div className="grid-col-9">
            Answers should be remembered easily, but only memorable to you. The questions can be asked via phone in the
            event of a Self Lock DHS Mismatch.
          </div>
        </Alert.Text>
      </Alert>
      <SelfLockLockForm className={CLASS_NAMES.form} onSubmit={onSubmit} />
    </div>
  );
}

export default SelfLockLockMarkup;
