import React from 'react';
import { Navigate } from 'react-router-dom';
import paths from '../../../../routing/paths';
import { useGetUserInfo } from '../../../../services/UserApi';
import { UserInfoFormData } from '../constants';
import SelfCheckFormConfirmIdentityMarkup from './SelfCheckFormConfirmIdentityMarkup';

export interface SelfCheckFormConfirmIdentityProps {
  onSubmit: (data: UserInfoFormData) => void;
  identityFormData?: UserInfoFormData;
}

function SelfCheckFormConfirmIdentity({
  onSubmit,
  identityFormData = undefined,
}: SelfCheckFormConfirmIdentityProps): React.JSX.Element {
  const { data, error, isError, isFetching, isLoading, isSuccess } = useGetUserInfo();
  const isIdle = !isError && !isFetching && !isLoading && !isSuccess;

  if (error) {
    return <Navigate to={paths.error} />;
  }

  return (
    <div>
      {!isLoading && !isIdle && (
        <SelfCheckFormConfirmIdentityMarkup onSubmit={onSubmit} userInfo={data} identityFormData={identityFormData} />
      )}
    </div>
  );
}

export default SelfCheckFormConfirmIdentity;
