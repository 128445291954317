import React, { useState } from 'react';
import { Button } from '@ver-uds/react';
import { get } from 'lodash';
import classNames from 'classnames';
import { useEnvironment } from '../../../context/Environment/EnvironmentContext';
import { redirectTo } from '../../../utils/routing';

const baseClassName = 'create-account-button';
const CLASS_NAMES = {
  base: classNames('usa-button', baseClassName, 'height-5'),
};

function CreateAccountButton(): React.JSX.Element {
  const { environmentVariables } = useEnvironment();
  const [isClicked, setClicked] = useState(false);

  const handleCreateAccountClick = (): void => {
    setClicked(true);
  };

  if (isClicked) {
    const createAccountRoute = get(environmentVariables, 'MYEVERIFY_WEB_SERVICE_CREATE_ACCOUNT', '/');

    redirectTo(createAccountRoute);
  }

  return (
    <Button className={CLASS_NAMES.base} as="button" onClick={handleCreateAccountClick} variant="secondary">
      Create Account
    </Button>
  );
}

export default CreateAccountButton;
