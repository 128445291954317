import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import get from 'lodash/get';
import paths from '../../../../../routing/paths';
import {
  SelfCheckCaseReverificationFieldsResponseData,
  SelfCheckCaseReverificationRequest,
  SelfCheckReverificationFieldNameUserInputRequired,
  useReverifySelfCheckCaseMutation,
} from '../../../../../services/SelfCheckApi';
import { getDataByFieldName } from '../util';
import { injectURLParams } from '../../../../../utils/routing';
import ReverifySectionMarkup, { ReverifySectionMarkupProps } from './ReverifySectionMarkup';

export const userInputRequiredFieldNames = [
  'firstName',
  'middleName',
  'lastName',
  'dob',
  'uscisANumber',
  'countryOfIssuance',
  'documentExpirationDate',
  'i551Number',
  'i766Number',
  'i94Number',
  'usPassportNumber',
  'foreignPassportNumber',
];

export interface ReverifySectionProps extends Omit<ReverifySectionMarkupProps, 'reverificationFields' | 'onSubmit'> {
  caseNumber: string;
  onSuccess: () => void;
  reverificationFields: SelfCheckCaseReverificationFieldsResponseData;
}

function ReverifySection({
  reverificationFields,
  caseNumber,
  data,
  onSuccess,
  ...props
}: ReverifySectionProps): React.JSX.Element {
  const {
    data: reverifyMutationResponseData,
    error: reverifyMutationError,
    isPending: isReverifyMutationLoading,
    isSuccess: isReverifyMutationSuccess,
    mutate,
  } = useReverifySelfCheckCaseMutation(caseNumber);
  const navigate = useNavigate();

  useEffect(() => {
    if (isReverifyMutationSuccess) {
      navigate(injectURLParams(paths.caseDetail, { caseNumber: reverifyMutationResponseData?.caseNumber }));
      onSuccess();
    }
  }, [isReverifyMutationSuccess, navigate, reverifyMutationResponseData, onSuccess]);

  if (reverifyMutationError) {
    /**
     * TODO: https://maestro.dhs.gov/jira/browse/UVME-2892
     * show modal alert and remain on page
     */
    console.log(`reverification error:\n${JSON.stringify(reverifyMutationError)}`); // eslint-disable-line no-console
  }

  const handleSubmit = (
    userInputRequiredFieldsPayload: Pick<
      SelfCheckCaseReverificationRequest,
      SelfCheckReverificationFieldNameUserInputRequired
    >,
  ): void => {
    const payload = reverificationFields.reduce((accumulator: SelfCheckCaseReverificationRequest, fieldName) => {
      /**
       * Make sure we send back all the fields marked as requiring reverification.
       * For example, user info fields, which we do not let the user edit, but the API expects to get back.
       */
      accumulator[fieldName] = get(userInputRequiredFieldsPayload, fieldName, getDataByFieldName(fieldName, data));
      return accumulator;
    }, {});
    mutate(payload);
  };

  return (
    <div>
      {!isReverifyMutationLoading && (
        <ReverifySectionMarkup
          data={data}
          onSubmit={handleSubmit}
          reverificationFields={
            reverificationFields.filter((fieldName) =>
              userInputRequiredFieldNames.includes(fieldName),
            ) as SelfCheckReverificationFieldNameUserInputRequired[]
          }
          {...props} /* eslint-disable-line react/jsx-props-no-spreading */
        />
      )}
    </div>
  );
}

export default ReverifySection;
