import React from 'react';
import { Accordion } from '@ver-uds/uswds-react';
import HelpSectionContent from '../HelpSectionContent';

const baseClassName = 'naturalization-certificate-help-section';
const CLASS_NAMES = {
  heading: `${baseClassName}__heading`,
  content: `${baseClassName}__content`,
};

function NaturalizationHelpSection(): React.JSX.Element {
  return (
    <>
      <Accordion.Heading className={CLASS_NAMES.heading} controls={baseClassName}>
        Help with Naturalization
      </Accordion.Heading>
      <HelpSectionContent id={baseClassName} className={CLASS_NAMES.content}>
        <p>
          Naturalization is the conferring of U.S. citizenship after birth by any means whatsoever. There are various
          ways to become a U.S. citizen through the process of naturalization. The applicant has the burden of
          establishing by a preponderance of the evidence that he or she meets the requirements for naturalization.
        </p>
        <p>
          <span className="text-bold"> General Eligibility Requirements for Naturalization </span>
          <br />
          The following are the general naturalization requirements that an applicant must meet in order to become a
          U.S. citizen:
        </p>
        <ul>
          <li>The applicant must be age 18 or older at the time of filing for naturalization</li>
          <li>
            The applicant must be a lawful permanent resident (LPR) for at least five years before being eligible for
            naturalization
          </li>
          <li>
            The applicant must have continuous residence in the United States as an LPR for at least five years
            immediately preceding the date of filing the application and up to the time of admission to citizenship
          </li>
          <li>
            The applicant must be physically present in the United States for at least 30 months out of the five years
            immediately preceding the date of filing the application
          </li>
          <li>
            The applicant must have lived within the state or USCIS district with jurisdiction over the applicant’s
            place of residence for at least three months prior to the date of filing
          </li>
          <li>
            The applicant must demonstrate good moral character for five years prior to filing for naturalization, and
            during the period leading up to the administration of the Oath of Allegiance
          </li>
          <li>
            The applicant must have an attachment to the principles of the U.S. Constitution and be well disposed to the
            good order and happiness of the United States during all relevant periods under the law
          </li>
          <li>
            The applicant must be able to read, write, and speak and understand English and have knowledge and an
            understanding of U.S. history and government
          </li>
        </ul>
        <p>
          For more information on Citizenship and Naturalization, visit{' '}
          <a href="https://www.uscis.gov/policy-manual/volume-12">https://www.uscis.gov/policy-manual/volume-12</a>
        </p>
      </HelpSectionContent>
    </>
  );
}

export default NaturalizationHelpSection;
