import React, { useContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import map from 'lodash/map';
import routes from './routes';
import { AuthenticationContext } from '../context/Authentication/AuthenticationContext';
import PrivateRoute from './PrivateRoute';
import LogoutWarningModal from '../components/LogoutWarningModal';
import { UserContext } from '../context/User/UserContext';

function Router(): React.JSX.Element {
  const { isAuthenticated, isConfigValid } = useContext(AuthenticationContext);
  const { hasCompletedIdProofing } = useContext(UserContext).userInfoResponseData;

  return (
    <>
      <LogoutWarningModal />
      {/* getUserConfirmation is overridden to prevent the default window.confirm history block behavior. */}
      <BrowserRouter>
        <Routes>
          {map(routes, ({ children, path, isPublic }, key) => (
            <Route
              key={key}
              path={path}
              element={
                <PrivateRoute
                  path={path}
                  isAuthenticated={isAuthenticated()}
                  isLoading={!isConfigValid()}
                  isPublic={isPublic}
                  hasCompletedIdProofing={hasCompletedIdProofing}
                >
                  {children}
                </PrivateRoute>
              }
            />
          ))}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default Router;
