import React from 'react';
import { Modal } from '@ver-uds/react';
import classNames from 'classnames';
import ssaHelpImg from '../../assets/img/case-tracker/help/ssa@2x.jpg';
import dhsHelpImg from '../../assets/img/case-tracker/help/dhs@2x.jpg';

export interface CaseTrackerHelpModalProps {
  handleClose: () => void;
  isOpen: boolean;
}

export const MODAL_ID = 'case-tracker-help-modal';

const baseClassName = MODAL_ID;
export const CLASS_NAMES = {
  base: baseClassName,
  contentSection: `${baseClassName}__content-section`,
  textSection: `${baseClassName}__text-section`,
  imagesSection: classNames(`${baseClassName}__images-section`, 'margin-top-3'),
  imageSectionColumn: classNames(`${baseClassName}__images-section-col`, 'grid-col-12', 'tablet:grid-col-6'),
  imageSectionMarginBottomMobile: classNames('tablet:margin-bottom-0', 'margin-bottom-205'),
  imageWrapper: classNames(`${baseClassName}__image`, 'margin-top-1'),
};

function CaseTrackerHelpModal({ handleClose, isOpen }: CaseTrackerHelpModalProps): React.JSX.Element {
  return (
    <Modal id={MODAL_ID} header="Case Tracker Help" open={isOpen} close={handleClose}>
      <Modal.Content className={CLASS_NAMES.contentSection}>
        <div className={CLASS_NAMES.textSection}>
          E-Verify Case Numbers are 13 numbers followed by 2 letters. If your employer used E-Verify, your Case Number
          can be found on the Further Action Notice letter provided by them.
        </div>
        <div className={CLASS_NAMES.imagesSection}>
          <div className="grid-row grid-gap">
            <div className={classNames(CLASS_NAMES.imageSectionColumn, CLASS_NAMES.imageSectionMarginBottomMobile)}>
              <h4>SSA Further Action Notice</h4>
              <div className={CLASS_NAMES.imageWrapper}>
                <img src={ssaHelpImg} alt="Example of SSA Further Action Notice Document" />
              </div>
            </div>
            <div className={CLASS_NAMES.imageSectionColumn}>
              <h4>DHS Further Action Notice</h4>
              <div className={CLASS_NAMES.imageWrapper}>
                <img src={dhsHelpImg} alt="Example of DHS Further Action Notice Document" />
              </div>
            </div>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
}

export default CaseTrackerHelpModal;
