import React from 'react';
import ConfirmIdentity from '../components/ConfirmIdentity/ConfirmIdentity';
import PageContentLayout from '../layouts/PageContentLayout/PageContentLayout';

function ConfirmIdentityPage(): React.JSX.Element {
  return (
    <PageContentLayout title="Confirm Identity">
      <ConfirmIdentity />
    </PageContentLayout>
  );
}

export default ConfirmIdentityPage;
