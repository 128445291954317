import { useContext, useMemo } from 'react';
import {
  QueryClient,
  QueryObserverResult,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import { AuthenticationContext } from '../../context/Authentication/AuthenticationContext';
import BaseApi, { useConfiguration } from '../BaseApi';
import { Case, CaseStatusData, getCaseStatusQueryID, getOpenSelfCheckCaseQueryID } from '../CaseApi';
import paths from '../paths.json';
import { apiMutation, doGet, doPatch, doPost } from '../ServicesUtils';
import {
  SubmitSelfCheckCaseRequest,
  SelfCheckCaseResponseData,
  SelfCheckCaseResponse,
  SelfCheckCaseReverificationFieldsResponseData,
  SelfCheckCaseReverificationFieldsResponse,
  SelfCheckCaseReverificationRequest,
  SelfCheckCaseClosureRequest,
  CaseStatus,
  CaseElligibilityStatement,
} from './constants';
import { RawCaseStatus } from '../CaseApi/CaseApiConstants';

class SelfCheckApi extends BaseApi {
  private getSelfCheckCaseEndpoint(): string {
    return this.getEndpoint(paths.cases.submitSelfCheckCase);
  }

  private getReverificationFieldsEndpoint(caseNumber: string): string {
    return this.getEndpoint(paths.cases.getReverificationFields, caseNumber);
  }

  private getReverifySelfCheckCaseEndpoint(caseNumber: string): string {
    return this.getEndpoint(paths.cases.reverifySelfCheckCase, caseNumber);
  }

  private getCaseDetailsByCaseNumberEndpoint(caseNumber: string): string {
    return this.getEndpoint(paths.cases.getCaseDetailsByCaseNumber, caseNumber);
  }

  private getCloseSelfCheckCaseEndpoint(caseNumber: string): string {
    return this.getEndpoint(paths.cases.closeSelfCheckCase, caseNumber);
  }

  private getReferSelfCheckCaseEndpoint(caseNumber: string): string {
    return this.getEndpoint(paths.cases.referSelfCheckCase, caseNumber);
  }

  private getNoActionSelfCheckCaseEndpoint(caseNumber: string): string {
    return this.getEndpoint(paths.cases.noActionSelfCheckCase, caseNumber);
  }

  async submitSelfCheckCase(data: SubmitSelfCheckCaseRequest): Promise<SelfCheckCaseResponseData> {
    const endpoint = this.getSelfCheckCaseEndpoint();
    return doPost<SubmitSelfCheckCaseRequest, SelfCheckCaseResponse>(endpoint, data);
  }

  async getReverificationFields(caseNumber: string): Promise<SelfCheckCaseReverificationFieldsResponseData> {
    const endpoint = this.getReverificationFieldsEndpoint(caseNumber);
    return (await doGet<SelfCheckCaseReverificationFieldsResponse>(endpoint)) || [];
  }

  async reverifySelfCheckCase(
    caseNumber: string,
    data: SelfCheckCaseReverificationRequest,
  ): Promise<SelfCheckCaseResponseData> {
    const endpoint = this.getReverifySelfCheckCaseEndpoint(caseNumber);
    return doPatch<SelfCheckCaseReverificationRequest, SelfCheckCaseResponse>(endpoint, data);
  }

  async getCaseDetailsByCaseNumber(caseNumber: string): Promise<SelfCheckCaseResponseData | undefined> {
    const endpoint = this.getCaseDetailsByCaseNumberEndpoint(caseNumber);
    return doGet<SelfCheckCaseResponse>(endpoint);
  }

  async closeSelfCheckCase(caseNumber: string, data: SelfCheckCaseClosureRequest): Promise<SelfCheckCaseResponseData> {
    const endpoint = this.getCloseSelfCheckCaseEndpoint(caseNumber);
    return doPost<SelfCheckCaseClosureRequest, SelfCheckCaseResponse>(endpoint, data);
  }

  async referSelfCheckCase(caseNumber: string): Promise<SelfCheckCaseResponseData> {
    const endpoint = this.getReferSelfCheckCaseEndpoint(caseNumber);
    return doPost<undefined, SelfCheckCaseResponse>(endpoint);
  }

  async noActionSelfCheckCase(caseNumber: string): Promise<SelfCheckCaseResponseData> {
    const endpoint = this.getNoActionSelfCheckCaseEndpoint(caseNumber);
    return doPost<undefined, SelfCheckCaseResponse>(endpoint);
  }
}

const useSelfCheckApi = (): SelfCheckApi => {
  const configuration = useConfiguration();
  const authContext = useContext(AuthenticationContext);
  authContext.refreshLastAuthenticatedTime();
  return useMemo(() => new SelfCheckApi(configuration), [configuration]);
};

const useSelfCheckMutation = apiMutation(useSelfCheckApi);

export const getCaseDetailsByCaseNumberQueryID = 'getCaseDetailsByCaseNumber';
export const submitSelfCheckCaseMutationID = 'submitSelfCheckCase';
export const useSubmitSelfCheckCaseMutation = (): UseMutationResult<
  SelfCheckCaseResponseData,
  Error,
  SubmitSelfCheckCaseRequest,
  SelfCheckCaseResponseData
> => {
  const api = useSelfCheckApi();
  const queryClient = useQueryClient();

  let previousGetOpenSelfCheckCaseQueryResult: Case | undefined;

  return useSelfCheckMutation<SubmitSelfCheckCaseRequest, SelfCheckCaseResponseData>(
    [submitSelfCheckCaseMutationID],
    (payload) => api.submitSelfCheckCase(payload),
    {
      /**
       * Because of react-query's opinionated approach to mutation side-effects,
       * we must update/return a "mirrored" (GET) query to this mutation.
       * Otherwise, the mutation hook doesn't know how to update itself
       * or change the mutation status upon the API action completing.
       *
       * Our main objective here is to clear out the old "open self check case",
       * and set the "new self check case" when submission succeeds.
       *
       * Because of react-query's mirrored update requirement (mentioned above),
       * we take the result of the getOpenSelfCheckCase query, and use this value (if exists)
       * as our "template" for our optimistic update from onMutate.
       *
       * On success, we update our new open case number and the result of the
       * getOpenSelfCheckCase query with this new case number.
       *
       * On error, we reset the open self check case to the "old" case number,
       * since we were unsuccessful in creating a new case.
       *
       * On settled, we tell react-query to fetch these queries in the background (invalidate queries),
       * in order to confirm/update the data we have manually set in the query cache.
       */
      onMutate: (): SelfCheckCaseResponseData => {
        queryClient.cancelQueries({ queryKey: [getOpenSelfCheckCaseQueryID] });

        previousGetOpenSelfCheckCaseQueryResult = queryClient.getQueryData<Case | undefined>([
          getOpenSelfCheckCaseQueryID,
        ]);

        const openSelfCheckCase = queryClient.getQueryData<SelfCheckCaseResponseData>([
          getCaseDetailsByCaseNumberQueryID,
          previousGetOpenSelfCheckCaseQueryResult?.caseNumber,
        ]);

        queryClient.setQueryData<Case | undefined>([getOpenSelfCheckCaseQueryID], undefined);

        return {
          ...(openSelfCheckCase || {}),
          caseStatus: CaseStatus.UNCONFIRMED_DATA,
        } as SelfCheckCaseResponseData;
      },
      onSuccess: (responseData: SelfCheckCaseResponseData): void => {
        queryClient.setQueryData<Case>([getOpenSelfCheckCaseQueryID], {
          caseNumber: responseData.caseNumber,
        } as Case);

        queryClient.setQueryData([getCaseDetailsByCaseNumberQueryID, responseData.caseNumber], responseData);
      },
      onError: (): void => {
        queryClient.setQueryData<Case | undefined>(
          [getOpenSelfCheckCaseQueryID],
          previousGetOpenSelfCheckCaseQueryResult,
        );
      },
      onSettled: (responseData?: SelfCheckCaseResponseData): void => {
        queryClient.invalidateQueries({ queryKey: [getOpenSelfCheckCaseQueryID] });
        if (responseData) {
          queryClient.invalidateQueries({ queryKey: [getCaseDetailsByCaseNumberQueryID, responseData.caseNumber] });
        }
      },
    },
  );
};

export const getReverificationFieldsQueryID = 'getReverificationFields';
export const useGetReverificationFieldsQuery = (
  caseNumber: string,
): QueryObserverResult<SelfCheckCaseReverificationFieldsResponseData> => {
  const api = useSelfCheckApi();
  const { isAuthenticated } = useContext(AuthenticationContext);
  return useQuery({
    queryKey: [getReverificationFieldsQueryID, caseNumber],
    queryFn: () => api.getReverificationFields(caseNumber),
    enabled: !isEmpty(caseNumber) && isAuthenticated() && api.isConfigurationValid(),
  });
};

export const reverifySelfCheckCaseMutationID = 'reverifySelfCheckCase';
export const useReverifySelfCheckCaseMutation = (
  caseNumber: string,
): UseMutationResult<
  SelfCheckCaseResponseData,
  Error,
  SelfCheckCaseReverificationRequest,
  SelfCheckCaseResponseData
> => {
  const api = useSelfCheckApi();
  return useSelfCheckMutation<SelfCheckCaseReverificationRequest, SelfCheckCaseResponseData>(
    [reverifySelfCheckCaseMutationID, caseNumber],
    (payload) => api.reverifySelfCheckCase(caseNumber, payload),
  );
};

export const useGetCaseDetailsByCaseNumberQuery = (
  caseNumber?: string,
): QueryObserverResult<SelfCheckCaseResponseData | undefined> => {
  const api = useSelfCheckApi();
  const { isAuthenticated } = useContext(AuthenticationContext);

  return useQuery({
    queryKey: [getCaseDetailsByCaseNumberQueryID, caseNumber],
    queryFn: () => api.getCaseDetailsByCaseNumber(caseNumber || ''),
    enabled: !isUndefined(caseNumber) && isAuthenticated() && api.isConfigurationValid(),
  });
};

export const closeSelfCheckCaseMutationID = 'closeSelfCheckCase';
export const useCloseSelfCheckCaseMutation = (
  caseNumber: string,
): UseMutationResult<SelfCheckCaseResponseData, Error, SelfCheckCaseClosureRequest, SelfCheckCaseResponseData> => {
  const api = useSelfCheckApi();
  const queryClient = useQueryClient();
  let previousGetOpenSelfCheckCaseQueryResult: Case;
  let previousGetCaseDetailsByCaseNumberQueryResult: SelfCheckCaseResponseData;
  return useSelfCheckMutation<SelfCheckCaseClosureRequest, SelfCheckCaseResponseData>(
    [closeSelfCheckCaseMutationID, caseNumber],
    (payload) => api.closeSelfCheckCase(caseNumber, payload),
    {
      /**
       * Because of react-query's opinionated approach to mutation side-effects,
       * we must update/return a "mirrored" (GET) query to this mutation.
       * Otherwise, the mutation hook doesn't know how to update itself
       * or change the mutation status upon the API action completing.
       *
       * Our main objective here is to clear out the old "open self check case",
       * since we know this is what our API will do in the DB as part of POST /close.
       *
       * Because of react-query's mirrored update requirement (mentioned above),
       * we take the result of the getOpenSelfCheckCase query, and use this value
       * as our "template" for our optimistic update from onMutate.
       *
       * On success, we update our existing open case number and the result of the
       * getOpenSelfCheckCase query with the response from the API.
       *
       * On error, we reset the open self check case to the "old" case number,
       * since we were unsuccessful in closing the case.
       * We also, reset the case details for this case number, since it is presumably _not_ closed.
       * (i.e. our optimistic update cannot be trusted because of the error)
       *
       * On settled, we tell react-query to fetch these queries in the background (invalidate queries),
       * in order to confirm/update the data we have manually set in the query cache.
       */
      onMutate: (): SelfCheckCaseResponseData => {
        queryClient.cancelQueries({ queryKey: [getOpenSelfCheckCaseQueryID] });

        previousGetOpenSelfCheckCaseQueryResult = queryClient.getQueryData<Case>([getOpenSelfCheckCaseQueryID]) as Case;

        previousGetCaseDetailsByCaseNumberQueryResult = queryClient.getQueryData<SelfCheckCaseResponseData>([
          getCaseDetailsByCaseNumberQueryID,
          caseNumber,
        ]) as SelfCheckCaseResponseData;

        queryClient.setQueryData<Case | undefined>([getOpenSelfCheckCaseQueryID], undefined);

        return {
          ...(previousGetCaseDetailsByCaseNumberQueryResult || {}),
          caseStatus: CaseStatus.CLOSED,
        } as SelfCheckCaseResponseData;
      },
      onSuccess: (responseData: SelfCheckCaseResponseData): void => {
        queryClient.setQueryData([getCaseDetailsByCaseNumberQueryID, caseNumber], responseData);
      },
      onError: (): void => {
        queryClient.setQueryData<Case>([getOpenSelfCheckCaseQueryID], previousGetOpenSelfCheckCaseQueryResult);

        queryClient.setQueryData<SelfCheckCaseResponseData>(
          [getCaseDetailsByCaseNumberQueryID, caseNumber],
          previousGetCaseDetailsByCaseNumberQueryResult,
        );
      },
      onSettled: (): void => {
        queryClient.invalidateQueries({ queryKey: [getOpenSelfCheckCaseQueryID] });
        queryClient.invalidateQueries({ queryKey: [getCaseDetailsByCaseNumberQueryID, caseNumber] });
      },
    },
  );
};

// TODO: figure out why ESLint thinks this is being defined multiple times
/* eslint-disable-next-line no-shadow */
enum TNCActionType {
  REFER = 'REFER',
  NO_ACTION = 'NO_ACTION',
}

const createTNCActionMutationOpts = (
  caseNumber: string,
  queryClient: QueryClient,
  actionType: TNCActionType,
): UseMutationOptions<SelfCheckCaseResponseData, Error, undefined, SelfCheckCaseResponseData> => {
  const getCaseDetailsByCaseNumberQueryKey = [getCaseDetailsByCaseNumberQueryID, caseNumber];
  const getCaseStatusQueryKey = [getCaseStatusQueryID, caseNumber];

  let previousCaseDetailsByCaseNumberQueryResult: SelfCheckCaseResponseData;
  let previousCaseStatusQueryResult: CaseStatusData;

  return {
    // Optimistically update the cache value on mutate, but store
    // the old value and return it so that it's accessible in case of
    // an error
    onMutate: (): SelfCheckCaseResponseData => {
      queryClient.cancelQueries({ queryKey: getCaseDetailsByCaseNumberQueryKey });
      queryClient.cancelQueries({ queryKey: getCaseStatusQueryKey });

      previousCaseDetailsByCaseNumberQueryResult = queryClient.getQueryData<SelfCheckCaseResponseData>(
        getCaseDetailsByCaseNumberQueryKey,
      ) as SelfCheckCaseResponseData;
      previousCaseStatusQueryResult = queryClient.getQueryData<CaseStatusData>(getCaseStatusQueryKey) as CaseStatusData;

      queryClient.setQueryData<SelfCheckCaseResponseData>(getCaseDetailsByCaseNumberQueryKey, {
        ...previousCaseDetailsByCaseNumberQueryResult,
        caseStatus: actionType === TNCActionType.REFER ? CaseStatus.REFERRED : CaseStatus.CLOSED,
        caseEligibilityStatement: actionType === TNCActionType.REFER ? null : CaseElligibilityStatement.NO_ACTION_FNC,
      });
      queryClient.setQueryData<CaseStatusData>(getCaseStatusQueryKey, {
        ...previousCaseStatusQueryResult,
        /**
         * Optimistically update with assumption all referred views look the same in UI.
         * Any logic based on what the "genuine" referred status is should be handled by API.
         */
        caseStatus: actionType === TNCActionType.REFER ? RawCaseStatus.EMPLOYEE_REFERRED_DHS : RawCaseStatus.CLOSED,
      });

      return previousCaseDetailsByCaseNumberQueryResult;
    },
    // On failure, roll back to the previous values
    onError: (): void => {
      queryClient.setQueryData(getCaseDetailsByCaseNumberQueryKey, previousCaseDetailsByCaseNumberQueryResult);
      queryClient.setQueryData(getCaseStatusQueryKey, previousCaseStatusQueryResult);
    },
    // After success or failure, refetch the queries
    onSettled: (): void => {
      queryClient.invalidateQueries({ queryKey: getCaseDetailsByCaseNumberQueryKey });
      queryClient.invalidateQueries({ queryKey: getCaseStatusQueryKey });
    },
  };
};

export const referSelfCheckCaseMutationID = 'referSelfCheckCase';
export const useReferSelfCheckCaseMutation = (
  caseNumber: string,
): UseMutationResult<SelfCheckCaseResponseData, Error, undefined, SelfCheckCaseResponseData> => {
  const api = useSelfCheckApi();
  const queryClient = useQueryClient();

  return useSelfCheckMutation<undefined, SelfCheckCaseResponseData>(
    [referSelfCheckCaseMutationID, caseNumber],
    () => api.referSelfCheckCase(caseNumber),
    createTNCActionMutationOpts(caseNumber, queryClient, TNCActionType.REFER),
  );
};

export const noActionSelfCheckCaseMutationID = 'noActionSelfCheckCase';
export const useNoActionSelfCheckCaseMutation = (
  caseNumber: string,
): UseMutationResult<SelfCheckCaseResponseData, Error, undefined, SelfCheckCaseResponseData> => {
  const api = useSelfCheckApi();
  const queryClient = useQueryClient();

  return useSelfCheckMutation<undefined, SelfCheckCaseResponseData>(
    [noActionSelfCheckCaseMutationID, caseNumber],
    () => api.noActionSelfCheckCase(caseNumber),
    createTNCActionMutationOpts(caseNumber, queryClient, TNCActionType.NO_ACTION),
  );
};
