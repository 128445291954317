import React from 'react';
import classNames from 'classnames';
import { Accordion } from '@ver-uds/uswds-react';
import ActionFooter from '../../../ActionFooter/ActionFooter';
import ConfirmCreditInformationHelpSection from '../../../HelpSections/ConfirmCreditInformationHelpSection/ConfirmCreditInformationHelpSection';
import ConfirmGovernmentRecordsHelpSection from '../../../HelpSections/ConfirmGovernmentRecordsHelpSection/ConfirmGovernmentRecordsHelpSection';
import IdentityProofingAttemptCounter from '../../VerifyIdentity/IdentityProofingAttemptCounter';
import { UserInfoResponseData } from '../../../../services/UserApi';
import AttemptsExceededAlert from './AttemptsExceededAlert';

export interface AttemptsExceededMarkupProps {
  onSubmit: () => void;
  userInfo?: UserInfoResponseData;
}

const baseClassName = 'generate-quiz-error-markup';
export const CLASS_NAMES = {
  base: baseClassName,
  actionFooter: classNames(`${baseClassName}__action-footer`, 'margin-top-4'),
  helpSection: classNames(`${baseClassName}__help-section`, 'margin-top-6'),
};

const SUBMIT_BUTTON_TEXT = 'Return to myE-Verify homepage';

function AttemptsExceededMarkup({ onSubmit, userInfo = undefined }: AttemptsExceededMarkupProps): React.JSX.Element {
  const headerContent = 'Exceeded Failed Attempts';

  return (
    <div className={baseClassName}>
      <h2>{headerContent}</h2>
      <IdentityProofingAttemptCounter idProofingAttempts={userInfo?.idProofingAttempts} />
      <AttemptsExceededAlert userInfo={userInfo} />
      <ActionFooter
        hideCancelButton
        className={CLASS_NAMES.actionFooter}
        onSubmit={onSubmit}
        submitButtonText={SUBMIT_BUTTON_TEXT}
      />
      <Accordion className={CLASS_NAMES.helpSection} bordered>
        <ConfirmCreditInformationHelpSection />
        <ConfirmGovernmentRecordsHelpSection />
      </Accordion>
    </div>
  );
}

export default AttemptsExceededMarkup;
