import { ButtonGroup } from '@ver-uds/react';
import React from 'react';
import styled from 'styled-components';
import CreateAccountButton from '../Actions/CreateAccountButton/CreateAccountButton';
import SignInButton from '../Actions/SignInButton';
import checksSvg from './checksSvg';

const Gradient = styled.div`
  position: relative;

  .usa-hero .grid-container {
    height: 424px;
  }

  > .background-image {
    position: absolute;
    background-image: url(data:image/svg+xml;base64,${btoa(checksSvg)}),
      linear-gradient(to right, rgb(24, 43, 78) 20%, rgb(190, 64, 60) 80%);
    height: 100%;
    width: 100%;

    min-width: 100%;

    left: 50%;
    transform: translateX(-50%);transform
  }
`;

function Hero(): React.JSX.Element {
  return (
    <Gradient>
      <div className="background-image" />
      <section className="usa-hero" aria-label="Introduction">
        <div className="grid-container display-flex flex-row flex-align-center">
          <div className="desktop:grid-col-6 tablet:grid-col-9">
            <h1>
              <span className="usa-hero__heading--alt">Welcome to myE-Verify</span>
            </h1>
            <p>
              Protect your identity, track your E-Verify case, learn your rights and confirm your own employment
              eligibility. It’s easy and free!
            </p>
            <ButtonGroup className="padding-top-2">
              <CreateAccountButton />
              <SignInButton />
            </ButtonGroup>
          </div>
        </div>
      </section>
    </Gradient>
  );
}

export default Hero;
