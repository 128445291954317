import React from 'react';
import { Link } from 'react-router-dom';
import PageContentLayout from '../layouts/PageContentLayout/PageContentLayout';

function ErrorPage(): React.JSX.Element {
  return (
    <PageContentLayout title="General System Error" variant="error">
      <div className="grid-col-9">
        <h2>Something went wrong</h2>
        <p>
          MyE-Verify encountered a problem and is unable to complete your request at this time. Please wait a few
          minutes and try again. If the error persists, contact{' '}
          <a href="mailto:myeverify@uscis.dhs.gov">myeverify@uscis.dhs.gov</a> and tell us exactly what you were doing
          when you received the error so we can get this resolved for you as soon as possible.
        </p>
        <Link to="/" className="usa-button ver-button margin-top-5">
          Go Back Home
        </Link>
      </div>
    </PageContentLayout>
  );
}

export default ErrorPage;
