import React from 'react';
import classNames from 'classnames';
import { Tag } from '@ver-uds/react';
import { CustomClassValue } from '../../../../utils/types';
import { ReadableCaseStatus } from '../../../../services/CaseApi';
import { mapReadableCaseStatusToTagText, mapReadableCaseStatusToTagVariant } from './util';

export interface CaseDetailTagProps {
  className?: CustomClassValue;
  caseStatus: ReadableCaseStatus;
}

const baseClassName = 'case-detail-tag';
const CLASS_NAMES = {
  base: baseClassName,
};

function CaseDetailTag({ className = undefined, caseStatus }: CaseDetailTagProps): React.JSX.Element {
  return (
    <Tag className={classNames(CLASS_NAMES.base, className)} variant={mapReadableCaseStatusToTagVariant(caseStatus)}>
      {mapReadableCaseStatusToTagText(caseStatus)}
    </Tag>
  );
}

export default CaseDetailTag;
