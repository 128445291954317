import React, { useCallback, useContext, useMemo } from 'react';
import {
  useGetUserInfo,
  dobFieldName,
  ssnFieldName,
  firstNameFieldName,
  lastNameFieldName,
  hasAcceptedTermsAndConditionsFieldName,
  idProofingAttempts,
  idProofingLastAttempt,
  emailAddressFieldName,
  phoneNumberFieldName,
} from '../../services/UserApi';
import { AuthenticationContext } from '../Authentication/AuthenticationContext';

export interface UserContextData {
  emailAddress?: string;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  hasAcceptedTermsAndConditions?: boolean;
  idProofingAttempts?: number;
  hasExceededAttempts?: boolean;
  idProofingLastAttempt?: string;
  hasCompletedIdProofing?: boolean;
  hashKeyExists?: boolean;
  isPivotRecordNull: boolean;
  isLoading: boolean;
  isIdle: boolean;
}

export interface UserState {
  userInfoResponseData: UserContextData;
}

export const DEFAULT_STATE: UserState = {
  userInfoResponseData: {
    emailAddress: '',
    phoneNumber: '',
    firstName: '',
    lastName: '',
    hasAcceptedTermsAndConditions: false,
    idProofingAttempts: 0,
    hasExceededAttempts: false,
    idProofingLastAttempt: '',
    hasCompletedIdProofing: false,
    hashKeyExists: false,
    isPivotRecordNull: true,
    isLoading: true,
    isIdle: false,
  },
};

export const UserContext = React.createContext<UserState>(DEFAULT_STATE);

export type UserProviderProps = {
  children: React.ReactNode;
};

export function UserProvider({ children }: UserProviderProps): React.JSX.Element {
  const { isAuthenticated } = useContext(AuthenticationContext);
  const {
    isError,
    isFetching,
    isLoading: isGetUserInfoLoading,
    isSuccess,
    data: getUserInfoData,
  } = useGetUserInfo({
    disabled: !isAuthenticated(),
  });
  const isIdle = !isError && !isFetching && !isGetUserInfoLoading && !isSuccess;
  const MAX_QUIZ_ATTEMPTS = 3;

  const isPivotRecordNull = useCallback(
    (): boolean => !getUserInfoData?.[dobFieldName] && !getUserInfoData?.[ssnFieldName],
    [getUserInfoData],
  );
  const hasExceededAttempts = useCallback(
    (): boolean =>
      getUserInfoData?.[idProofingAttempts] ? getUserInfoData[idProofingAttempts] >= MAX_QUIZ_ATTEMPTS : false,
    [getUserInfoData, MAX_QUIZ_ATTEMPTS],
  );

  const userData = getUserInfoData;
  const state = useMemo(
    () => ({
      userInfoResponseData: {
        emailAddress: userData?.[emailAddressFieldName],
        phoneNumber: userData?.[phoneNumberFieldName],
        firstName: userData?.[firstNameFieldName],
        lastName: userData?.[lastNameFieldName],
        hasAcceptedTermsAndConditions: userData?.[hasAcceptedTermsAndConditionsFieldName],
        idProofingAttempts: userData?.[idProofingAttempts],
        hasExceededAttempts: hasExceededAttempts(),
        idProofingLastAttempt: userData?.[idProofingLastAttempt],
        hasCompletedIdProofing: userData?.hasCompletedIdProofing,
        hashKeyExists: userData?.hashKeyExists,
        isPivotRecordNull: isPivotRecordNull(),
        isLoading: isGetUserInfoLoading,
        isIdle,
      },
    }),
    [userData, isGetUserInfoLoading, isPivotRecordNull, hasExceededAttempts, isIdle],
  );
  return <UserContext.Provider value={state}>{children}</UserContext.Provider>;
}
