import React from 'react';
import classNames from 'classnames';
import { CustomClassValue } from '../../../../utils/types';

export interface CaseHistoryEmptySectionProps {
  className?: CustomClassValue;
  caseDescriptor: 'open' | 'closed';
}

const baseClassName = 'case-history-empty-section';
const CLASS_NAMES = {
  base: classNames(baseClassName, 'usa-section', 'bg-base-lightest'),
  textWrapper: classNames(`${baseClassName}__text-wrapper`, 'grid-container', 'text-center'),
};

function CaseHistoryEmptySection({
  className = undefined,
  caseDescriptor,
}: CaseHistoryEmptySectionProps): React.JSX.Element {
  return (
    <div className={classNames(CLASS_NAMES.base, className)}>
      <div className={CLASS_NAMES.textWrapper}>
        <p>
          {caseDescriptor === 'open'
            ? 'There are no open cases that require your attention at this time'
            : 'There are no closed cases available for you to view at this time'}
        </p>
      </div>
    </div>
  );
}

export default CaseHistoryEmptySection;
