import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { DropdownControl } from '@ver-uds/uswds-react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { CustomClassValue } from '../../utils/types';
import { Country } from '../../services/ReferenceApi';
import { DEFAULT_LABEL, DEFAULT_NAME } from './constants';

export interface CountrySelectMarkupProps {
  className?: CustomClassValue;
  label?: string;
  labelHint?: string;
  name?: string;
  disabled?: boolean;
  required?: boolean;
  defaultValue?: string;
  error?: string;
  options: Country[];
  // register: (name: string, options?: RegisterOptions) => void;
  // register: (rules?: RegisterOptions) => (ref: (FieldElement & Ref) | null) => void;
  register: UseFormRegister<any>;
}

function CountrySelectMarkup({
  className = undefined,
  label = DEFAULT_LABEL,
  labelHint = undefined,
  name = DEFAULT_NAME,
  disabled = false,
  required = true,
  defaultValue = undefined,
  error = undefined,
  options,
  register,
}: CountrySelectMarkupProps): React.JSX.Element {
  return (
    <div className={classNames(name, className)}>
      <DropdownControl
        id={name}
        label={label}
        labelHint={labelHint}
        disabled={disabled || isEmpty(options)}
        errorId={error ? `${name}__error` : undefined}
        errorMessage={error}
        defaultValue={defaultValue}
        required={required}
        {...register(name, { required })}
      >
        <option value="">- Select -</option>
        {options.map((country) => (
          <option value={country.code} key={country.code}>
            {country.description}
          </option>
        ))}
      </DropdownControl>
    </div>
  );
}

export default CountrySelectMarkup;
