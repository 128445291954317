import React from 'react';
import classNames from 'classnames';
import { Alert } from '@ver-uds/uswds-react';
import { CustomClassValue } from '../../../../utils/types';
import { COLUMN_9_CLASS_NAME } from '../../../../constants';

export interface GenerateQuizErrorAlertProps {
  className?: CustomClassValue;
}

const baseClassName = 'generate-quiz-error-alert';
const CLASS_NAMES = {
  base: baseClassName,
  text: classNames(`${baseClassName}__text`, COLUMN_9_CLASS_NAME),
};

function GenerateQuizErrorAlert({ className = undefined }: GenerateQuizErrorAlertProps): React.JSX.Element {
  const bodyContent =
    'The third party identity assurance provider was not able to generate enough information about you to create a quiz to confirm your identity.' +
    ' There may be several reasons why the identity assurance provider could not generate a quiz for you. Click to expand each section below to learn more.' +
    ' If you think your personal information has been entered incorrectly, select “Edit Personal Information.”';

  const generateQuizErrorAlertStyling = {
    marginTop: '16px',
  };

  return (
    <div
      className={classNames(CLASS_NAMES.base, className)}
      role="alert"
      aria-atomic="true"
      style={generateQuizErrorAlertStyling}
    >
      <Alert status="error">
        <Alert.Heading>Unable to Generate Quiz - What Happens Next</Alert.Heading>
        <Alert.Text className={CLASS_NAMES.text}>{bodyContent}</Alert.Text>
      </Alert>
    </div>
  );
}

export default GenerateQuizErrorAlert;
