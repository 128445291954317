import React from 'react';
import { Accordion } from '@ver-uds/uswds-react';
import HelpSectionContent from '../HelpSectionContent';

const baseClassName = 'confirm-credit-information-help-section';
const CLASS_NAMES = {
  heading: `${baseClassName}__heading`,
  content: `${baseClassName}__content`,
};

function ConfirmCreditInformationHelpSection(): React.JSX.Element {
  return (
    <>
      <Accordion.Heading className={CLASS_NAMES.heading} controls={baseClassName}>
        Confirm your credit information
      </Accordion.Heading>
      <HelpSectionContent id={baseClassName} className={CLASS_NAMES.content}>
        <p>
          If you would like to confirm the accuracy of the information found in your credit reports you may do so by
          visiting{' '}
          <a href="https://www.annualcreditreport.com" target="_blank" rel="noopener noreferrer">
            www.annualcreditreport.com
          </a>{' '}
          and requesting free copies of your credit reports. If you find a discrepancy in any of your credit reports you
          should follow up with the individual credit reporting agency to correct those records.
        </p>
        <p>
          If you would like information on your rights relating to credit reporting, or more detailed information on the
          steps you can take to correct errors found in your credit reports, please visit{' '}
          <a href="https://www.ftc.gov/credit" target="_blank" rel="noopener noreferrer">
            www.ftc.gov/credit
          </a>
        </p>
        <p>
          Because the identity assurance step was not completed, myE-Verify did not check your personal information
          against any U.S. government records.
        </p>
      </HelpSectionContent>
    </>
  );
}

export default ConfirmCreditInformationHelpSection;
