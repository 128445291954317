import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import { PageLoader } from '@ver-uds/react';
import type { CustomClassValue } from '../../utils/types';

const Wrapper = styled.div`
  .ver-page-loader-overlay {
    background-color: white;
  }
`;

export interface LoadingSpinnerProps {
  className?: CustomClassValue;
}

const baseClassName = 'loading-spinner';
export const CLASS_NAMES = {
  base: baseClassName,
};

function LoadingSpinner({ className = undefined }: LoadingSpinnerProps): React.JSX.Element {
  return (
    <Wrapper className={classNames(CLASS_NAMES.base, className)}>
      <PageLoader label="Loading" />
    </Wrapper>
  );
}

export default LoadingSpinner;
