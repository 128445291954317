import React from 'react';
import { Button, ButtonGroup, Modal } from '@ver-uds/react';
import { Alert } from '@ver-uds/uswds-react';
import classNames from 'classnames';

export interface DocumentUploadModalProps {
  handleClose: () => void;
  isOpen: boolean;
  onConfirm: () => void;
}

export const MODAL_ID = 'documentUpload-modal';

const baseClassName = MODAL_ID;
export const CLASS_NAMES = {
  base: baseClassName,
  contentSection: `${baseClassName}__content-section`,
  textSection: `${baseClassName}__text-section`,
  errorAlert: classNames(`${baseClassName}__error-alert`, 'margin-top-2'),
  errorAlertText: `${baseClassName}__error-alert-text`,
};

function DocumentUploadModal({ handleClose, isOpen, onConfirm }: DocumentUploadModalProps): React.JSX.Element {
  return (
    <Modal id={MODAL_ID} header="Document Upload" open={isOpen} close={handleClose}>
      <Modal.Content className={CLASS_NAMES.contentSection}>
        <Alert className={classNames(CLASS_NAMES.errorAlert)} status="warning" slim>
          <Alert.Text className={classNames(CLASS_NAMES.errorAlertText)}>
            You will be uploading sensitive documents to a government site. To continue click “Start Upload,” otherwise
            click “Cancel.”
          </Alert.Text>
        </Alert>
      </Modal.Content>
      <Modal.Footer>
        <ButtonGroup>
          <Button onClick={handleClose} className={CLASS_NAMES.base} variant="secondary">
            Cancel
          </Button>
          <Button onClick={onConfirm} className={CLASS_NAMES.base} variant="primary">
            Start Upload
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
}

export default DocumentUploadModal;
