import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import createError, { HttpError } from 'http-errors';
import { isCaseNumberValid, useCaseTrackerQuery } from '../../services/CaseApi';
import paths from '../../routing/paths';
import { injectURLParams } from '../../utils/routing';
import CaseTrackerMarkup from './CaseTrackerMarkup';

export const VALIDATION_ERROR_MESSAGE =
  'Invalid E-Verify Case Number. Case Numbers are 13 numbers followed by 2 letters.';
export const UNEXPECTED_SERVER_ERROR_MESSAGE = 'An unexpected error occurred. Please try again.';
export const NOT_FOUND_ERROR_MESSAGE = 'Case not found.';

function CaseTracker(): React.JSX.Element {
  const [caseNumber, setCaseNumber] = useState<string | null>(null);
  const [validationError, setValidationError] = useState<Error | null>(null);
  const { error, isSuccess } = useCaseTrackerQuery(caseNumber as string);

  const handleSubmit = (submittedCaseNumber: string): void => {
    setCaseNumber(submittedCaseNumber);

    const isValid = isCaseNumberValid(submittedCaseNumber);
    setValidationError(isValid ? null : new Error(VALIDATION_ERROR_MESSAGE));
  };

  const getError = (): Error | undefined => {
    if (validationError) {
      return validationError;
    }

    if (!error) {
      return undefined;
    }

    if (error instanceof HttpError && `${error.statusCode}`.startsWith('5')) {
      // error on server (5xx error)
      return createError(error.statusCode, UNEXPECTED_SERVER_ERROR_MESSAGE);
    }

    // default error message (assumes 404 error)
    return createError(404, NOT_FOUND_ERROR_MESSAGE);
  };

  if (isSuccess) {
    return <Navigate to={injectURLParams(paths.caseDetail, { caseNumber: caseNumber as string })} />;
  }

  return <CaseTrackerMarkup error={getError()} onSubmit={handleSubmit} />;
}

export default CaseTracker;
