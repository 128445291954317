import React, { useContext } from 'react';
import { AuthenticationContext } from '../../../../context/Authentication/AuthenticationContext';
import { UserInfoResponseData } from '../../../../services/UserApi';
import AttemptsExceededMarkup from './AttemptsExceededMarkup';

export interface AttemptsExceededProps {
  userInfo?: UserInfoResponseData;
}

function AttemptsExceeded({ userInfo = undefined }: AttemptsExceededProps): React.JSX.Element {
  const authentication = useContext(AuthenticationContext);
  return <AttemptsExceededMarkup onSubmit={authentication.logout} userInfo={userInfo} />;
}

export default AttemptsExceeded;
