import React from 'react';
import { Accordion } from '@ver-uds/uswds-react';
import HelpSectionContent from '../HelpSectionContent';

const baseClassName = 'address-help-section';
const CLASS_NAMES = {
  heading: `${baseClassName}__heading`,
  content: `${baseClassName}__content`,
};

function QuizGenerationHelpSection(): React.JSX.Element {
  return (
    <>
      <Accordion.Heading className={CLASS_NAMES.heading} controls={baseClassName}>
        Why a quiz could not be generated for you
      </Accordion.Heading>
      <HelpSectionContent id={baseClassName} className={CLASS_NAMES.content}>
        <ul>
          <li>
            You may have moved recently and your credit files and public records do not yet reflect your new address. If
            so, you can try enrolling a myE-Verify again using your former residence in the address field.
          </li>
          <li>
            You may have entered information incorrectly, preventing the independent service from locating any records
            about you.
          </li>
          <li>
            You may have attempted to take a quiz too many times recently. In this case, if you need to access
            myE-Verify you should wait a few days and try again.
          </li>
          <li>
            You may have entered the country or the workforce recently and may not have applied for any type of credit.
            You may need more of a financial footprint to generate enough questions for the identity authentication
            process.
          </li>
          <li>
            You may have certain fraud alerts reported to your state or a credit bureau. These fraud alerts are usually
            created by an individual who was the victim of identity theft or is at a greater risk of becoming a victim.
          </li>
          <li>You may have put a security freeze on your credit report.</li>
        </ul>
      </HelpSectionContent>
    </>
  );
}

export default QuizGenerationHelpSection;
