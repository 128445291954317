import React from 'react';
import classNames from 'classnames';
import { CustomClassValue } from '../../../../../utils/types';
import { ReadableCaseStatus } from '../../../../../services/CaseApi';
import { mapReadableCaseStatusToCaseDetailBodyText } from './util';

export interface GenericCaseDetailContentProps {
  className?: CustomClassValue;
  caseStatus: ReadableCaseStatus;
}

const baseClassName = 'case-detail-content--generic';
const CLASS_NAMES = {
  base: baseClassName,
};

function GenericCaseDetailContent({
  className = undefined,
  caseStatus,
}: GenericCaseDetailContentProps): React.JSX.Element {
  return (
    <div className={(classNames(CLASS_NAMES.base, className), 'grid-col-9')}>
      <p>{mapReadableCaseStatusToCaseDetailBodyText(caseStatus)}</p>
    </div>
  );
}

export default GenericCaseDetailContent;
