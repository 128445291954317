import React, { useState } from 'react';
import { Alert } from '@ver-uds/uswds-react';
import { Button, Search } from '@ver-uds/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames';
import styled from 'styled-components';
import CaseTrackerHelpModal from './CaseTrackerHelpModal';
import type { CustomClassValue } from '../../utils/types';

const SearchWrapper = styled.div`
  > form.usa-search > label.usa-label,
  > form.usa-search > input.usa-input {
    max-width: none;
  }
`;

const HelpButtonWrapper = styled.div`
  display: inline-block;

  > .ver-button--small {
    padding: 0;
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > .ver-button--small svg {
    max-width: 0.75rem;
    max-height: 0.75rem;
  }
`;

export interface CaseTrackerProps {
  className?: CustomClassValue;
  error?: Error;
  onSubmit: (value: string) => void;
}

const baseClassName = 'case-tracker';
export const CLASS_NAMES = {
  base: classNames(baseClassName, 'usa-card__container', 'radius-md', 'margin-0'),
  content: classNames(`${baseClassName}__content`, 'usa-card__body'),
  errorAlert: classNames(`${baseClassName}__error-alert`, 'margin-top-2'),
  errorAlertText: `${baseClassName}__error-alert-text`,
  header: classNames(`${baseClassName}__header`, 'usa-card__header'),
  headerContent: classNames(`${baseClassName}__header-content`, 'display-flex', 'flex-align-center'),
  helpButton: classNames(`${baseClassName}__help-button`, 'radius-pill', 'margin-x-1px'),
  search: `${baseClassName}__search`,
};

function CaseTracker({ className = undefined, error = undefined, onSubmit }: CaseTrackerProps): React.JSX.Element {
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  const handleHelpButtonClick = (): void => {
    setIsHelpModalOpen(true);
  };

  const handleHelpModalClose = (): void => {
    setIsHelpModalOpen(false);
  };

  let errorAlert;
  if (error) {
    errorAlert = (
      <Alert className={classNames(CLASS_NAMES.errorAlert)} status="warning" slim>
        <Alert.Text className={classNames(CLASS_NAMES.errorAlertText)}>{error.message}</Alert.Text>
      </Alert>
    );
  }

  return (
    <>
      <div className={classNames(CLASS_NAMES.base, className)} data-testid={CLASS_NAMES.base}>
        <header className={classNames(CLASS_NAMES.header)} data-testid={CLASS_NAMES.header}>
          <h3 className={CLASS_NAMES.headerContent}>
            <span>Track E-Verify Case Number</span>
            <HelpButtonWrapper>
              <Button className={CLASS_NAMES.helpButton} size="small" title="help" onClick={handleHelpButtonClick}>
                <FontAwesomeIcon icon={faQuestion as IconProp} />
              </Button>
            </HelpButtonWrapper>
          </h3>
        </header>
        <div className={CLASS_NAMES.content}>
          <SearchWrapper>
            <Search id={`${baseClassName}-search`} className={classNames(CLASS_NAMES.search)} onSubmit={onSubmit} />
          </SearchWrapper>
          <div role="alert" aria-atomic="true">
            {errorAlert}
          </div>
        </div>
      </div>
      <CaseTrackerHelpModal isOpen={isHelpModalOpen} handleClose={handleHelpModalClose} />
    </>
  );
}

export default CaseTracker;
