// TODO: figure out why ESLint thinks this is being defined multiple times
/* eslint-disable-next-line no-shadow */
export enum RawCaseStatus {
  CASE_INCOMPLETE = 'case incomplete',
  CASE_INCOMPLETE_ALTERNATE = 'case_incomplete',
  REVIEW_UPDATE_EMPLOYEE_DATA = 'review and update employee data',
  PHOTO_MATCHING_REQUIRED = 'photo matching required',
  DUPLICATE_CASE = 'duplicate case',
  CLOSE_AND_RESUBMIT = 'close and resubmit',
  CLOSE_AND_RESUBMIT_ALTERNATE = 'close_and_resubmit',
  CLOSED = 'closed',
  EMPLOYMENT_AUTHORIZED = 'employment authorized',
  EMPLOYMENT_AUTHORIZED_ALTERNATE = 'employment_authorized',
  CHECK_BACK_LATER = 'check back later',
  DHS_VERIFICATION_IN_PROCESS = 'dhs verification in process',
  VERIFICATION_IN_PROCESS = 'verification in process',
  FINAL_NONCONFIRMATION = 'final nonconfirmation',
  FINAL_NONCONFIRMATION_ALTERNATE = 'final_nonconfirmation',
  DHS_FINAL_NONCONFIRMATION = 'dhs final nonconfirmation',
  NO_SHOW = 'no_show',
  DHS_NO_SHOW = 'dhs no show',
  NO_ACTION_FNC = 'no_action_fnc',
  NOT_AUTHORIZED_EMPLOYMENT = 'not_authorized_employment',
  SSA_FINAL_NONCONFIRMATION = 'ssa final nonconfirmation',
  EMPLOYEE_REFERRED_DHS = 'employee referred (dhs)',
  EMPLOYEE_REFERRED_DHS_ALTERNATE_ONE = 'employee referred to dhs',
  EMPLOYEE_REFERRED_DHS_ALTERNATE_TWO = 'employee_referred_dhs',
  EMPLOYEE_REFERRED_SSA = 'employee referred (ssa)',
  EMPLOYEE_REFERRED_SSA_ALTERNATE_ONE = 'employee referred to ssa',
  EMPLOYEE_REFERRED_SSA_ALTERNATE_TWO = 'employee_referred_ssa',
  EMPLOYEE_REFERRED_DHS_AND_SSA = 'employee referred (dhs and ssa)',
  EMPLOYEE_REFERRED_DHS_AND_SSA_ALTERNATE = 'employee_referred_dhs_and_ssa',
  EMPLOYEE_REFERRED_DHS_OR_SSA = 'employee referred (dhs or ssa)',
  EMPLOYEE_REFERRED_DHS_OR_SSA_ALTERNATE = 'employee_referred_dhs_or_ssa',
  TENTATIVE_NONCONFIRMATION_DHS = 'tentative nonconfirmation (dhs)',
  TENTATIVE_NONCONFIRMATION_DHS_ALTERNATE_ONE = 'dhs tentative nonconfirmation',
  TENTATIVE_NONCONFIRMATION_DHS_ALTERNATE_TWO = 'dhs tentative nonconfirmation (tnc)',
  TENTATIVE_NONCONFIRMATION_DHS_ALTERNATE_THREE = 'tentative_nonconfirmation_dhs',
  TENTATIVE_NONCONFIRMATION_SSA = 'tentative nonconfirmation (ssa)',
  TENTATIVE_NONCONFIRMATION_SSA_ALTERNATE_ONE = 'ssa tentative nonconfirmation (tnc)',
  TENTATIVE_NONCONFIRMATION_SSA_ALTERNATE_TWO = 'tentative_nonconfirmation_ssa',
  TENTATIVE_NONCONFIRMATION_DHS_AND_SSA = 'tentative nonconfirmation (dhs and ssa)',
  TENTATIVE_NONCONFIRMATION_DHS_AND_SSA_ALTERNATE = 'tentative_nonconfirmation_dhs_and_ssa',
  TENTATIVE_NONCONFIRMATION_DHS_OR_SSA = 'tentative nonconfirmation (dhs or ssa)',
  TENTATIVE_NONCONFIRMATION_DHS_OR_SSA_ALTERNATE = 'tentative_nonconfirmation_dhs_or_ssa',
  CASE_IN_CONTIUNANCE = 'continuance',
  CASE_IN_CONTINUANCE_DHS = 'case in continuance (dhs)',
  CASE_IN_CONTINUANCE_DHS_ALTERNATE = 'dhs case in continuance',
  CASE_IN_CONTINUANCE_SSA = 'case in continuance (ssa)',
  CASE_IN_CONTINUANCE_SSA_ALTERNATE = 'ssa case in continuance',
  CASE_IN_CONTINUANCE_DHS_AND_SSA = 'case in continuance (dhs and ssa)',
  CASE_IN_CONTINUANCE_DHS_OR_SSA = 'case in continuance (dhs or ssa)',
  CASE_IN_CONTINUANCE_DHS_OR_SSA_ALTERNATE = 'case_in_continuance_dhs_or_ssa',
  EMPLOYEE_REFERRED_CONTINUANCE = 'employee_referred_continuance',
  EMPLOYEE_REFERRED_DHS_AND_CASE_IN_CONTINUANCE_SSA = 'employee referred (dhs) and case in continuance (ssa)',
  CASE_IN_CONTINUANCE_DHS_AND_EMPLOYEE_REFERRED_SSA = 'case in continuance (dhs) and employee referred (ssa)',
  UNABLE_TO_PROCESS = 'unable to process',
  UNKNOWN = 'unknown',
}

// TODO: figure out why ESLint thinks this is being defined multiple times
/* eslint-disable-next-line no-shadow */
export enum ReadableCaseStatus {
  CASE_INCOMPLETE = 'CASE_INCOMPLETE',
  CLOSE_AND_RESUBMIT = 'CLOSE_AND_RESUBMIT',
  CLOSED = 'CLOSED',
  EMPLOYMENT_AUTHORIZED = 'EMPLOYMENT_AUTHORIZED',
  CHECK_BACK_LATER = 'CHECK_BACK_LATER',
  FINAL_NONCONFIRMATION = 'FINAL_NONCONFIRMATION',
  EMPLOYEE_REFERRED_DHS = 'EMPLOYEE_REFERRED_DHS',
  EMPLOYEE_REFERRED_SSA = 'EMPLOYEE_REFERRED_SSA',
  EMPLOYEE_REFERRED_DHS_AND_SSA = 'EMPLOYEE_REFERRED_DHS_AND_SSA',
  EMPLOYEE_REFERRED_DHS_OR_SSA = 'EMPLOYEE_REFERRED_DHS_OR_SSA',
  EMPLOYEE_REFERRED_CONTINUANCE = 'EMPLOYEE_REFERRED_CONTINUANCE',
  TENTATIVE_NONCONFIRMATION_DHS = 'TENTATIVE_NONCONFIRMATION_DHS',
  TENTATIVE_NONCONFIRMATION_SSA = 'TENTATIVE_NONCONFIRMATION_SSA',
  TENTATIVE_NONCONFIRMATION_DHS_AND_SSA = 'TENTATIVE_NONCONFIRMATION_DHS_AND_SSA',
  TENTATIVE_NONCONFIRMATION_DHS_OR_SSA = 'TENTATIVE_NONCONFIRMATION_DHS_OR_SSA',
  CONTINUANCE = 'CONTINUANCE',
  UNKNOWN = 'UNKNOWN',
}

// TODO: figure out why ESLint thinks this is being defined multiple times
/* eslint-disable-next-line no-shadow */
export enum TncType {
  US_PASSPORT = 'US_PASSPORT',
  DRIVERS_LICENSE = 'DRIVERS_LICENSE',
  JBL = 'JBL',
  NATZ = 'NATZ',
  OTHER = 'OTHER',
}
