import React from 'react';
import { Accordion } from '@ver-uds/uswds-react';
import usPassportCover from '../../../assets/img/documents/help/usPassport/us-passport-cover.jpg';
import usPassportInside from '../../../assets/img/documents/help/usPassport/us-passport-inside.jpg';
import usPassportCardFront from '../../../assets/img/documents/help/usPassportCard/us-passport-card-front.jpg';
import usPassportCardBack from '../../../assets/img/documents/help/usPassportCard/us-passport-card-back.jpg';
import ImageWrapper from '../../ImageWrapper';
import HelpSectionContent from '../HelpSectionContent';

const baseClassName = 'us-passport-help-section';
const CLASS_NAMES = {
  heading: `${baseClassName}__heading`,
  content: `${baseClassName}__content`,
};

function UsPassportHelpSection(): React.JSX.Element {
  return (
    <>
      <Accordion.Heading className={CLASS_NAMES.heading} controls={baseClassName}>
        Help with U.S. Passport and U.S. Passport Card
      </Accordion.Heading>
      <HelpSectionContent id={baseClassName} className={CLASS_NAMES.content}>
        <p>
          <span className="text-bold">U.S. Passport</span>
          <br />
          The U.S. Department of State issues U.S. Passports to U.S. citizens and U.S. nationals. A small number of
          versions still in circulation may differ from the main versions shown here. The U.S. Passport will always
          contain nine (9) digits.
        </p>
        <p>Current U.S. passport cover and interior</p>
        <ImageWrapper
          images={[
            { src: usPassportCover, alt: 'Example of the U.S. passport cover' },
            {
              src: usPassportInside,
              alt: 'Example of the U.S. passport interior',
            },
          ]}
        />
        <p>
          <span className="text-bold"> U.S. Passport Card </span>
          <br />
          The U.S. Department of State also issues a U.S. passport card to U.S. citizens and U.S. nationals. The U.S.
          Passport Card is a wallet-size card that can only be used for land and sea travel between the United States
          and Canada, Mexico, the Caribbean, and Bermuda. The U.S. Passport Card number begins with the letter "C",
          followed by eight (8) digits.
        </p>
        <ImageWrapper
          images={[
            { src: usPassportCardFront, alt: 'Example of the front of a U.S. passport card' },
            {
              src: usPassportCardBack,
              alt: 'Example of the back of a U.S. passport card',
            },
          ]}
        />
      </HelpSectionContent>
    </>
  );
}

export default UsPassportHelpSection;
