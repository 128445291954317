import React from 'react';
import classNames from 'classnames';
import { CustomClassValue } from '../../../utils/types';

export interface TelephoneLinkProps {
  className?: CustomClassValue;
  phoneNumber: string;
}

const baseClassName = 'telephone-link';
const CLASS_NAMES = {
  base: baseClassName,
};

function TelephoneLink({ className = undefined, phoneNumber }: TelephoneLinkProps): React.JSX.Element {
  return (
    <a className={classNames(CLASS_NAMES.base, className)} href={`tel:${phoneNumber}`}>
      {phoneNumber}
    </a>
  );
}

export default TelephoneLink;
