import React from 'react';
import { Navigate } from 'react-router-dom';
import { HttpError } from 'http-errors';
import get from 'lodash/get';
import { CustomClassValue } from '../../../../../utils/types';
import { SelfCheckCaseDetailVariant } from '../constants';
import paths from '../../../../../routing/paths';
import { ReadableCaseStatus, useCaseTncTypeQuery } from '../../../../../services/CaseApi';
import CaseDetailActionSectionMarkup from './CaseDetailActionSectionMarkup';

export interface CaseDetailActionSectionProps {
  className?: CustomClassValue;
  caseNumber: string;
  caseStatus: ReadableCaseStatus;
  selfCheckCaseDetailVariant?: SelfCheckCaseDetailVariant;
}

const is500Error = (error: HttpError): boolean => `${get(error, 'status')}`.startsWith('5');

function CaseDetailActionSection({
  className = undefined,
  caseNumber,
  caseStatus,
  selfCheckCaseDetailVariant = undefined,
}: CaseDetailActionSectionProps): React.JSX.Element {
  const { data: caseTncTypeData, error } = useCaseTncTypeQuery(caseNumber);

  if (is500Error(error as HttpError)) {
    return <Navigate to={paths.error} />;
  }

  return (
    <CaseDetailActionSectionMarkup
      className={className}
      caseNumber={caseNumber}
      caseStatus={caseStatus}
      caseTncTypeData={caseTncTypeData}
      selfCheckCaseDetailVariant={selfCheckCaseDetailVariant}
    />
  );
}

export default CaseDetailActionSection;
