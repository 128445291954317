import classNames from 'classnames';
import entries from 'lodash/entries';
import React from 'react';
import { CustomClassValue } from '../../../utils/types';
import { COLUMN_9_CLASS_NAME } from '../../../constants';
import { CreateUserQuizRequestFieldName, CreateUserQuizRequest } from '../../../services/UserApi';
import { formatDate } from '../../../utils/dates';
import { initialPhoneNumberFormat } from '../../../utils/formatting';
import ActionFooter from '../../ActionFooter/ActionFooter';
import ReadOnlyFields from '../../Form/FormFields/ReadOnlyFields/ReadOnlyFields';
import { personalInformationFormLabels, STATES } from '../PersonalInformation/constants';

export interface ReviewInformationMarkupProps {
  onSubmit: () => void;
  onBack: () => void;
  data: CreateUserQuizRequest;
}

const SORT_ORDER = [
  CreateUserQuizRequestFieldName.FIRST_NAME,
  CreateUserQuizRequestFieldName.MIDDLE_INITIAL,
  CreateUserQuizRequestFieldName.LAST_NAME,
  CreateUserQuizRequestFieldName.DOB,
  CreateUserQuizRequestFieldName.SSN,
  CreateUserQuizRequestFieldName.ADDRESS_LINE1,
  CreateUserQuizRequestFieldName.PHONE_NUMBER,
];

const baseClassName = 'review-information-markup';
export const CLASS_NAMES = {
  base: baseClassName,
  header: classNames(`${baseClassName}__header`, 'margin-0'),
  bodyText: classNames(`${baseClassName}__body-text`, COLUMN_9_CLASS_NAME),
  fields: classNames(baseClassName, 'grid-row', 'grid-gap'),
  field: classNames(`${baseClassName}__field`, 'grid-col-12'),
};

const getFieldClassName = (key: CreateUserQuizRequestFieldName): CustomClassValue =>
  classNames(CLASS_NAMES.field, {
    'maxw-card-lg': key === 'firstName',
    'tablet:grid-col-6': key === 'firstName' || key === 'middleInitial',
  });

function ReviewInformationMarkup({ onBack, onSubmit, data }: ReviewInformationMarkupProps): React.JSX.Element {
  return (
    <div className={baseClassName}>
      <h1 className={CLASS_NAMES.header}>Confirm Personal Information</h1>
      <p className={CLASS_NAMES.bodyText}>
        Please review the information below before continuing. If there are any errors, please go back to the previous
        step to correct the information.
      </p>
      <h2>Personal Information</h2>
      <ReadOnlyFields
        className={CLASS_NAMES.fields}
        data={entries(data)
          .filter(([key]) => SORT_ORDER.includes(key as CreateUserQuizRequestFieldName))
          .map(([key, value]) => {
            const field = {
              className: getFieldClassName(key as CreateUserQuizRequestFieldName),
              name: personalInformationFormLabels[key as CreateUserQuizRequestFieldName],
              key,
              value,
            };

            if (key === CreateUserQuizRequestFieldName.DOB) {
              field.value = formatDate(value);
            }
            if (key === CreateUserQuizRequestFieldName.PHONE_NUMBER) {
              field.value = initialPhoneNumberFormat(value);
            }
            if (key === CreateUserQuizRequestFieldName.ADDRESS_LINE1) {
              field.value = (
                <>
                  {data.addressLine1}
                  <br />
                  {data.addressLine2 && (
                    <>
                      {data.addressLine2}
                      <br />
                    </>
                  )}
                  {data.city}, {STATES.find((state) => state.code === data.stateCode)?.description} {data.zipCode}
                </>
              );
            }
            return field;
          })}
        sortOrder={SORT_ORDER}
      />
      <ActionFooter submitButtonText="Next" cancelButtonText="Back" onCancel={onBack} onSubmit={onSubmit} />
    </div>
  );
}

export default ReviewInformationMarkup;
