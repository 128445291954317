import React from 'react';
import PageContentLayout from '../layouts/PageContentLayout/PageContentLayout';
import SelfLock from '../components/SelfLock/SelfLock';

function SelfLockPage(): React.JSX.Element {
  return (
    <PageContentLayout title="Self Lock">
      <SelfLock />
    </PageContentLayout>
  );
}

export default SelfLockPage;
