/* eslint-disable import/prefer-default-export */
const defaultSortKey = 'key';
type SortKey = typeof defaultSortKey | string;
type DataList = (
  | Record<string, any> /* eslint-disable-line @typescript-eslint/no-explicit-any */
  | Record<SortKey, string>
)[];
export const sortByKeyToMatchList = (
  unsortedData: DataList,
  sortedKeys: string[],
  sortByKey = defaultSortKey,
): DataList =>
  unsortedData.sort(({ [sortByKey]: a }, { [sortByKey]: b }) => sortedKeys.indexOf(a) - sortedKeys.indexOf(b));
