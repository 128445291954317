import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Button } from '@ver-uds/react';
import { CustomClassValue } from '../../../../../../../../utils/types';
import { useConfiguration } from '../../../../../../../../services/BaseApi';
import paths from '../../../../../../../../services/paths.json';
import { isConfigurationValid } from '../../../../../../../../services/ServicesUtils/configuration';
import { normalizeURL } from '../../../../../../../../utils/routing';

export interface SelfCheckReferredDownloadRDCButtonProps {
  className?: CustomClassValue;
}

const baseClassName = 'self-check-referred-download-rdc-button';
const CLASS_NAMES = {
  base: baseClassName,
};

function SelfCheckReferredDownloadRDCButton({
  className = undefined,
}: SelfCheckReferredDownloadRDCButtonProps): React.JSX.Element {
  const configuration = useConfiguration();

  if (!isConfigurationValid(configuration)) {
    return <div />;
  }

  return (
    <Button
      className={classNames(CLASS_NAMES.base, className)}
      as="a"
      href={normalizeURL(`${configuration.basePath}${paths.documents.referralDateConfirmation}`)}
      iconLeft={<FontAwesomeIcon icon={faDownload as IconProp} />}
      variant="secondary"
    >
      Referral Date Confirmation
    </Button>
  );
}

export default SelfCheckReferredDownloadRDCButton;
