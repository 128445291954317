import React from 'react';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import reduce from 'lodash/reduce';
import { CustomClassValue, PartialRecord } from '../../../../../utils/types';
import { Country } from '../../../../../services/ReferenceApi';
import { formatDate } from '../../../../../utils/dates';
import ReadOnlyFields, { FieldProps } from '../../../../Form/FormFields/ReadOnlyFields/ReadOnlyFields';
import {
  USCISANumberFieldName,
  countryOfIssuanceFieldName,
  documentExpirationDateFieldName,
  documentNumberProvidedFieldName,
  fieldDefinitions,
  FieldName,
  foreignPassportNumberFieldName,
  i551NumberFieldName,
  i766NumberFieldName,
  i94NumberFieldName,
  SelfCheckFormDocumentDetailsFormData,
  SelfCheckFormDocumentSelectionFormData,
  usPassportNumberFieldName,
  visaNumberFieldName,
} from '../../constants';
import { FIELD_ORDER } from '../constants';

export const FIELD_LABEL_MAP: PartialRecord<FieldName, string> = {
  [USCISANumberFieldName]: fieldDefinitions[USCISANumberFieldName].label,
  [i551NumberFieldName]: fieldDefinitions[i551NumberFieldName].label,
  [i766NumberFieldName]: fieldDefinitions[i766NumberFieldName].label,
  [i94NumberFieldName]: fieldDefinitions[i94NumberFieldName].label,
  [usPassportNumberFieldName]: fieldDefinitions[usPassportNumberFieldName].label,
  [foreignPassportNumberFieldName]: fieldDefinitions[foreignPassportNumberFieldName].label,
  [countryOfIssuanceFieldName]: fieldDefinitions[countryOfIssuanceFieldName].label,
  [documentExpirationDateFieldName]: fieldDefinitions[documentExpirationDateFieldName].label,
  [visaNumberFieldName]: fieldDefinitions[visaNumberFieldName].label,
  citizenshipStatus: 'Type',
};

const HIDE_UNDEFINED_VALUE_FIELDS = [countryOfIssuanceFieldName, visaNumberFieldName, documentNumberProvidedFieldName];

export type Data =
  | Pick<
      SelfCheckFormDocumentSelectionFormData,
      'uscisANumber' | 'i94Number' | 'foreignPassportNumber' | 'countryOfIssuance'
    >
  | SelfCheckFormDocumentDetailsFormData;

export interface DocumentDetailsSectionMarkupProps {
  className?: CustomClassValue;
  countries?: Country[];
  data?: Data;
}

const baseClassName = 'self-check-review-document-details-section';
const CLASS_NAMES = {
  base: baseClassName,
  readOnlyFields: classNames(`${baseClassName}__read-only-fields`, 'margin-top-2'),
};

const parseFieldVal = (key: keyof Data, value?: string, countries: Country[] = []): string | undefined => {
  if (key === documentExpirationDateFieldName && value) {
    return formatDate(value);
  }
  if (key === countryOfIssuanceFieldName) {
    const foundCountry = countries.find((country) => country.code === value);
    if (foundCountry) {
      return foundCountry.description;
    }
  }
  if (key === documentNumberProvidedFieldName) {
    return undefined;
  }
  return value;
};

function DocumentDetailsSectionMarkup({
  className = undefined,
  countries = undefined,
  data = undefined,
}: DocumentDetailsSectionMarkupProps): React.JSX.Element {
  const fields = reduce(
    data,
    (accumulator: FieldProps[], value, key) => {
      const parsedValue = parseFieldVal(key as keyof Data, value, countries);

      if (HIDE_UNDEFINED_VALUE_FIELDS.includes(key) && isUndefined(parsedValue)) {
        return accumulator;
      }

      accumulator.push({
        key,
        name: FIELD_LABEL_MAP[key as keyof Data],
        value: parsedValue,
      });

      return accumulator;
    },
    [],
  );

  return (
    <div className={classNames(CLASS_NAMES.base, className)}>
      {!isEmpty(fields) && (
        <>
          <h2>Document Details</h2>
          <ReadOnlyFields className={CLASS_NAMES.readOnlyFields} data={fields} sortOrder={FIELD_ORDER} />
        </>
      )}
    </div>
  );
}

export default DocumentDetailsSectionMarkup;
