import React from 'react';
import classNames from 'classnames';
import { CustomClassValue } from '../../../../../../../utils/types';
import { SelfCheckCaseDetailVariant } from '../../../constants';
import SelfCheckCaseDetailFNCActionSection from './variants/SelfCheckCaseDetailFNCActionSection';
import SelfCheckCaseDetailPendingRefActionSection from './variants/SelfCheckCaseDetailPendingRefActionSection';
import SelfCheckCaseDetailReturnHomeActionSection from './variants/SelfCheckCaseDetailReturnHomeActionSection';
import SelfCheckCaseDetailUnconfirmedActionSection from './variants/SelfCheckCaseDetailUnconfirmedActionSection';

export interface SelfCheckCaseDetailActionSectionProps {
  className?: CustomClassValue;
  caseNumber: string;
  selfCheckCaseDetailVariant: SelfCheckCaseDetailVariant;
}

const baseClassName = 'case-detail-action-section--self-check';
const CLASS_NAMES = {
  base: baseClassName,
};

function SelfCheckCaseDetailActionSection({
  className = undefined,
  caseNumber,
  selfCheckCaseDetailVariant,
}: SelfCheckCaseDetailActionSectionProps): React.JSX.Element {
  return (
    <div className={classNames(CLASS_NAMES.base, className)}>
      {(selfCheckCaseDetailVariant === SelfCheckCaseDetailVariant.REFERRED ||
        selfCheckCaseDetailVariant === SelfCheckCaseDetailVariant.EMPLOYMENT_AUTHORIZED) && (
        <SelfCheckCaseDetailReturnHomeActionSection />
      )}
      {selfCheckCaseDetailVariant === SelfCheckCaseDetailVariant.FNC && (
        <SelfCheckCaseDetailFNCActionSection caseNumber={caseNumber} />
      )}
      {selfCheckCaseDetailVariant === SelfCheckCaseDetailVariant.UNCONFIRMED_DATA && (
        <SelfCheckCaseDetailUnconfirmedActionSection caseNumber={caseNumber} />
      )}
      {selfCheckCaseDetailVariant === SelfCheckCaseDetailVariant.PENDING_REFERRAL && (
        <SelfCheckCaseDetailPendingRefActionSection caseNumber={caseNumber} />
      )}
    </div>
  );
}

export default SelfCheckCaseDetailActionSection;
