import React from 'react';
import classNames from 'classnames';
import { CustomClassValue } from '../../../../../../../utils/types';

export interface SelfCheckCaseDetailUnconfirmedContentProps {
  className?: CustomClassValue;
}

const baseClassName = 'case-detail-content-self-check--unconfirmed';
const CLASS_NAMES = {
  base: baseClassName,
};

function SelfCheckCaseDetailUnconfirmedContent({
  className = undefined,
}: SelfCheckCaseDetailUnconfirmedContentProps): React.JSX.Element {
  return (
    <div className={(classNames(CLASS_NAMES.base, className), 'grid-col-9')}>
      <p>
        We apologize for the inconvenience, but something went wrong when processing this Self Check case. Please start
        a new Self Check case in order to continue.
      </p>
    </div>
  );
}

export default SelfCheckCaseDetailUnconfirmedContent;
