import { useMemo } from 'react';
import get from 'lodash/get';
import { useEnvironment } from '../context/Environment/EnvironmentContext';
import { injectURLParams, normalizeURL } from '../utils/routing';
import Configuration from './Configuration';
import { isConfigurationValid } from './ServicesUtils/configuration';

export default abstract class BaseApi {
  protected configuration: Configuration;

  constructor(configuration: Configuration) {
    this.configuration = configuration;
  }

  isConfigurationValid(): boolean {
    return isConfigurationValid(this.configuration);
  }

  protected getEndpoint(path: string, caseNumber?: string): string {
    let pathWithParams;
    if (caseNumber) {
      pathWithParams = injectURLParams(path, { caseNumber });
    }
    const basePath = this.configuration.basePath ? this.configuration.basePath : '';
    return normalizeURL(`${basePath}/${pathWithParams || path}`);
  }
}

// eslint-disable-next-line import/prefer-default-export
export const useConfiguration = (): Configuration => {
  const { environmentVariables } = useEnvironment();
  return useMemo(
    () =>
      new Configuration({
        basePath: get(environmentVariables, 'MYEVERIFY_WEB_SERVICE_ROOT'),
      }),
    [environmentVariables],
  );
};
