import React from 'react';
import { Accordion } from '@ver-uds/uswds-react';
import ImageWrapper from '../../ImageWrapper';
import HelpSectionContent from '../HelpSectionContent';
import driversLicense from '../../../assets/img/documents/help/driversLicense/drivers-license.png';
import identificationCard from '../../../assets/img/documents/help/driversLicense/identification-card.png';

const baseClassName = 'drivers-license-help-section';
const CLASS_NAMES = {
  heading: `${baseClassName}__heading`,
  content: `${baseClassName}__content`,
};

function DriversLicenseHelpSection(): React.JSX.Element {
  return (
    <>
      <Accordion.Heading className={CLASS_NAMES.heading} controls={baseClassName}>
        Help with Driver’s License
      </Accordion.Heading>
      <HelpSectionContent id={baseClassName} className={CLASS_NAMES.content}>
        <p>
          <span className="text-bold">Driver's License</span>
          <br />
          Driver's license or identification card issued by a state or outlying territory of the U.S., provided it
          contains a photograph or information such as name, date of birth, gender, height, eye color and address.
        </p>
        <ImageWrapper images={[{ src: driversLicense, alt: "Example of a U.S. driver's license" }]} />
        <ImageWrapper
          minColumnSize={3}
          images={[
            {
              src: identificationCard,
              alt: 'Example of a state issued identification card',
            },
          ]}
        />
        <ul>
          <li>
            ID card issued by federal, state or local government agencies or entities, provided it contains a photograph
            or information such as name, date of birth, gender, height, eye color and address
          </li>
          <li>School ID card with a photograph</li>
          <li>Voter registration card</li>
          <li>U.S. military card or draft record</li>
          <li>Military dependent’s ID card</li>
          <li>
            <a href="https://www.dco.uscg.mil/national_maritime_center/">
              U.S. Coast Guard Merchant Mariner Document (MMD) card
            </a>
          </li>
          <li>Native American tribal document</li>
          <li>Driver’s license issued by a Canadian government authority</li>
        </ul>
      </HelpSectionContent>
    </>
  );
}

export default DriversLicenseHelpSection;
