import { ErrorMessages } from '../../../utils/forms';

// TODO: figure out why ESLint thinks this is being defined multiple times
/* eslint-disable-next-line no-shadow */
export enum DocumentUploadFormFieldName {
  DOCUMENT_IMAGE1 = 'firstDocument',
  DOCUMENT_IMAGE2 = 'secondDocument',
  PHONE_NUMBER = 'phoneNumber',
  EMAIL_ADDRESS = 'emailAddress',
}

export interface DocumentUploadFormFields extends Record<string, FileList | string> {
  [DocumentUploadFormFieldName.DOCUMENT_IMAGE1]: FileList;
  [DocumentUploadFormFieldName.DOCUMENT_IMAGE2]: FileList;
  [DocumentUploadFormFieldName.PHONE_NUMBER]: string;
  [DocumentUploadFormFieldName.EMAIL_ADDRESS]: string;
}

export const documentUploadFormErrorMessages: { [field in DocumentUploadFormFieldName]: ErrorMessages } = {
  [DocumentUploadFormFieldName.DOCUMENT_IMAGE1]: {
    required: 'This document image is required.',
    maxSize: 'The file size must be under 4MB.',
    minSize: 'The file size must be greater than 0 bytes.',
  },
  [DocumentUploadFormFieldName.DOCUMENT_IMAGE2]: {
    required: 'This document image is required.',
    maxSize: 'The file size must be under 4MB.',
    minSize: 'The file size must be greater than 0 bytes.',
  },
  [DocumentUploadFormFieldName.PHONE_NUMBER]: {
    required: 'A phone number is required.',
    pattern: 'Phone number is invalid or formatted incorrectly (###-###-####).',
  },
  [DocumentUploadFormFieldName.EMAIL_ADDRESS]: {
    required: 'An email address is required.',
    pattern: 'Email address is invalid.',
  },
};
