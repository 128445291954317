import classNames from 'classnames';
import fromPairs from 'lodash/fromPairs';
import React, { useRef } from 'react';
import { Controller, get, useForm } from 'react-hook-form';
import { DropdownControl, Form, TextInputControl } from '@ver-uds/uswds-react';
import { CreateUserQuizRequest, CreateUserQuizRequestFieldName } from '../../../services/UserApi';
import { isBefore18YearsAgo, isValidDate } from '../../../utils/dates';
import { formatSsn, formatPhoneNumber, initialPhoneNumberFormat } from '../../../utils/formatting';
import { doCombinedStringLengthsExceedMax, getFieldError } from '../../../utils/forms';
import ActionFooter from '../../ActionFooter/ActionFooter';
import FormattedDateInput from '../../Form/FormattedDateInput/FormattedDateInput';
import { personalInformationFormErrorMessages, personalInformationFormLabels, STATES } from './constants';

export interface PersonalInformationFormProps {
  onSubmit: (data: CreateUserQuizRequest) => void;
  onCancel: () => void;
  initialData?: CreateUserQuizRequest;
}

const baseClassName = 'personal-information-form';
export const CLASS_NAMES = {
  base: baseClassName,
  actionFooter: classNames(`${baseClassName}__action-footer`, 'margin-top-4'),
};

function PersonalInformationForm({
  onSubmit,
  onCancel,
  initialData = undefined,
}: PersonalInformationFormProps): React.JSX.Element {
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    trigger,
  } = useForm<CreateUserQuizRequest>({
    mode: 'onSubmit',
  });

  const handleFormSubmit = handleSubmit((data) => {
    window.scrollTo(0, 0);
    onSubmit({
      ...data,
      firstName: data.firstName.replace(/^\s+|\s+$|\s+(?=\s)/g, ''),
      lastName: data.lastName.replace(/^\s+|\s+$|\s+(?=\s)/g, ''),
      addressLine1: data.addressLine1.replace(/^\s+|\s+$|\s+(?=\s)/g, ''),
      addressLine2: data.addressLine2?.replace(/^\s+|\s+$|\s+(?=\s)/g, ''),
      city: data.city.replace(/^\s+|\s+$|\s+(?=\s)/g, ''),
      phoneNumber: data.phoneNumber.replace(/\D/g, ''),
    });
  });

  const errorMessages = fromPairs(
    Object.keys(personalInformationFormErrorMessages).map((key) => [
      key,
      getFieldError(
        errors[key as CreateUserQuizRequestFieldName],
        personalInformationFormErrorMessages[key as CreateUserQuizRequestFieldName],
      ),
    ]),
  );

  const dateInputRef = useRef<HTMLInputElement>(null);
  return (
    <>
      <Form className={classNames(baseClassName, CLASS_NAMES.base)} variant="large">
        <h2>Personal Information</h2>
        <TextInputControl
          className="tablet:grid-col-9"
          id={CreateUserQuizRequestFieldName.FIRST_NAME}
          label={personalInformationFormLabels[CreateUserQuizRequestFieldName.FIRST_NAME]}
          errorMessage={errorMessages[CreateUserQuizRequestFieldName.FIRST_NAME]}
          defaultValue={get(initialData, CreateUserQuizRequestFieldName.FIRST_NAME)}
          required
          {...register(CreateUserQuizRequestFieldName.FIRST_NAME, {
            required: true,
            maxLength: 32,
            pattern: /^[a-zA-Z-' ]*$/,
            validate: {
              isEmpty: (value): boolean => !!value.trim(),
            },
          })}
        />
        <TextInputControl
          className="tablet:grid-col-3"
          id={CreateUserQuizRequestFieldName.MIDDLE_INITIAL}
          label={personalInformationFormLabels[CreateUserQuizRequestFieldName.MIDDLE_INITIAL]}
          errorMessage={errorMessages[CreateUserQuizRequestFieldName.MIDDLE_INITIAL]}
          defaultValue={get(initialData, CreateUserQuizRequestFieldName.MIDDLE_INITIAL)}
          maxLength={1}
          {...register(CreateUserQuizRequestFieldName.MIDDLE_INITIAL, {
            maxLength: 1,
            pattern: /^[a-zA-Z]+$/,
          })}
          onKeyPress={(event): void => {
            if (event.key === ' ') {
              event.preventDefault();
            }
          }}
          variant="small"
        />
        <div className="grid-row margin-top-0 grid-gap">
          <div className="tablet:grid-col-9">
            <TextInputControl
              id={CreateUserQuizRequestFieldName.LAST_NAME}
              label={personalInformationFormLabels[CreateUserQuizRequestFieldName.LAST_NAME]}
              errorMessage={errorMessages[CreateUserQuizRequestFieldName.LAST_NAME]}
              defaultValue={get(initialData, CreateUserQuizRequestFieldName.LAST_NAME)}
              required
              {...register(CreateUserQuizRequestFieldName.LAST_NAME, {
                required: true,
                maxLength: 32,
                pattern: /^[a-zA-Z-' ]*$/,
                validate: {
                  isEmpty: (value): boolean => !!value.trim(),
                },
              })}
            />
          </div>
        </div>
        <Controller
          control={control}
          defaultValue={get(initialData, CreateUserQuizRequestFieldName.DOB)}
          name={CreateUserQuizRequestFieldName.DOB}
          rules={{
            required: true,
            validate: {
              validDate: isValidDate,
              over18: isBefore18YearsAgo,
            },
          }}
          render={({ field }): React.JSX.Element => (
            <FormattedDateInput
              className="margin-top-3"
              id={CreateUserQuizRequestFieldName.DOB}
              name={CreateUserQuizRequestFieldName.DOB}
              label={personalInformationFormLabels[CreateUserQuizRequestFieldName.DOB]}
              errorMessage={errorMessages[CreateUserQuizRequestFieldName.DOB]}
              required
              monthRef={dateInputRef}
              formattedDate={field.value}
              onUpdate={field.onChange}
            />
          )}
          // Need to test focus order after the updates to see if this issue still persists
          // Focus order is not respected. This is fixed in react-hook-form version 7
          // onFocus={(): void => {
          //   if (dateInputRef?.current) {
          //     dateInputRef.current.focus();
          //   }
          // }}
        />
        <Controller
          control={control}
          defaultValue={get(initialData, CreateUserQuizRequestFieldName.SSN)}
          name={CreateUserQuizRequestFieldName.SSN}
          rules={{
            required: true,
            pattern: /^(?!000|9\d{2})\d{3}-(?!0{2})\d{2}-(?!0{4})\d{4}$/,
          }}
          render={({ field }): React.JSX.Element => (
            <TextInputControl
              className="tablet:grid-col-6"
              id={CreateUserQuizRequestFieldName.SSN}
              name={CreateUserQuizRequestFieldName.SSN}
              label={personalInformationFormLabels[CreateUserQuizRequestFieldName.SSN]}
              errorMessage={errorMessages[CreateUserQuizRequestFieldName.SSN]}
              hint="###-##-####"
              required
              value={field.value}
              onChange={(event): void => field.onChange(formatSsn(event.target.value))}
            />
          )}
        />
        <h2>Address</h2>
        <TextInputControl
          id={CreateUserQuizRequestFieldName.ADDRESS_LINE1}
          label={personalInformationFormLabels[CreateUserQuizRequestFieldName.ADDRESS_LINE1]}
          errorMessage={errorMessages[CreateUserQuizRequestFieldName.ADDRESS_LINE1]}
          defaultValue={get(initialData, CreateUserQuizRequestFieldName.ADDRESS_LINE1)}
          required
          {...register(CreateUserQuizRequestFieldName.ADDRESS_LINE1, {
            required: true,
            pattern: /^[a-zA-Z0-9#\-/. ]*$/,
            maxLength: 60,
            validate: {
              maxCombinedLength: (value): boolean =>
                doCombinedStringLengthsExceedMax(
                  60,
                  getValues(CreateUserQuizRequestFieldName.ADDRESS_LINE2) || '',
                  value,
                ),
              isEmpty: (value): boolean => !!value.trim(),
            },
          })}
        />
        <TextInputControl
          id={CreateUserQuizRequestFieldName.ADDRESS_LINE2}
          label={personalInformationFormLabels[CreateUserQuizRequestFieldName.ADDRESS_LINE2]}
          errorMessage={errorMessages[CreateUserQuizRequestFieldName.ADDRESS_LINE2]}
          defaultValue={get(initialData, CreateUserQuizRequestFieldName.ADDRESS_LINE2)}
          {...register(CreateUserQuizRequestFieldName.ADDRESS_LINE2, {
            pattern: /^[a-zA-Z0-9#\-/. ]*$/,
            maxLength: 59,
          })}
          onChange={async (): Promise<void> => {
            await trigger(CreateUserQuizRequestFieldName.ADDRESS_LINE1);
          }}
        />
        <TextInputControl
          className="tablet:grid-col-7"
          id={CreateUserQuizRequestFieldName.CITY}
          label={personalInformationFormLabels[CreateUserQuizRequestFieldName.CITY]}
          errorMessage={errorMessages[CreateUserQuizRequestFieldName.CITY]}
          defaultValue={get(initialData, CreateUserQuizRequestFieldName.CITY)}
          required
          {...register(CreateUserQuizRequestFieldName.CITY, {
            required: true,
            pattern: /^[a-zA-Z-' ]*$/,
            maxLength: 38,
            validate: {
              isEmpty: (value): boolean => !!value.trim(),
            },
          })}
        />
        <DropdownControl
          className="tablet:grid-col-7"
          id={CreateUserQuizRequestFieldName.STATE}
          label={personalInformationFormLabels[CreateUserQuizRequestFieldName.STATE]}
          errorMessage={errorMessages[CreateUserQuizRequestFieldName.STATE]}
          defaultValue={get(initialData, CreateUserQuizRequestFieldName.STATE)}
          required
          {...register(CreateUserQuizRequestFieldName.STATE, {
            required: true,
          })}
        >
          <option value="">- Select -</option>
          {STATES.map((state) => (
            <option value={state.code} key={state.code}>
              {state.description}
            </option>
          ))}
        </DropdownControl>
        <TextInputControl
          className="tablet:grid-col-4"
          id={CreateUserQuizRequestFieldName.ZIP_CODE}
          label={personalInformationFormLabels[CreateUserQuizRequestFieldName.ZIP_CODE]}
          errorMessage={errorMessages[CreateUserQuizRequestFieldName.ZIP_CODE]}
          defaultValue={get(initialData, CreateUserQuizRequestFieldName.ZIP_CODE)}
          required
          {...register(CreateUserQuizRequestFieldName.ZIP_CODE, {
            required: true,
            pattern: /^[\d]{5}([-]?[\d]{4})?$/,
          })}
          onKeyPress={(event): void => {
            if (event.key === ' ') {
              event.preventDefault();
            }
          }}
        />
        <Controller
          control={control}
          defaultValue={initialPhoneNumberFormat(get(initialData, CreateUserQuizRequestFieldName.PHONE_NUMBER))}
          name={CreateUserQuizRequestFieldName.PHONE_NUMBER}
          rules={{
            required: true,
            pattern: /^([2-9][0-8][0-9])-([2-9][0-9]{2})-([0-9]{4})$/,
          }}
          render={({ field }): React.JSX.Element => (
            <TextInputControl
              className="tablet:grid-col-8"
              id={CreateUserQuizRequestFieldName.PHONE_NUMBER}
              name={CreateUserQuizRequestFieldName.PHONE_NUMBER}
              label={personalInformationFormLabels[CreateUserQuizRequestFieldName.PHONE_NUMBER]}
              errorMessage={errorMessages[CreateUserQuizRequestFieldName.PHONE_NUMBER]}
              hint="###-###-####"
              maxLength={12}
              required
              value={field.value}
              onChange={(event): void => field.onChange(formatPhoneNumber(event.target.value))}
            />
          )}
        />
      </Form>
      <ActionFooter
        className={CLASS_NAMES.actionFooter}
        submitButtonText="Next"
        onCancel={onCancel}
        onSubmit={handleFormSubmit}
      />
    </>
  );
}

export default PersonalInformationForm;
