import classNames from 'classnames';
import get from 'lodash/get';
import React from 'react';
import { RegisterOptions, useForm } from 'react-hook-form';
import { Fieldset, Form, Legend, RadioButton, TextInputControl } from '@ver-uds/uswds-react';
import { documentNumberProvidedFieldName, fieldDefinitions, I551FormData, i551NumberFieldName } from '../../constants';
import { getFieldError } from '../../../../../utils/forms';
import ActionFooter from '../../../../ActionFooter/ActionFooter';
import { DocumentMarkupComponentProps } from '../DocumentDetailsFormTypes';
import { isBefore1989Sep1 } from '../../../../../utils/dates';

const baseClassName = 'self-check-document-details';
const CLASS_NAMES = {
  base: baseClassName,
  actionFooter: classNames(`${baseClassName}__action-footer`, 'margin-top-4'),
  form: classNames(`${baseClassName}__form`, 'maxw-tablet'),
  fieldSet: `${baseClassName}__field-set`,
  formGroupLabel: classNames(`${baseClassName}__form-group-label`, 'margin-top-2', 'maxw-full'),
  formGroupLabelWrapper: classNames(
    `${baseClassName}__form-group-label-wrapper`,
    'usa-legend',
    'margin-top-0',
    'maxw-tablet',
  ),
  formGroupLabelRequiredText: classNames(
    `${baseClassName}__form-group-label-required-text`,
    'usa-hint',
    'margin-left-05',
  ),
};

export interface I551DocumentMarkupComponentProps extends DocumentMarkupComponentProps<I551FormData> {
  dob?: string;
}

function I551DocumentMarkup({
  data,
  onSubmit,
  onCancel,
  dob = undefined,
}: I551DocumentMarkupComponentProps): React.JSX.Element {
  const defaultValues = { ...(data || {}) };
  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm<I551FormData>({
    mode: 'onSubmit',
    defaultValues,
  });

  const handleFormSubmit = handleSubmit((formData) => {
    onSubmit(formData);
  });

  const documentNumberErrorMessage = getFieldError(
    errors.i551Number,
    fieldDefinitions[i551NumberFieldName].errorMessages,
  );

  const shouldPromptForDocNumber = (): boolean => dob != null && isBefore1989Sep1(dob);

  const isDocumentNumberProvided = (checkedValue?: string): boolean =>
    !shouldPromptForDocNumber() || checkedValue !== 'no';

  const handleNoDocumentNumberChange = (): void => {
    setValue(i551NumberFieldName, '');
  };

  return (
    <>
      <Form className={CLASS_NAMES.form} onSubmit={handleFormSubmit} variant="large">
        {shouldPromptForDocNumber() && (
          <>
            <Fieldset className={CLASS_NAMES.fieldSet}>
              <div className={CLASS_NAMES.formGroupLabelWrapper}>
                <Legend className={CLASS_NAMES.formGroupLabel}>
                  Is there a document number on your Permanent Resident Card?
                  <span className={CLASS_NAMES.formGroupLabelRequiredText} aria-hidden="true">
                    (required)
                  </span>
                </Legend>
              </div>

              <RadioButton
                id={`${documentNumberProvidedFieldName}-yes`}
                label="Yes, there is a document number"
                value="yes"
                defaultChecked={isDocumentNumberProvided(get(data, documentNumberProvidedFieldName))}
                {...register(documentNumberProvidedFieldName, { required: true })}
                aria-required="true"
              />
              <RadioButton
                id={`${documentNumberProvidedFieldName}-no`}
                label="No, there is not a document number"
                value="no"
                defaultChecked={!isDocumentNumberProvided(get(data, documentNumberProvidedFieldName))}
                {...register(documentNumberProvidedFieldName, { required: true })}
                aria-required="true"
                onChange={handleNoDocumentNumberChange}
              />
            </Fieldset>
            <h2 className="margin-top-4">Enter Document Number</h2>
          </>
        )}
        <div className="maxw-mobile-lg">
          <TextInputControl
            id={i551NumberFieldName}
            label={fieldDefinitions[i551NumberFieldName].label}
            hint={!documentNumberErrorMessage ? fieldDefinitions[i551NumberFieldName].hint : undefined}
            errorMessage={documentNumberErrorMessage}
            disabled={!isDocumentNumberProvided(watch(documentNumberProvidedFieldName))}
            required={isDocumentNumberProvided(watch(documentNumberProvidedFieldName))}
            defaultValue={get(data, i551NumberFieldName)}
            {...register(i551NumberFieldName, {
              required: isDocumentNumberProvided(watch(documentNumberProvidedFieldName)),
              ...(get(fieldDefinitions, [i551NumberFieldName, 'validation'], {}) as RegisterOptions<
                I551FormData,
                typeof i551NumberFieldName
              >),
            })}
          />
        </div>
      </Form>
      <ActionFooter
        className={CLASS_NAMES.actionFooter}
        onCancel={onCancel}
        cancelButtonText="Back"
        onSubmit={handleFormSubmit}
        submitButtonText="Next"
      />
    </>
  );
}

export default I551DocumentMarkup;
