import React from 'react';
import classNames from 'classnames';
import { Alert } from '@ver-uds/uswds-react';
import { CustomClassValue } from '../../utils/types';
import { COLUMN_9_CLASS_NAME } from '../../constants';

export interface ConfirmIdentityAlertProps {
  className?: CustomClassValue;
}

const baseClassName = 'confirm-information-alert';
const CLASS_NAMES = {
  base: baseClassName,
  text: classNames(`${baseClassName}__text`, COLUMN_9_CLASS_NAME),
};

function ConfirmIdentityAlert({ className = undefined }: ConfirmIdentityAlertProps): React.JSX.Element {
  return (
    <div className={classNames(CLASS_NAMES.base, className)} role="alert" aria-atomic="true">
      <Alert status="error">
        <Alert.Heading>The combination you entered doesn't match our records</Alert.Heading>
        <Alert.Text className={CLASS_NAMES.text}>
          The Date of Birth and Social Security number combination you entered doesn’t match our records. Please review
          the information entered and try again.
        </Alert.Text>
      </Alert>
    </div>
  );
}

export default ConfirmIdentityAlert;
