import React from 'react';
import classNames from 'classnames';
import { CustomClassValue } from '../../../../utils/types';

export interface I9CentralFooterProps {
  className?: CustomClassValue;
}

const baseClassName = 'I-9-central-footer';
const CLASS_NAMES = {
  base: baseClassName,
};

function I9CentralFooter({ className = undefined }: I9CentralFooterProps): React.JSX.Element {
  return (
    <p className={classNames(CLASS_NAMES.base, className)}>
      Visit <a href="https://www.uscis.gov/i-9-central">I-9 Central</a> for additional help or questions.
    </p>
  );
}

export default I9CentralFooter;
