import React from 'react';
import classNames from 'classnames';
import { CustomClassValue } from '../../../../../../../../../utils/types';
import { StartNewCaseActionFooter } from '../shared';

export interface SelfCheckCaseDetailFNCActionSectionProps {
  className?: CustomClassValue;
  caseNumber: string;
}

const baseClassName = 'case-detail-self-check-action-section--fnc';
const CLASS_NAMES = {
  base: baseClassName,
};

function SelfCheckCaseDetailFNCActionSection({
  className = undefined,
  caseNumber,
}: SelfCheckCaseDetailFNCActionSectionProps): React.JSX.Element {
  return <StartNewCaseActionFooter className={classNames(CLASS_NAMES.base, className)} caseNumber={caseNumber} />;
}

export default SelfCheckCaseDetailFNCActionSection;
