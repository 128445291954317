import React from 'react';
import classNames from 'classnames';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Alert, Form } from '@ver-uds/uswds-react';
import { COLUMN_9_CLASS_NAME } from '../../../../../constants';
import { SelfCheckFormData } from '../../constants';
import { FIELD_ORDER } from '../constants';
import FormFields, { FormFieldData } from '../../../../Form/FormFields/FormFields';
import {
  SelfCheckCaseReverificationRequest,
  SelfCheckReverificationFieldNameUserInputRequired,
} from '../../../../../services/SelfCheckApi';
import { getDataByFieldName } from '../util';
import { mapReverifySectionFieldDataToFormFieldData, ReactHookFormProps, ReverifySectionFieldData } from './util';
import { CustomClassValue } from '../../../../../utils/types';
import ActionFooter from '../../../../ActionFooter/ActionFooter';

type SelfCheckReverificationFieldNameUserVisible = SelfCheckReverificationFieldNameUserInputRequired | 'dob';

export const REVERIFY_SUBMIT_BUTTON_TEXT = 'Resubmit';

export interface ReverifySectionMarkupProps {
  className?: CustomClassValue;
  reverificationFields?: SelfCheckReverificationFieldNameUserVisible[];
  data?: SelfCheckFormData;
  onSubmit: SubmitHandler<SelfCheckCaseReverificationRequest>;
}

const baseClassName = 'self-check-review-reverify-section';
const CLASS_NAMES = {
  base: baseClassName,
  warningAlert: classNames(`${baseClassName}__info-alert`, 'margin-bottom-3', 'margin-top-3'),
  warningAlertText: classNames(`${baseClassName}__info-alert-text`, COLUMN_9_CLASS_NAME),
  form: `${baseClassName}__form`,
  actionFooter: classNames(`${baseClassName}__action-footer`, 'margin-top-4'),
};

const createFormFields = (
  fieldData: ReverifySectionFieldData[],
  reactHookFormProps: ReactHookFormProps,
): FormFieldData[] => mapReverifySectionFieldDataToFormFieldData(fieldData, reactHookFormProps);

function ReverifySectionMarkup({
  className = undefined,
  data = undefined,
  reverificationFields = [],
  onSubmit,
}: ReverifySectionMarkupProps): React.JSX.Element {
  const fieldData: ReverifySectionFieldData[] = [];
  const defaultValues: SelfCheckCaseReverificationRequest = {};
  reverificationFields.forEach((name) => {
    const value = getDataByFieldName(name, data);

    fieldData.push({
      name,
      value,
    });

    defaultValues[name] = value;
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = useForm<SelfCheckCaseReverificationRequest>({
    mode: 'onSubmit',
    defaultValues,
  });

  const formFields = createFormFields(fieldData, {
    control,
    register,
    setValue,
    errors,
  } as ReactHookFormProps<SelfCheckCaseReverificationRequest>);

  const handleFormSubmit = handleSubmit((formData: SelfCheckCaseReverificationRequest) => {
    onSubmit(formData);
  });

  const handleCancel = () => {
    reset(defaultValues);
    window.location.href = '/';
  };

  return (
    <div className={classNames(CLASS_NAMES.base, className)}>
      <h2>Document Details</h2>
      <Alert status="warning">
        <Alert.Heading>Please Confirm Information</Alert.Heading>
        <Alert.Text>
          An initial check of our records indicates that you may have mistyped some of your information. Please review
          the information you provided and correct any errors.
        </Alert.Text>
      </Alert>
      <Alert status="warning">
        <Alert.Heading>Editing Information Temporarily Disabled</Alert.Heading>
        <Alert.Text>
          Due to an issue with the editing feature, we are not able to process edits to the information provided. If the
          information below is correct, please click "Resubmit". Otherwise, please click "cancel" to end this request,
          then start a new request. If you reach the "General System Error" page, please wait one minute and then try
          again.
        </Alert.Text>
      </Alert>
      {watch() && (
        <Form className={classNames(CLASS_NAMES.form, className)} onSubmit={handleFormSubmit} variant="large">
          <FormFields data={formFields} sortOrder={FIELD_ORDER} tempDisabled />
        </Form>
      )}
      <ActionFooter
        className={CLASS_NAMES.actionFooter}
        // hideCancelButton
        onCancel={handleCancel}
        onSubmit={handleFormSubmit}
        submitButtonText={REVERIFY_SUBMIT_BUTTON_TEXT}
        submitButtonVariant="submit"
      />
    </div>
  );
}

export default ReverifySectionMarkup;
