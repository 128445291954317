import React from 'react';
import SelfLockLock from '../components/SelfLock/SelfLockLock/SelfLockLock';
import PageContentLayout from '../layouts/PageContentLayout/PageContentLayout';

function SelfLockLockPage(): React.JSX.Element {
  return (
    <PageContentLayout title="Lock My SSN">
      <SelfLockLock />
    </PageContentLayout>
  );
}

export default SelfLockLockPage;
