import React from 'react';
import get from 'lodash/get';
import { CustomClassValue } from '../../../../utils/types';
import { UploadDocumentType, useGetOpenSelfCheckCaseQuery } from '../../../../services/CaseApi';
import DocumentUploadSuccessModalMarkup from './DocumentUploadSuccessModalMarkup';

export interface DocumentUploadSuccessModalProps {
  className?: CustomClassValue;
  caseNumber: string;
  isOpen: boolean;
  uploadDocumentType: UploadDocumentType;
}

function DocumentUploadSuccessModal({
  className = undefined,
  caseNumber,
  isOpen,
  uploadDocumentType,
}: DocumentUploadSuccessModalProps): React.JSX.Element {
  const { data: openSelfCheckCaseData, isError, isFetching, isLoading, isSuccess } = useGetOpenSelfCheckCaseQuery();
  const isIdle = !isError && !isFetching && !isLoading && !isSuccess;

  if (isIdle || isLoading) {
    return <div />;
  }

  const isSelfCheckCase = get(openSelfCheckCaseData, 'caseNumber') === caseNumber;
  return (
    <DocumentUploadSuccessModalMarkup
      className={className}
      isOpen={isOpen}
      isSelfCheckCase={isSelfCheckCase}
      uploadDocumentType={uploadDocumentType}
    />
  );
}

export default DocumentUploadSuccessModal;
