import React from 'react';
import { Navigate } from 'react-router-dom';
import get from 'lodash/get';
import { AlertProps } from '@ver-uds/uswds-react/dist/Alert/Alert';
import paths from '../../../routing/paths';
import {
  SelfLockChallengeQuestion,
  SelfLockState,
  useSelfLockQuery,
  useSelfLockUpdateMutation,
  SelfLockLockPayload,
} from '../../../services/SelfLockApi';
import NotificationAlert from '../../../utils/NotificationAlert/NotificationAlert';
import SelfLockLockMarkup from './SelfLockLockMarkup';

export const SUCCESS_MESSAGE = 'Your Social Security Number has been successfully locked.';
export const UNEXPECTED_SERVER_ERROR_MESSAGE = 'An unexpected error occurred. Please try again.';

function SelfLockLock(): React.JSX.Element {
  const { data, error, isError, isFetching, isLoading, isSuccess } = useSelfLockQuery();
  const isIdle = !isError && !isFetching && !isLoading && !isSuccess;
  const {
    mutate,
    error: submissionError,
    isPending: isSubmissionLoading,
    isSuccess: isSubmissionSuccess,
  } = useSelfLockUpdateMutation();

  const setNotificationAlert = (status: AlertProps['status'], message: string) => {
    const alert = new NotificationAlert(status, message);
    sessionStorage.setItem('selfLockNotificationAlert', JSON.stringify(alert));
  };

  if (submissionError) {
    setNotificationAlert('error', UNEXPECTED_SERVER_ERROR_MESSAGE);
    return <Navigate to={paths.selfLock} />;
  }

  if (isSubmissionSuccess) {
    setNotificationAlert('success', SUCCESS_MESSAGE);
    return <Navigate to={paths.selfLock} />;
  }

  if (error || (!isSubmissionLoading && get(data, 'state', SelfLockState.UNLOCKED) !== SelfLockState.UNLOCKED)) {
    return <Navigate to={paths.error} />;
  }

  const handleSubmit = (challengeQuestions: SelfLockChallengeQuestion[]): void => {
    const payload: SelfLockLockPayload = {
      state: SelfLockState.LOCKED,
      challengeQuestions,
    };

    mutate(payload);
  };

  return <div>{!isLoading && !isIdle && <SelfLockLockMarkup onSubmit={handleSubmit} />}</div>;
}

export default SelfLockLock;
