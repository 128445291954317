import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import get from 'lodash/get';
import {
  useSelfLockQuery,
  SelfLockState,
  SelfLockUnlockPayload,
  useSelfLockUpdateMutation,
} from '../../services/SelfLockApi';
import SelfLockMarkup from './SelfLockMarkup';
import NotificationAlert from '../../utils/NotificationAlert/NotificationAlert';

export const SUCCESS_MESSAGE = 'Your Social Security Number has been successfully unlocked.';
export const UNEXPECTED_SERVER_ERROR_MESSAGE = 'An unexpected error occurred. Please try again.';

function SelfLock(): React.JSX.Element {
  const { data, error, isError, isFetching, isLoading, isSuccess } = useSelfLockQuery();
  const isIdle = !isError && !isFetching && !isLoading && !isSuccess;
  const {
    mutate,
    error: submissionError,
    isPending: isSubmissionLoading,
    isSuccess: isSubmissionSuccess,
  } = useSelfLockUpdateMutation();

  const navigate = useNavigate();
  const historyNotificationAlert = get(navigate, 'location.state.notificationAlert');
  const [notificationAlert, setNotificationAlert] = useState<NotificationAlert>();

  useEffect(() => {
    if (isSubmissionSuccess) {
      setNotificationAlert(new NotificationAlert('success', SUCCESS_MESSAGE));
    } else if (submissionError) {
      setNotificationAlert(new NotificationAlert('error', UNEXPECTED_SERVER_ERROR_MESSAGE));
    } else if (historyNotificationAlert) {
      setNotificationAlert(historyNotificationAlert);
    }
  }, [historyNotificationAlert, isSubmissionSuccess, submissionError]);

  const handleUserUnlockRequest = (): void => {
    const payload: SelfLockUnlockPayload = {
      state: SelfLockState.UNLOCKED,
    };

    mutate(payload);
  };

  if (error) {
    return <Navigate to="/error" />;
  }

  return (
    <div>
      {!isLoading && !isIdle && !isSubmissionLoading && (
        <SelfLockMarkup
          data={data}
          handleUnlockButtonClick={handleUserUnlockRequest}
          notificationAlert={notificationAlert}
        />
      )}
    </div>
  );
}

export default SelfLock;
