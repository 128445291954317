import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Alert } from '@ver-uds/uswds-react';
import { UserInfoResponseData, UserQuizAnswersRequestData, UserQuizResponseData } from '../../../services/UserApi';
import IdentityProofingAttemptCounter, { MAX_ID_PROOFING_ATTEMPTS } from './IdentityProofingAttemptCounter';
import VerifyIdentityForm from './VerifyIdentityForm';

export interface VerifyIdentityMarkupProps {
  quiz?: UserQuizResponseData;
  onSubmit: (data: UserQuizAnswersRequestData[]) => void;
  userInfo?: UserInfoResponseData;
  timeoutRedirect: () => void;
}

const baseClassName = 'verify-identity-markup';
export const CLASS_NAMES = {
  base: baseClassName,
  header: classNames(`${baseClassName}__header`, 'margin-0'),
  alert: classNames(`${baseClassName}__alert`, 'margin-bottom-3'),
};

const FIVE_MINUTE_COUNTDOWN_VALUE = 300;

const toTimeFormat = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const second = seconds - minutes * 60;

  return `${minutes} min, ${second} sec.`;
};

function VerifyIdentityMarkup({
  quiz = undefined,
  onSubmit,
  userInfo = undefined,
  timeoutRedirect,
}: VerifyIdentityMarkupProps): React.JSX.Element {
  const [count, setCount] = useState(FIVE_MINUTE_COUNTDOWN_VALUE);

  useEffect(() => {
    const timer = setInterval(() => {
      if (count > 0) {
        setCount(count - 1);
      }
      clearInterval(timer);
    }, 1000);
    if (count === 0) {
      timeoutRedirect();
    }
  }, [count, timeoutRedirect]);

  return (
    <div className={baseClassName}>
      <h1 className={CLASS_NAMES.header}>Identity Proofing Quiz</h1>
      <IdentityProofingAttemptCounter idProofingAttempts={userInfo?.idProofingAttempts} />
      <Alert status="info" className={CLASS_NAMES.alert}>
        <Alert.Text>
          <div className="grid-col-9">
            You will have {MAX_ID_PROOFING_ATTEMPTS} attempts and five minutes to answer all four questions. The current
            time remaining is <b>{toTimeFormat(count)}</b>
          </div>
        </Alert.Text>
      </Alert>
      <VerifyIdentityForm quiz={quiz} onSubmit={onSubmit} />
    </div>
  );
}

export default VerifyIdentityMarkup;
