import React from 'react';
import { SelfCheckForm, SelfCheckFormConsumer } from '../../../context/Form/SelfCheck/SelfCheckFormContext';

const withSelfCheckFormConsumer = (Component: (props: SelfCheckForm) => React.JSX.Element): (() => React.JSX.Element) =>
  function selfCheckFormConsumer(): React.JSX.Element {
    return (
      <SelfCheckFormConsumer>
        {(props: SelfCheckForm): React.JSX.Element => (
          <Component {...props} /> /* eslint-disable-line react/jsx-props-no-spreading */
        )}
      </SelfCheckFormConsumer>
    );
  };

export default withSelfCheckFormConsumer;
