import classNames from 'classnames';
import get from 'lodash/get';
import React from 'react';
import { Controller, RegisterOptions, useForm } from 'react-hook-form';
import { Form, TextInputControl } from '@ver-uds/uswds-react';
import {
  documentExpirationDateFieldName,
  fieldDefinitions,
  USPassportFormData,
  usPassportNumberFieldName,
} from '../../constants';
import { getFieldError } from '../../../../../utils/forms';
import ActionFooter from '../../../../ActionFooter/ActionFooter';
import FormattedDateInput from '../../../../Form/FormattedDateInput/FormattedDateInput';
import { DocumentMarkupComponentProps } from '../DocumentDetailsFormTypes';

const baseClassName = 'self-check-document-details';
const CLASS_NAMES = {
  base: baseClassName,
  actionFooter: classNames(`${baseClassName}__action-footer`, 'margin-top-4'),
  form: `${baseClassName}__form`,
  dateInputSpacing: 'margin-top-3',
};

function USPassportDocumentMarkup({
  data,
  onSubmit,
  onCancel,
}: DocumentMarkupComponentProps<USPassportFormData>): React.JSX.Element {
  const {
    formState: { errors },
    handleSubmit,
    register,
    control,
  } = useForm<USPassportFormData>({
    mode: 'onSubmit',
    defaultValues: { ...(data || {}) },
  });

  const handleFormSubmit = handleSubmit((formData) => {
    onSubmit(formData);
  });

  const documentNumberErrorMessage = getFieldError(
    errors.usPassportNumber,
    fieldDefinitions[usPassportNumberFieldName].errorMessages,
  );

  const dateInputErrorMessage = getFieldError(
    errors.documentExpirationDate,
    fieldDefinitions[documentExpirationDateFieldName].errorMessages,
  );

  return (
    <>
      <Form className={CLASS_NAMES.form} onSubmit={handleFormSubmit} variant="large">
        <TextInputControl
          id={usPassportNumberFieldName}
          label={fieldDefinitions[usPassportNumberFieldName].label}
          hint={!documentNumberErrorMessage ? fieldDefinitions[usPassportNumberFieldName].hint : undefined}
          errorMessage={documentNumberErrorMessage}
          required
          defaultValue={get(data, usPassportNumberFieldName)}
          {...register(usPassportNumberFieldName, {
            required: true,
            ...(get(fieldDefinitions, [usPassportNumberFieldName, 'validation'], {}) as RegisterOptions<
              USPassportFormData,
              typeof usPassportNumberFieldName
            >),
          })}
        />
        <Controller
          control={control}
          name={documentExpirationDateFieldName}
          rules={{
            required: true,
            validate: get(fieldDefinitions[documentExpirationDateFieldName], 'validate', {}),
          }}
          render={({ field }): React.JSX.Element => (
            <FormattedDateInput
              className={CLASS_NAMES.dateInputSpacing}
              id={documentExpirationDateFieldName}
              name={documentExpirationDateFieldName}
              label={fieldDefinitions[documentExpirationDateFieldName].label}
              hint={!dateInputErrorMessage ? fieldDefinitions[documentExpirationDateFieldName].hint : undefined}
              errorMessage={dateInputErrorMessage}
              required
              defaultValue={get(data, documentExpirationDateFieldName)}
              formattedDate={field.value}
              onUpdate={field.onChange}
            />
          )}
        />
      </Form>
      <ActionFooter
        className={CLASS_NAMES.actionFooter}
        onCancel={onCancel}
        cancelButtonText="Back"
        onSubmit={handleFormSubmit}
        submitButtonText="Next"
      />
    </>
  );
}

export default USPassportDocumentMarkup;
