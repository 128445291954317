import React, { useEffect, useMemo, useState } from 'react';
import { Navigate } from 'react-router-dom';
import zipWith from 'lodash/zipWith';
import reject from 'lodash/reject';
import { CustomClassValue } from '../../utils/types';
import paths from '../../routing/paths';
import { useGetCasesQuery, useGetCaseUploadsQueries } from '../../services';
import CaseHistoryMarkup from './CaseHistoryMarkup';
import { isCaseOpen } from './util';

export interface CaseHistoryProps {
  className?: CustomClassValue;
}

function CaseHistory({ className = undefined }: CaseHistoryProps): React.JSX.Element {
  const {
    data: cases,
    isError: fetchCasesFailed,
    isFetching: fetchingCases,
    isLoading: loadingCases,
    isSuccess: fetchCasesSucceeded,
  } = useGetCasesQuery();
  const [isReady, setIsReady] = useState(false);

  const isIdle = useMemo(
    () => !fetchCasesFailed && !fetchingCases && !loadingCases && !fetchCasesSucceeded,
    [fetchCasesFailed, fetchingCases, loadingCases, fetchCasesSucceeded],
  );

  const caseUploadQueriesResults = useGetCaseUploadsQueries(
    cases?.filter(isCaseOpen).map((caseData) => caseData.caseNumber) || [],
  );

  const uploadCounts = useMemo(
    () => caseUploadQueriesResults.map((result) => result.data?.count),
    [caseUploadQueriesResults],
  );

  const closedCases = useMemo(() => reject(cases, isCaseOpen), [cases]);
  const openCases = useMemo(
    () =>
      zipWith(uploadCounts, cases?.filter(isCaseOpen) || [], (uploadCount, caseData) => ({
        ...caseData,
        openCaseStatus: {
          uploadCount,
          caseStatus: caseData.caseStatus,
        },
      })),
    [uploadCounts, cases],
  );

  const uploadsQueryIsIdle = useMemo(
    () =>
      caseUploadQueriesResults.every(
        (caseUploadQuery) =>
          !caseUploadQuery.isError &&
          !caseUploadQuery.isLoading &&
          !caseUploadQuery.isFetching &&
          !caseUploadQuery.isSuccess,
      ),
    [caseUploadQueriesResults],
  );

  useEffect(() => {
    if ((!isIdle && !uploadsQueryIsIdle) || fetchCasesSucceeded) {
      setIsReady(true);
    }
  }, [isIdle, loadingCases, uploadsQueryIsIdle, fetchCasesSucceeded]);

  if (fetchCasesFailed) {
    return <Navigate to={paths.error} />;
  }

  if (!isReady) {
    return <div />;
  }

  return <CaseHistoryMarkup className={className} cases={closedCases.concat(openCases)} />;
}

export default CaseHistory;
