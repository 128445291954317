import React from 'react';
import classNames from 'classnames';
import { CustomClassValue } from '../../../../utils/types';
import { ReadableCaseStatus } from '../../../../services';
import { SelfCheckCaseResponseData } from '../../../../services/SelfCheckApi';
import { resolveToCaseDetailHeadingText } from './util';

export interface CaseDetailHeadingProps {
  className?: CustomClassValue;
  caseStatus: ReadableCaseStatus;
  selfCheckCaseData?: SelfCheckCaseResponseData;
}

const baseClassName = 'case-detail-heading';
const CLASS_NAMES = {
  base: baseClassName,
};

function CaseDetailHeading({
  className = undefined,
  caseStatus,
  selfCheckCaseData = undefined,
}: CaseDetailHeadingProps): React.JSX.Element {
  return (
    <h1 className={classNames(CLASS_NAMES.base, className)}>
      {resolveToCaseDetailHeadingText(caseStatus, selfCheckCaseData)}
    </h1>
  );
}

export default CaseDetailHeading;
