import get from 'lodash/get';
import { ReadableCaseStatus } from '../../../../services/CaseApi';
import { CaseStatus, SelfCheckCaseResponseData } from '../../../../services/SelfCheckApi';
import { CaseEligibilityStatus } from '../constants';

/* eslint-disable import/prefer-default-export */
export const mapReadableCaseStatusToCaseDetailHeadingText = (readableCaseStatus: ReadableCaseStatus): string => {
  switch (readableCaseStatus) {
    case ReadableCaseStatus.CASE_INCOMPLETE:
      return 'Case Incomplete';
    case ReadableCaseStatus.CLOSE_AND_RESUBMIT:
      return 'Close and Resubmit';
    case ReadableCaseStatus.CLOSED:
      return 'Closed';
    case ReadableCaseStatus.EMPLOYMENT_AUTHORIZED:
      return 'Employment Authorized';
    case ReadableCaseStatus.CHECK_BACK_LATER:
      return 'Check Back Later';
    case ReadableCaseStatus.FINAL_NONCONFIRMATION:
      return 'Final Nonconfirmation';
    case ReadableCaseStatus.EMPLOYEE_REFERRED_DHS:
      return 'Employee Referred (DHS)';
    case ReadableCaseStatus.EMPLOYEE_REFERRED_SSA:
    case ReadableCaseStatus.EMPLOYEE_REFERRED_DHS_OR_SSA:
      /* Applied for NATZ Referred cases since a NATZ is an SSA Case that is handled by DHS. */
      return 'Employee Referred (SSA)';
    case ReadableCaseStatus.EMPLOYEE_REFERRED_DHS_AND_SSA:
      return 'Employee Referred (DHS and SSA)';
    case ReadableCaseStatus.EMPLOYEE_REFERRED_CONTINUANCE:
      return 'Case in Continuance (Verification in Process/E-Verify needs more time)';
    case ReadableCaseStatus.TENTATIVE_NONCONFIRMATION_DHS:
      return 'Tentative Nonconfirmation (Mismatch) (DHS)';
    case ReadableCaseStatus.TENTATIVE_NONCONFIRMATION_SSA:
    case ReadableCaseStatus.TENTATIVE_NONCONFIRMATION_DHS_OR_SSA:
      /* Applied for NATZ TNC cases since a NATZ is an SSA Case that is handled by DHS. */
      return 'Tentative Nonconfirmation (Mismatch) (SSA)';
    case ReadableCaseStatus.TENTATIVE_NONCONFIRMATION_DHS_AND_SSA:
      return 'Tentative Nonconfirmation (Mismatch) (DHS and SSA)';
    case ReadableCaseStatus.CONTINUANCE:
      return "We're sorry, things are taking longer than usual";
    case ReadableCaseStatus.UNKNOWN:
    default:
      return 'Unknown';
  }
};

export const mapSelfCheckCaseStatusToCaseDetailHeadingText = (selfCheckCaseStatus?: CaseStatus): string | undefined => {
  switch (selfCheckCaseStatus) {
    case CaseStatus.REFERRED:
      return 'Pending Employee Action';
    case CaseStatus.UNCONFIRMED_DATA:
      return 'We’re sorry, something went wrong';
    case CaseStatus.PENDING_REFERRAL:
      return 'Unable to Confirm Employment Eligibility';
    default:
      return undefined;
  }
};

type OptionalCaseEligibilityStatus = CaseEligibilityStatus | null | undefined;
export const mapSelfCheckCaseEligibilityStatusToCaseDetailHeadingText = (
  caseEligibilityStatus?: OptionalCaseEligibilityStatus,
): string | undefined => {
  switch (caseEligibilityStatus) {
    case CaseEligibilityStatus.EMPLOYMENT_AUTHORIZED:
      return 'You’re authorized to work!';
    case CaseEligibilityStatus.NO_ACTION_FNC:
    case CaseEligibilityStatus.FNC:
      return 'Final Nonconfirmation';
    default:
      return undefined;
  }
};

export const resolveToCaseDetailHeadingText = (
  readableCaseStatus: ReadableCaseStatus,
  selfCheckCaseData?: SelfCheckCaseResponseData,
): string =>
  mapSelfCheckCaseStatusToCaseDetailHeadingText(get(selfCheckCaseData, 'caseStatus')) ||
  mapSelfCheckCaseEligibilityStatusToCaseDetailHeadingText(
    get(selfCheckCaseData, 'caseEligibilityStatement') as OptionalCaseEligibilityStatus,
  ) ||
  mapReadableCaseStatusToCaseDetailHeadingText(readableCaseStatus);
