import React, { useContext } from 'react';
import { CustomClassValue } from '../../../../../../../../utils/types';
import { AuthenticationContext } from '../../../../../../../../context/Authentication/AuthenticationContext';
import { useEnvironment } from '../../../../../../../../context/Environment/EnvironmentContext';
import { TncType, useCaseTrackerQuery } from '../../../../../../../../services/CaseApi';
import CaseUploadActionFooterMarkup from './CaseUploadActionFooterMarkup';
import { mapRawCaseStatusToReadableCaseStatus } from '../../../../../../../../services/CaseApi/CaseApiUtil';

export interface CaseUploadActionFooterProps {
  className?: CustomClassValue;
  caseNumber: string;
  tncType: TncType;
}

function CaseUploadActionFooter({
  className = undefined,
  caseNumber,
  tncType,
}: CaseUploadActionFooterProps): React.JSX.Element {
  const { environmentVariables } = useEnvironment();
  const { isAuthenticated } = useContext(AuthenticationContext);
  const { data: caseTrackerData } = useCaseTrackerQuery(caseNumber);

  if (!environmentVariables) {
    return <div />;
  }

  return (
    <CaseUploadActionFooterMarkup
      className={className}
      caseNumber={caseNumber}
      isAuthenticated={isAuthenticated()}
      loginUrl={environmentVariables.MYEVERIFY_WEB_SERVICE_LOGIN}
      tncType={tncType}
      caseStatus={mapRawCaseStatusToReadableCaseStatus(caseTrackerData?.caseStatus)}
    />
  );
}

export default CaseUploadActionFooter;
