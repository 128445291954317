import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import get from 'lodash/get';
import paths from '../../routing/paths';
import { isCaseNumberValid, useCaseTrackerQuery, useGetOpenSelfCheckCaseQuery } from '../../services/CaseApi';
import { mapRawCaseStatusToReadableCaseStatus } from '../../services/CaseApi/CaseApiUtil';
import { useGetCaseDetailsByCaseNumberQuery } from '../../services/SelfCheckApi';
import CaseDetailMarkup from './CaseDetailMarkup';

function CaseDetail(): React.JSX.Element {
  const urlParams = useParams();
  const caseNumber = get(urlParams, 'caseNumber', undefined);
  const {
    data: caseTrackerData,
    error,
    isError,
    isFetching,
    isLoading: isCaseTrackerLoading,
    isSuccess,
  } = useCaseTrackerQuery(caseNumber);
  const isCaseTrackerIdle = !isError && !isFetching && !isCaseTrackerLoading && !isSuccess;
  const { data: openSelfCheckCaseData, isLoading: isGetOpenSelfCheckCaseLoading } = useGetOpenSelfCheckCaseQuery();

  const { data: selfCheckCaseData, isLoading: isGetCaseDetailsByCaseNumberLoading } =
    useGetCaseDetailsByCaseNumberQuery(caseNumber);

  if (error || !isCaseNumberValid(caseNumber)) {
    return <Navigate to={paths.error} />;
  }

  return (
    <div>
      {!isCaseTrackerIdle &&
        !isCaseTrackerLoading &&
        !isGetOpenSelfCheckCaseLoading &&
        !isGetCaseDetailsByCaseNumberLoading && (
          <CaseDetailMarkup
            caseNumber={caseNumber as string}
            caseStatus={mapRawCaseStatusToReadableCaseStatus(caseTrackerData?.caseStatus)}
            selfCheckCaseData={openSelfCheckCaseData && selfCheckCaseData}
          />
        )}
    </div>
  );
}

export default CaseDetail;
