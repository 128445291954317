import React from 'react';
import classNames from 'classnames';
import isUndefined from 'lodash/isUndefined';
import pick from 'lodash/pick';
import { SelfCheckForm as SelfCheckFormContextData } from '../../../context/Form/SelfCheck/SelfCheckFormContext';
import { SelfCheckCaseResponseData } from '../../../services/SelfCheckApi';
import { SelfCheckFormData, SelfCheckFormPage } from './constants';
import SelfCheckForm from './SelfCheckForm';
import { useOnBeforeUnload } from '../../../utils/forms';

const baseClassName = 'self-check-form-container';
const CLASS_NAMES = {
  base: baseClassName,
  divider: classNames(`${baseClassName}__divider`, 'border-top-1px', 'border-base-lighter', 'margin-top-3'),
};

export const hasFormBeenStarted = (formData: SelfCheckFormData): boolean =>
  formData?.[SelfCheckFormPage.CITIZENSHIP_STATUS] != null;

type SelfCheckFormContainerProps = SelfCheckFormContextData;

function SelfCheckFormContainer({
  formData,
  updateFormData,
  formPage,
  updateFormPage,
  caseData,
  updateCaseData,
}: SelfCheckFormContainerProps): React.JSX.Element {
  useOnBeforeUnload(hasFormBeenStarted(formData));

  const stepFormPage = (step: 1 | -1 = 1): void => {
    updateFormPage(formPage.valueOf() + step);
  };

  const handleBack = (): void => stepFormPage(-1);

  const handleSubmit = (data: SelfCheckFormData): void => {
    const updatedData = {
      ...formData,
      ...data,
    };
    updateFormData(updatedData);

    stepFormPage();
  };

  const handleCaseSubmitSuccess = (caseSubmitResponseData?: SelfCheckCaseResponseData): void => {
    updateCaseData(caseSubmitResponseData);
    if (isUndefined(caseSubmitResponseData)) {
      // Case submission complete, clear form state
      updateFormData(pick(formData, SelfCheckFormPage.CONFIRM_IDENTITY));
      updateFormPage(SelfCheckFormPage.CONFIRM_IDENTITY);
    }
  };

  return (
    <div className={CLASS_NAMES.base}>
      <SelfCheckForm
        caseData={caseData}
        onCaseSubmitSuccess={handleCaseSubmitSuccess}
        formData={formData}
        formPage={formPage}
        onBack={handleBack}
        onSubmit={handleSubmit}
      />
    </div>
  );
}

export default SelfCheckFormContainer;
