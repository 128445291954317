import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface InternalLinkProps {
  to: string;
  href?: never;
}

interface ExternalLinkProps {
  to?: never;
  href: string;
}

export type LinkProps = (InternalLinkProps | ExternalLinkProps) & {
  className?: string;
  children: React.ReactNode;
};

const isExternalLink = (href?: string): boolean => typeof href === 'string' && href.startsWith('http');

function Link({ href, to, children, className }: LinkProps): React.JSX.Element {
  if (isExternalLink(href)) {
    return (
      <a className={className} href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }
  return (
    <RouterLink className={className} to={to || ''}>
      {children}
    </RouterLink>
  );
}

export default Link;
