import React from 'react';
import classNames from 'classnames';
import { Alert } from '@ver-uds/uswds-react';
import { CustomClassValue } from '../../../../utils/types';
import { SelfCheckFormPage } from '../constants';
import { CitizenshipStatus, DocumentType } from '../../../../services/SelfCheckApi';
import CustomStepIndicator from '../../../CustomStepIndicator/CustomStepIndicator';

const STEP_INDICATOR_LABELS = [
  'Personal Information',
  'Citizenship Status',
  'Document Selection',
  'Document Details',
  'Review & Submit',
];

const getDocumentSelectionHeaderText = (citizenshipStatus?: CitizenshipStatus): string =>
  citizenshipStatus === CitizenshipStatus.NONCITIZEN_AUTHORIZED_TO_WORK
    ? 'Select a Document Number'
    : 'Select a Document';

const getDocumentDetailsHeaderText = (documentType?: DocumentType): string =>
  documentType === DocumentType.LIST_B_C_DOCUMENTS ? 'Confirm Document Details' : 'Enter Document Details';

export const getHeaderText = (
  formPage: SelfCheckFormPage,
  citizenshipStatus?: CitizenshipStatus,
  documentType?: DocumentType,
): string | undefined => {
  switch (formPage) {
    case SelfCheckFormPage.CONFIRM_IDENTITY:
      return 'Confirm Identity Information';
    case SelfCheckFormPage.CITIZENSHIP_STATUS:
      return 'Select Citizenship Status';
    case SelfCheckFormPage.DOCUMENT_SELECTION:
      return getDocumentSelectionHeaderText(citizenshipStatus);
    case SelfCheckFormPage.DOCUMENT_DETAILS:
      return getDocumentDetailsHeaderText(documentType);
    case SelfCheckFormPage.REVIEW:
      return 'Review Information';
    default:
      return undefined;
  }
};

export interface SelfCheckFormHeaderProps {
  className?: CustomClassValue;
  formPage: SelfCheckFormPage;
  citizenshipStatus?: CitizenshipStatus;
  documentType?: DocumentType;
}

const baseClassName = 'self-check-form-header';
const CLASS_NAMES = {
  base: baseClassName,
  header: classNames(`${baseClassName}__header`, 'margin-0'),
  stepIndicator: `${baseClassName}__step-indicator`,
};

const displayWarningAlert = () => {
  return (
    <Alert status="warning">
      <Alert.Heading>Editing Personal Information Disabled</Alert.Heading>
      <Alert.Text>
        We have temporarily disabled the ability to edit personal information to implement a planned update that will
        enhance the security of our users’ information. This feature will be available again shortly. Thank you for your
        patience.
      </Alert.Text>
    </Alert>
  );
};

function SelfCheckFormHeader({
  className = undefined,
  formPage,
  citizenshipStatus = undefined,
  documentType = undefined,
}: SelfCheckFormHeaderProps): React.JSX.Element {
  return (
    <div className={classNames(CLASS_NAMES.base, className)}>
      <CustomStepIndicator className={CLASS_NAMES.stepIndicator} stepIndex={formPage} labels={STEP_INDICATOR_LABELS} />
      <h1 className={CLASS_NAMES.header} aria-live="polite">
        {getHeaderText(formPage, citizenshipStatus, documentType)}
      </h1>
      {getHeaderText(formPage, citizenshipStatus, documentType) === 'Confirm Identity Information'
        ? displayWarningAlert()
        : null}
    </div>
  );
}

export default SelfCheckFormHeader;
