import React, { useContext } from 'react';
import classNames from 'classnames';
import { Button } from '@ver-uds/react';
import { AuthenticationContext } from '../../../context/Authentication/AuthenticationContext';

type ButtonVariants = 'primary' | 'secondary' | 'submit' | 'destructive' | 'inverse' | 'tertiary' | undefined;

export interface LogOutButtonProps {
  variant?: ButtonVariants;
}

const baseClassName = 'log-out-button';
const CLASS_NAMES = {
  base: classNames('usa-button', baseClassName),
};

function LogOutButton({ variant = 'primary' }: LogOutButtonProps): React.JSX.Element {
  const authentication = useContext(AuthenticationContext);
  return (
    <Button className={CLASS_NAMES.base} onClick={authentication.logout} variant={variant}>
      Sign Out
    </Button>
  );
}

export default LogOutButton;
