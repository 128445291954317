import React from 'react';
import { Accordion } from '@ver-uds/uswds-react';
import prcFront from '../../../assets/img/documents/help/permanentResidentCard/current/prc-front.jpg';
import prcBack from '../../../assets/img/documents/help/permanentResidentCard/current/prc-back.jpg';
import prcFrontPrevious from '../../../assets/img/documents/help/permanentResidentCard/previous/prc-front-previous.jpg';
import prcBackPrevious from '../../../assets/img/documents/help/permanentResidentCard/previous/prc-back-previous.jpg';
import prcFrontOlder from '../../../assets/img/documents/help/permanentResidentCard/older/prc-front-older.jpg';
import prcBackOlder from '../../../assets/img/documents/help/permanentResidentCard/older/prc-back-older.jpg';
import residentAlien from '../../../assets/img/documents/help/permanentResidentCard/older/resident-alien.png';
import ImageWrapper from '../../ImageWrapper';
import HelpSectionContent from '../HelpSectionContent';

const baseClassName = 'i551-help-section';
const CLASS_NAMES = {
  heading: `${baseClassName}__heading`,
  content: `${baseClassName}__content`,
};

function I551HelpSection(): React.JSX.Element {
  return (
    <>
      <Accordion.Heading className={CLASS_NAMES.heading} controls={baseClassName}>
        Help with Permanent Resident Card (Form I-551)
      </Accordion.Heading>
      <HelpSectionContent id={baseClassName} className={CLASS_NAMES.content}>
        <p>
          The Permanent Resident Card (Form I-551) is green and contains the bearer's name, photo, document/card number,
          alien/USCIS number, date of birth, laser-engraved fingerprint, and card expiration date.
        </p>
        <p>
          Document Number: The document number, also called a card number, is printed on the back of the current version
          of the card. The document number is exactly 13 alphanumeric characters (letters and numbers). Do not enter any
          special characters.
        </p>
        <p>Current Form I-551, front and back</p>
        <ImageWrapper
          images={[
            { src: prcFront, alt: 'Example of the front of a permanent resident card' },
            {
              src: prcBack,
              alt: 'Example of the back of a permanent resident card',
            },
          ]}
        />
        <p>Previous Form I-551, front and back</p>
        <ImageWrapper
          images={[
            { src: prcFrontPrevious, alt: 'Example of the front of the previous version of a permanent resident card' },
            {
              src: prcBackPrevious,
              alt: 'Example of the back of the previous version of a permanent resident card',
            },
          ]}
        />
        <p>Older Form I-551, front and back</p>
        <ImageWrapper
          images={[
            { src: prcFrontOlder, alt: 'Example of the front of an older version of a permanent resident card' },
            {
              src: prcBackOlder,
              alt: 'Example of the back of an older version of a permanent resident card',
            },
          ]}
        />
        <p>
          Also in circulation are older Resident Alien Cards. Resident Alien cards are peach in color and contain the
          bearer's name, photo, Alien number, fingerprint and date of birth.
        </p>
        <p>Resident Alien Cards (issued between January 1977 and August 1989)</p>
        <ul>
          <li>No document numbers</li>
          <li>No expiration dates - valid indefinitely</li>
        </ul>
        <ImageWrapper images={[{ src: residentAlien, alt: 'Example of a Resident Alien card' }]} />
      </HelpSectionContent>
    </>
  );
}

export default I551HelpSection;
