import { RawCaseStatus, ReadableCaseStatus } from './CaseApiConstants';

/* eslint-disable import/prefer-default-export */
export const mapRawCaseStatusToReadableCaseStatus = (rawCaseStatus?: RawCaseStatus | null): ReadableCaseStatus => {
  switch (rawCaseStatus) {
    case RawCaseStatus.CASE_INCOMPLETE:
    case RawCaseStatus.CASE_INCOMPLETE_ALTERNATE:
    case RawCaseStatus.REVIEW_UPDATE_EMPLOYEE_DATA:
    case RawCaseStatus.PHOTO_MATCHING_REQUIRED:
    case RawCaseStatus.DUPLICATE_CASE:
      return ReadableCaseStatus.CASE_INCOMPLETE;
    case RawCaseStatus.CLOSE_AND_RESUBMIT:
    case RawCaseStatus.CLOSE_AND_RESUBMIT_ALTERNATE:
      return ReadableCaseStatus.CLOSE_AND_RESUBMIT;
    case RawCaseStatus.CLOSED:
      return ReadableCaseStatus.CLOSED;
    case RawCaseStatus.EMPLOYMENT_AUTHORIZED:
    case RawCaseStatus.EMPLOYMENT_AUTHORIZED_ALTERNATE:
      return ReadableCaseStatus.EMPLOYMENT_AUTHORIZED;
    case RawCaseStatus.CHECK_BACK_LATER:
    case RawCaseStatus.DHS_VERIFICATION_IN_PROCESS:
    case RawCaseStatus.VERIFICATION_IN_PROCESS:
      return ReadableCaseStatus.CHECK_BACK_LATER;
    case RawCaseStatus.FINAL_NONCONFIRMATION:
    case RawCaseStatus.FINAL_NONCONFIRMATION_ALTERNATE:
    case RawCaseStatus.DHS_FINAL_NONCONFIRMATION:
    case RawCaseStatus.NO_SHOW:
    case RawCaseStatus.NO_ACTION_FNC:
    case RawCaseStatus.DHS_NO_SHOW:
    case RawCaseStatus.SSA_FINAL_NONCONFIRMATION:
    case RawCaseStatus.NOT_AUTHORIZED_EMPLOYMENT:
      return ReadableCaseStatus.FINAL_NONCONFIRMATION;
    case RawCaseStatus.EMPLOYEE_REFERRED_DHS:
    case RawCaseStatus.EMPLOYEE_REFERRED_DHS_ALTERNATE_ONE:
    case RawCaseStatus.EMPLOYEE_REFERRED_DHS_ALTERNATE_TWO:
      return ReadableCaseStatus.EMPLOYEE_REFERRED_DHS;
    case RawCaseStatus.EMPLOYEE_REFERRED_SSA:
    case RawCaseStatus.EMPLOYEE_REFERRED_SSA_ALTERNATE_ONE:
    case RawCaseStatus.EMPLOYEE_REFERRED_SSA_ALTERNATE_TWO:
      return ReadableCaseStatus.EMPLOYEE_REFERRED_SSA;
    case RawCaseStatus.EMPLOYEE_REFERRED_DHS_AND_SSA:
    case RawCaseStatus.EMPLOYEE_REFERRED_DHS_AND_SSA_ALTERNATE:
      return ReadableCaseStatus.EMPLOYEE_REFERRED_DHS_AND_SSA;
    case RawCaseStatus.EMPLOYEE_REFERRED_DHS_OR_SSA:
    case RawCaseStatus.EMPLOYEE_REFERRED_DHS_OR_SSA_ALTERNATE:
      return ReadableCaseStatus.EMPLOYEE_REFERRED_DHS_OR_SSA;
    case RawCaseStatus.TENTATIVE_NONCONFIRMATION_DHS:
    case RawCaseStatus.TENTATIVE_NONCONFIRMATION_DHS_ALTERNATE_ONE:
    case RawCaseStatus.TENTATIVE_NONCONFIRMATION_DHS_ALTERNATE_TWO:
    case RawCaseStatus.TENTATIVE_NONCONFIRMATION_DHS_ALTERNATE_THREE:
      return ReadableCaseStatus.TENTATIVE_NONCONFIRMATION_DHS;
    case RawCaseStatus.TENTATIVE_NONCONFIRMATION_SSA:
    case RawCaseStatus.TENTATIVE_NONCONFIRMATION_SSA_ALTERNATE_ONE:
    case RawCaseStatus.TENTATIVE_NONCONFIRMATION_SSA_ALTERNATE_TWO:
      return ReadableCaseStatus.TENTATIVE_NONCONFIRMATION_SSA;
    case RawCaseStatus.TENTATIVE_NONCONFIRMATION_DHS_AND_SSA:
    case RawCaseStatus.TENTATIVE_NONCONFIRMATION_DHS_AND_SSA_ALTERNATE:
      return ReadableCaseStatus.TENTATIVE_NONCONFIRMATION_DHS_AND_SSA;
    case RawCaseStatus.TENTATIVE_NONCONFIRMATION_DHS_OR_SSA:
    case RawCaseStatus.TENTATIVE_NONCONFIRMATION_DHS_OR_SSA_ALTERNATE:
      return ReadableCaseStatus.TENTATIVE_NONCONFIRMATION_DHS_OR_SSA;
    case RawCaseStatus.CASE_IN_CONTIUNANCE:
    case RawCaseStatus.CASE_IN_CONTINUANCE_DHS:
    case RawCaseStatus.CASE_IN_CONTINUANCE_DHS_ALTERNATE:
    case RawCaseStatus.CASE_IN_CONTINUANCE_SSA:
    case RawCaseStatus.CASE_IN_CONTINUANCE_SSA_ALTERNATE:
    case RawCaseStatus.CASE_IN_CONTINUANCE_DHS_AND_SSA:
    case RawCaseStatus.CASE_IN_CONTINUANCE_DHS_OR_SSA:
    case RawCaseStatus.CASE_IN_CONTINUANCE_DHS_OR_SSA_ALTERNATE:
      return ReadableCaseStatus.CONTINUANCE;
    case RawCaseStatus.EMPLOYEE_REFERRED_DHS_AND_CASE_IN_CONTINUANCE_SSA:
    case RawCaseStatus.CASE_IN_CONTINUANCE_DHS_AND_EMPLOYEE_REFERRED_SSA:
    case RawCaseStatus.EMPLOYEE_REFERRED_CONTINUANCE:
      return ReadableCaseStatus.EMPLOYEE_REFERRED_CONTINUANCE;
    case RawCaseStatus.UNABLE_TO_PROCESS:
    default:
      return ReadableCaseStatus.UNKNOWN;
  }
};
