import React from 'react';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Button } from '@ver-uds/react';
import classNames from 'classnames';
import { CustomClassValue } from '../../../../../../../../utils/types';
import { useConfiguration } from '../../../../../../../../services/BaseApi';
import paths from '../../../../../../../../services/paths.json';
import { normalizeURL } from '../../../../../../../../utils/routing';

export const FAN_DOWNLOAD_BUTTON_TEXT = 'Download Further Action Notice';
type ButtonVariants = 'primary' | 'secondary' | 'submit' | 'destructive' | 'inverse' | 'tertiary' | undefined;
export interface FANDownloadButtonProps {
  className?: CustomClassValue;
  variant?: ButtonVariants;
}

const baseClassName = 'fan-download-button';
const CLASS_NAMES = {
  base: baseClassName,
};

function FANDownloadButton({
  className = undefined,
  variant = 'secondary',
}: FANDownloadButtonProps): React.JSX.Element {
  const configuration = useConfiguration();
  return (
    <Button
      className={classNames(CLASS_NAMES.base, className)}
      as="a"
      href={normalizeURL(`${configuration.basePath}${paths.documents.furtherActionNotice}`)}
      variant={variant}
      iconLeft={<FontAwesomeIcon icon={faDownload as IconProp} />}
    >
      {FAN_DOWNLOAD_BUTTON_TEXT}
    </Button>
  );
}

export default FANDownloadButton;
