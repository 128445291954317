import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { MutationCache, QueryCache, useQueryClient } from '@tanstack/react-query';

export interface LoadingStatus {
  isQueryLoading: boolean;
  isMutationLoading: boolean;
}

const INITIAL_LOADING_STATE: LoadingStatus = {
  isQueryLoading: false,
  isMutationLoading: false,
};

const LoadingContext = createContext<LoadingStatus>(INITIAL_LOADING_STATE);

export const useLoadingState = (): LoadingStatus => useContext(LoadingContext);

export function LoadingProvider({ children }: { children: ReactNode }) {
  const queryClient = useQueryClient();
  const [loadingState, setQueryLoadingState] = useState(INITIAL_LOADING_STATE);

  const queryCache = useMemo(() => queryClient.getQueryCache(), [queryClient]);
  const mutationCache = useMemo(() => queryClient.getMutationCache(), [queryClient]);

  const isCacheLoading = (cache: QueryCache | MutationCache): boolean => {
    const entries = cache.getAll();

    return entries.some((entry: any) => {
      const isPending = entry.state.status === 'pending';
      const isFetching = entry.state.fetchStatus === 'fetching';
      const isEnabled = entry.options.enabled;

      return (isPending || isFetching) && isEnabled;
    });
  };

  useEffect(() => {
    const updateLoadingState = () => {
      const queryLoading = isCacheLoading(queryCache);
      const mutationLoading = isCacheLoading(mutationCache);

      setQueryLoadingState({
        isQueryLoading: queryLoading,
        isMutationLoading: mutationLoading,
      });
    };

    const unsubscribeQueryCache = queryCache.subscribe(updateLoadingState);
    const unsubscribeMutationCache = mutationCache.subscribe(updateLoadingState);

    updateLoadingState();

    return () => {
      unsubscribeQueryCache();
      unsubscribeMutationCache();
    };
  }, [queryCache, mutationCache]);

  return <LoadingContext.Provider value={loadingState}>{children}</LoadingContext.Provider>;
}
