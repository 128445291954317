import React, { ComponentPropsWithRef, ReactElement } from 'react';
import { Button, ButtonGroup } from '@ver-uds/react';
import classNames from 'classnames';
import styled from 'styled-components';
import { CustomClassValue } from '../../utils/types';
import Divider from '../Divider/Divider';

const DEFAULT_CANCEL_BUTTON_TEXT = 'Cancel';
const DEFAULT_SUBMIT_BUTTON_TEXT = 'Submit';

const ActionSection = styled.div`
  > .usa-button-group > .usa-button-group__item {
    margin-bottom: 0;
    margin-top: 0;

    /**
     * Required patch until @ver-uds/styles issue gets resolved:
     * https://git.uscis.dhs.gov/USCIS/ver-uds/issues/240
     */
    @media (min-width: 30rem) {
      &:first-child {
        margin-left: 0.25rem;
      }

      &:last-child {
        margin-right: 0.25rem;
      }
    }

    & > .usa-button {
      margin-top: 1rem;
    }
  }
`;

type ButtonVariants = 'primary' | 'secondary' | 'submit' | 'destructive' | 'inverse' | 'tertiary' | undefined;

export interface ActionFooterPropsInterface {
  className?: CustomClassValue;
  disabled?: boolean;
  hideCancelButton?: boolean;
  onCancel?: () => void;
  onSubmit?: () => void;
  cancelButtonText?: string;
  submitButtonText?: string;
  cancelButton?: ReactElement<ComponentPropsWithRef<typeof Button>>;
  submitButton?: ReactElement<ComponentPropsWithRef<typeof Button>>;
  submitButtonVariant?: ButtonVariants;
  cancelTempDisabled?: boolean;
  submitTempDisabled?: boolean;
}

type ActionFooterCancelButtonHiddenProps = { hideCancelButton: true };

type ActionFooterCancelButtonNoCustom = {
  cancelButton?: undefined;
  cancelButtonText?: string;
  onCancel: () => void;
};

type ActionFooterCancelButtonCustomOnly = {
  cancelButton: ReactElement<ComponentPropsWithRef<typeof Button>>;
  cancelButtonText?: undefined;
  onCancel?: undefined;
};

type ActionFooterCancelButtonProps =
  | ActionFooterCancelButtonHiddenProps
  | ActionFooterCancelButtonNoCustom
  | ActionFooterCancelButtonCustomOnly;

type ActionFooterSubmitButtonNoCustom = {
  submitButton?: undefined;
  submitButtonText?: string;
  onSubmit: () => void;
};

type ActionFooterSubmitButtonCustomOnly = {
  submitButton: ReactElement<ComponentPropsWithRef<typeof Button>>;
  submitButtonText?: undefined;
  onSubmit?: undefined;
};

type ActionFooterSubmitButtonProps = ActionFooterSubmitButtonNoCustom | ActionFooterSubmitButtonCustomOnly;

export type ActionFooterProps = ActionFooterPropsInterface &
  ActionFooterCancelButtonProps &
  ActionFooterSubmitButtonProps;

const baseClassName = 'action-footer';
const CLASS_NAMES = {
  base: baseClassName,
  divider: classNames(`${baseClassName}__divider`, 'border-top-1px', 'border-base-lighter'),
  actionButtonsSection: classNames(`${baseClassName}__action-buttons-section`),
  cancelButton: classNames(`${baseClassName}__cancel-button`),
  submitButton: classNames(`${baseClassName}__submit-button`),
  soloSubmitButton: classNames(`${baseClassName}__submit-button––solo`, 'margin-top-2'),
};

function ActionFooter({
  className = undefined,
  disabled = false,
  hideCancelButton = false,
  onCancel = (): void => undefined,
  onSubmit = (): void => undefined,
  cancelButtonText = undefined,
  submitButtonText = undefined,
  cancelButton: customCancelButton = undefined,
  submitButton: customSubmitButton = undefined,
  submitButtonVariant = undefined,
  cancelTempDisabled = false,
  submitTempDisabled = false,
}: ActionFooterProps): React.JSX.Element {
  const submitButton: React.JSX.Element = customSubmitButton || (
    <Button
      className={CLASS_NAMES.submitButton}
      onClick={onSubmit}
      disabled={disabled || submitTempDisabled}
      variant={submitButtonVariant}
    >
      {submitButtonText || DEFAULT_SUBMIT_BUTTON_TEXT}
    </Button>
  );

  const cancelButton = customCancelButton || (
    <Button className={CLASS_NAMES.cancelButton} onClick={onCancel} variant="secondary" disabled={cancelTempDisabled}>
      {cancelButtonText || DEFAULT_CANCEL_BUTTON_TEXT}
    </Button>
  );

  return (
    <div className={classNames(CLASS_NAMES.base, className)}>
      <Divider className={CLASS_NAMES.divider} />
      <ActionSection className={CLASS_NAMES.actionButtonsSection}>
        {hideCancelButton ? (
          <div className={CLASS_NAMES.soloSubmitButton}>{submitButton}</div>
        ) : (
          <ButtonGroup>
            {cancelButton}
            {submitButton}
          </ButtonGroup>
        )}
      </ActionSection>
    </div>
  );
}

export default ActionFooter;
