/**
 * This enum serves as a simple reference for our logic,
 * and is by no means an exhaustive list of known eligibility statuses.
 */
// TODO: figure out why ESLint thinks this is being defined multiple times
/* eslint-disable-next-line no-shadow, import/prefer-default-export */
export enum CaseEligibilityStatus {
  FNC = 'FNC',
  NO_ACTION_FNC = 'NO_ACTION_FNC',
  EMPLOYMENT_AUTHORIZED = 'EMPLOYMENT_AUTHORIZED',
}
