import React from 'react';
import classNames from 'classnames';
import { CustomClassValue } from '../../../utils/types';
import { COLUMN_9_CLASS_NAME } from '../../../constants';
import { UploadDocumentType } from '../../../services/CaseApi';
import { mapUploadDocumentTypeToInfoCopy } from './util';

export interface UploadDocumentInfoSectionProps {
  className?: CustomClassValue;
  uploadDocumentType: UploadDocumentType | null;
}

const baseClassName = 'upload-document-info-section';
const CLASS_NAMES = {
  base: classNames(baseClassName, COLUMN_9_CLASS_NAME),
};

function UploadDocumentInfoSection({
  className = undefined,
  uploadDocumentType,
}: UploadDocumentInfoSectionProps): React.JSX.Element {
  const copy = mapUploadDocumentTypeToInfoCopy(uploadDocumentType);
  if (typeof copy === 'undefined') {
    return <div />;
  }
  return <div className={classNames(CLASS_NAMES.base, className)}>{copy}</div>;
}

export default UploadDocumentInfoSection;
