import React from 'react';
import classNames from 'classnames';
import { Alert } from '@ver-uds/uswds-react';
import { CustomClassValue } from '../../../../utils/types';
import { COLUMN_9_CLASS_NAME } from '../../../../constants';

export interface FailedAttemptErrorAlertProps {
  className?: CustomClassValue;
}

const baseClassName = 'failed-attempt-error-alert';
const CLASS_NAMES = {
  base: baseClassName,
  text: classNames(`${baseClassName}__text`, COLUMN_9_CLASS_NAME),
};

const failedAttemptErrorAlertStyling = {
  marginTop: '16px',
};

function FailedAttemptErrorAlert({ className = undefined }: FailedAttemptErrorAlertProps): React.JSX.Element {
  return (
    <div
      className={classNames(CLASS_NAMES.base, className)}
      role="alert"
      aria-atomic="true"
      style={failedAttemptErrorAlertStyling}
    >
      <Alert status="error">
        <Alert.Heading>Unable to Confirm Identity - What Happens Next</Alert.Heading>
        <Alert.Text className={CLASS_NAMES.text}>
          We were not able to confirm your identity at this time based on the information you provided. If you think the
          information you entered is incorrect, select “Try Again”. You will be able to review and change your
          information and another quiz will be generated for you. If you do not want to try again, select “Cancel” and
          you will be returned to myE-Verify homepage.
        </Alert.Text>
      </Alert>
    </div>
  );
}

export default FailedAttemptErrorAlert;
