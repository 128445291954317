import React from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import { CustomClassValue } from '../../../../../utils/types';
import { CitizenshipStatus } from '../../../../../services/SelfCheckApi';
import ReadOnlyFields from '../../../../Form/FormFields/ReadOnlyFields/ReadOnlyFields';

const FIELD_LABEL_MAP: Record<CitizenshipStatus, string> = {
  [CitizenshipStatus.US_CITIZEN]: 'Citizen of the United States',
  [CitizenshipStatus.US_NON_CITIZEN]: 'Noncitizen National of the United States',
  [CitizenshipStatus.PERMANENT_RESIDENT]: 'Lawful Permanent Resident',
  [CitizenshipStatus.NONCITIZEN_AUTHORIZED_TO_WORK]: 'Noncitizen Authorized to Work',
};

export interface CitizenshipStatusSectionProps {
  citizenshipStatus: CitizenshipStatus;
  className?: CustomClassValue;
}

const baseClassName = 'self-check-review-citizenship-status-section';
const CLASS_NAMES = {
  base: baseClassName,
  readOnlyFields: classNames(`${baseClassName}__read-only-fields`, 'margin-top-2'),
};

function CitizenshipStatusSection({
  citizenshipStatus,
  className = undefined,
}: CitizenshipStatusSectionProps): React.JSX.Element {
  return (
    <div className={classNames(CLASS_NAMES.base, className)}>
      <h2>Citizenship Status</h2>
      <ReadOnlyFields
        className={CLASS_NAMES.readOnlyFields}
        data={[
          {
            key: 'citizenshipStatus',
            name: 'Type',
            value: get(FIELD_LABEL_MAP, citizenshipStatus),
          },
        ]}
      />
    </div>
  );
}

export default CitizenshipStatusSection;
