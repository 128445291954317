import { useContext, useMemo } from 'react';
import { QueryObserverResult, useMutation, UseMutationResult, useQueries, useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import createError, { HttpError } from 'http-errors';
import get from 'lodash/get';
import { injectURLParams, normalizeURL } from '../../utils/routing';
import BaseApi, { useConfiguration } from '../BaseApi';
import paths from '../paths.json';
import { AuthenticationContext } from '../../context/Authentication/AuthenticationContext';
import { createEmptyFile } from '../../utils/files';
import { doGet, doPost, mapErrorToHttpError } from '../ServicesUtils';
import { DocumentUploadSubmissionFields } from '../../components/DocumentUpload/DocumentUploadForm/DocumentUploadFormMarkup';
import { RawCaseStatus, TncType } from './CaseApiConstants';

export interface CaseStatusData {
  caseStatus: RawCaseStatus;
}

// TODO: figure out why ESLint thinks this is being defined multiple times
/* eslint-disable-next-line no-shadow */
export enum UploadDocumentType {
  US_PASSPORT = 'US_PASSPORT',
  DRIVERS_LICENSE = 'DRIVERS_LICENSE',
  NATZ = 'NATZ',
  UNKNOWN = 'UNKNOWN',
}

export interface CaseTncTypeData {
  tncType: TncType;
  uploadDocumentType: UploadDocumentType | null;
}

export interface Case {
  date: string;
  caseNumber: string;
  companyName: string;
  state: string | null;
  caseStatus: RawCaseStatus | null;
  type: string;
}

export interface CaseUploads {
  count: number;
}

export interface CaseStatusResponse {
  data: CaseStatusData;
}

export interface CaseTncTypeResponse {
  data: CaseTncTypeData;
}

export interface CasesResponse {
  data: Case[];
}

export interface CaseUploadsResponse {
  data: CaseUploads;
}

class CaseApi extends BaseApi {
  private getCaseTrackerEndpoint(caseNumber: string): string {
    const pathWithParams = injectURLParams(paths.cases.getCaseStatus, { caseNumber });
    return normalizeURL(`${this.configuration.basePath ?? ''}${pathWithParams}`);
  }

  private getCaseTncTypeEndpoint(caseNumber: string): string {
    const pathWithParams = injectURLParams(paths.cases.getCaseTncType, { caseNumber });
    return normalizeURL(`${this.configuration.basePath ?? ''}${pathWithParams}`);
  }

  private getCasesEndpoint(): string {
    return this.getEndpoint(paths.cases.getCases);
  }

  private getOpenSelfCheckCaseEndpoint(): string {
    return normalizeURL(`${this.configuration.basePath ?? ''}/${paths.cases.getOpenSelfCheckCases}`);
  }

  private getUploadCaseDocumentsEndpoint(caseNumber: string): string {
    const pathWithParams = injectURLParams(paths.cases.uploadCaseDocuments, { caseNumber });
    return normalizeURL(`${this.configuration.basePath ?? ''}${pathWithParams}`);
  }

  private getCaseUploadsEndpoint(caseNumber: string): string {
    const pathWithParams = injectURLParams(paths.cases.getCaseUploads, { caseNumber });
    return normalizeURL(`${this.configuration.basePath ?? ''}/${pathWithParams}`);
  }

  async getCaseStatus(caseNumber: string): Promise<CaseStatusData> {
    const endpoint: string = this.getCaseTrackerEndpoint(caseNumber);
    try {
      const resp: AxiosResponse<CaseStatusResponse> = await axios.get(endpoint);
      return get(resp, 'data.data');
    } catch (err) {
      throw createError(get(err, 'response.status', 500), (err as HttpError).message);
    }
  }

  async getCaseTncType(caseNumber: string): Promise<CaseTncTypeData> {
    const endpoint: string = this.getCaseTncTypeEndpoint(caseNumber);
    try {
      const resp: AxiosResponse<CaseTncTypeResponse> = await axios.get(endpoint, {
        withCredentials: true,
      });
      return get(resp, 'data.data');
    } catch (err) {
      throw createError(get(err, 'response.status', 500), (err as HttpError).message);
    }
  }

  async getCases(): Promise<Case[]> {
    const endpoint = this.getCasesEndpoint();
    try {
      return (await doGet<CasesResponse>(endpoint)) || [];
    } catch (err) {
      throw mapErrorToHttpError(err);
    }
  }

  async getOpenSelfCheckCase(): Promise<Case | undefined> {
    const endpoint = this.getOpenSelfCheckCaseEndpoint();
    try {
      const resp: AxiosResponse<CasesResponse> = await axios.get(endpoint, {
        withCredentials: true,
      });
      return resp.data?.data[0] ?? null;
    } catch (err) {
      throw createError(get(err, 'response.status', 500), (err as HttpError).message);
    }
  }

  async uploadCaseDocuments(
    caseNumber: string,
    files: File[],
    phoneNumber: string,
    emailAddress: string,
  ): Promise<void> {
    const url = this.getUploadCaseDocumentsEndpoint(caseNumber);
    try {
      if (files.length < 1 || files.length > 2) {
        throw createError(400);
      }
      const formData = new FormData();
      /**
       * Need to send two files even if "second" is not provided by user.
       * API will treat empty files as not provided.
       */
      formData.append('secondDocument', createEmptyFile());

      const payload: FormData = files.reduce((formDataAccumulator, file, i) => {
        if (i === 0) {
          formDataAccumulator.append('firstDocument', file);
        }
        if (i === 1) {
          formDataAccumulator.set('secondDocument', file);
        }
        return formDataAccumulator;
      }, formData);
      payload.append('phoneNumber', phoneNumber);
      payload.append('emailAddress', emailAddress);
      await doPost(url, payload);
    } catch (err) {
      throw createError(get(err, 'status', get(err, 'response.status', 500)));
    }
  }

  async getCaseUploads(caseNumber: string): Promise<CaseUploads | undefined> {
    const endpoint = this.getCaseUploadsEndpoint(caseNumber);
    try {
      const resp: AxiosResponse<CaseUploads> = await axios.get(endpoint, {
        withCredentials: true,
      });
      return get(resp, 'data.data');
    } catch (err) {
      throw createError(get(err, 'response.status', 500), (err as HttpError).message);
    }
  }
}

const CASE_NUMBER_VALID_REGEX = /^\d{13}[A-Za-z]{2}$/;
export const isCaseNumberValid = (caseNumber: string | undefined): boolean =>
  typeof caseNumber === 'string' && CASE_NUMBER_VALID_REGEX.test(caseNumber);

const useCaseApi = (): CaseApi => {
  const configuration = useConfiguration();
  const authContext = useContext(AuthenticationContext);
  authContext.refreshLastAuthenticatedTime();
  return useMemo(() => new CaseApi(configuration), [configuration]);
};

export const getCaseStatusQueryID = 'getCaseStatus';
export const useCaseTrackerQuery = (caseNumber: string | undefined): QueryObserverResult<CaseStatusData> => {
  const api = useCaseApi();

  return useQuery({
    queryKey: [getCaseStatusQueryID, caseNumber],
    queryFn: () => api.getCaseStatus(caseNumber || ''),
    enabled: isCaseNumberValid(caseNumber) && api.isConfigurationValid(),
  });
};

export const getCaseTncTypeQueryId = 'getCaseTncType';
export const useCaseTncTypeQuery = (caseNumber: string | null): QueryObserverResult<CaseTncTypeData> => {
  const api = useCaseApi();

  return useQuery({
    queryKey: [getCaseTncTypeQueryId, caseNumber],
    queryFn: () => api.getCaseTncType(caseNumber || ''),
    enabled: api.isConfigurationValid(),
  });
};

export const getCasesQueryId = 'getCases';
export const useGetCasesQuery = (): QueryObserverResult<Case[]> => {
  const api = useCaseApi();
  const { isAuthenticated } = useContext(AuthenticationContext);

  return useQuery({
    queryKey: [getCasesQueryId],
    queryFn: () => api.getCases(),
    enabled: isAuthenticated() && api.isConfigurationValid(),
  });
};

export const getOpenSelfCheckCaseQueryID = 'getOpenSelfCheckCase';
export const useGetOpenSelfCheckCaseQuery = (): QueryObserverResult<Case | undefined> => {
  const api = useCaseApi();
  const { isAuthenticated } = useContext(AuthenticationContext);

  return useQuery({
    queryKey: [getOpenSelfCheckCaseQueryID],
    queryFn: async () => {
      const resp = api.getOpenSelfCheckCase();
      if (!resp) {
        return null;
      }
      return resp;
    },
    enabled: isAuthenticated() && api.isConfigurationValid(),
  });
};

export const useUploadCaseDocumentsMutationId = 'uploadCaseDocuments';
export const useUploadCaseDocumentsMutation = (
  caseNumber: string,
): UseMutationResult<void, Error, DocumentUploadSubmissionFields, void> => {
  const api = useCaseApi();
  const { isAuthenticated } = useContext(AuthenticationContext);

  return useMutation({
    mutationKey: [useUploadCaseDocumentsMutationId, caseNumber],
    mutationFn: ({ files, phoneNumber, emailAddress }) => {
      if (!api.isConfigurationValid()) {
        throw createError(404);
      }
      if (!isAuthenticated) {
        throw createError(403);
      }
      return api.uploadCaseDocuments(caseNumber, files, phoneNumber, emailAddress);
    },
  });
};

export const getCaseUploadsQueryId = 'getCaseUploads';
export const useGetCaseUploadsQuery = (caseNumber: string): QueryObserverResult<CaseUploads> => {
  const api = useCaseApi();
  const { isAuthenticated } = useContext(AuthenticationContext);
  return useQuery({
    queryKey: [getCaseUploadsQueryId, caseNumber],
    queryFn: () => api.getCaseUploads(caseNumber),
    enabled: isAuthenticated() && api.isConfigurationValid(),
    /**
     * Case upload counts are required for the open cases table.
     * In order to avoid waiting for fetches on every pagination change, the old value may be used during loading.
     */
    // placeholderData: undefined, // TODO: thoroughly test this!!!! // Use previous data while new data loads
    // staleTime: 1000 * 60 * 5, // Keep data fresh for 5 min to reduce refetch frequency
  });
};

export const useGetCaseUploadsQueries = (caseNumbers: string[]): QueryObserverResult<CaseUploads>[] => {
  const api = useCaseApi();
  const { isAuthenticated } = useContext(AuthenticationContext);

  return useQueries({
    queries: caseNumbers.map((caseNumber) => ({
      enabled: isAuthenticated() && api.isConfigurationValid() && caseNumbers.length > 0,
      queryKey: ['getCaseUploadsQueryId', caseNumber],
      queryFn: (): Promise<CaseUploads | undefined> => api.getCaseUploads(caseNumber),
      keepPreviousData: true,
    })),
  });

  // Legacy version of the above code
  // return useQueries(
  //   caseNumbers.map((caseNumber) => {
  //     return {
  //       enabled: isAuthenticated() && api.isConfigurationValid(),
  //       queryKey: [getCaseUploadsQueryId, caseNumber],
  //       queryFn: (): Promise<CaseUploads | undefined> => api.getCaseUploads(caseNumber),
  //       keepPreviousData: true,
  //     };
  //   }),
  // ) as UseQueryResult<CaseUploads>[];
};
