import { Checkbox } from '@ver-uds/react';
import classNames from 'classnames';
import React from 'react';
import { useForm } from 'react-hook-form';
import { ErrorMessage, Fieldset, Form, FormGroup } from '@ver-uds/uswds-react';
import { COLUMN_9_CLASS_NAME } from '../../../constants';
import { hasAcceptedTermsAndConditionsFieldName } from '../../../services/UserApi';
import { ErrorMessages, getFieldError } from '../../../utils/forms';
import ActionFooter from '../../ActionFooter/ActionFooter';

const LABEL_TEXT =
  'By checking this box, I represent that I am authorized to accept the Terms of Service and that I have read, understand, and agree to abide by them. I understand that I am providing ‘written instructions’ to USCIS under the Fair Credit Reporting Act authorizing USCIS to obtain information from my personal credit report or other information from Experian. I authorize USCIS to obtain such information solely to validate my identity for the purposes of using myE-Verify and its features.';
export interface TermsAndConditionsFormFields {
  [hasAcceptedTermsAndConditionsFieldName]: boolean;
}

const errorMessages: ErrorMessages = {
  required: 'You must agree to the terms by checking the checkbox below before proceeding.',
};

const baseClassName = 'terms-and-conditions-form';
export const CLASS_NAMES = {
  base: baseClassName,
  form: classNames(`${baseClassName}__form}`, 'margin-top-3', 'margin-bottom-4', 'maxw-none'),
  checkbox: classNames(`${baseClassName}__checkbox`, COLUMN_9_CLASS_NAME),
};

export interface TermsAndConditionsFormProps {
  onSubmit: (data: TermsAndConditionsFormFields) => void;
  onCancel: () => void;
}

function TermsAndConditionsForm({ onSubmit, onCancel }: TermsAndConditionsFormProps): React.JSX.Element {
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<TermsAndConditionsFormFields>({
    mode: 'onSubmit',
  });

  const handleFormSubmit = handleSubmit((data) => {
    onSubmit(data);
  });

  const agreeToTermsAndConditionsErrorMessage = getFieldError(
    errors[hasAcceptedTermsAndConditionsFieldName],
    errorMessages,
  );

  return (
    <>
      <Form className={classNames(baseClassName, CLASS_NAMES.form)}>
        <FormGroup error={agreeToTermsAndConditionsErrorMessage}>
          <Fieldset>
            {agreeToTermsAndConditionsErrorMessage && (
              <ErrorMessage id="terms-and-conditions-error">{agreeToTermsAndConditionsErrorMessage}</ErrorMessage>
            )}
            <Checkbox
              className={CLASS_NAMES.checkbox}
              required
              id="agree-to-terms-and-conditions"
              label={LABEL_TEXT}
              {...register(hasAcceptedTermsAndConditionsFieldName, { required: true })}
            />
          </Fieldset>
        </FormGroup>
      </Form>
      <ActionFooter submitButtonText="Next" onCancel={onCancel} onSubmit={handleFormSubmit} />
    </>
  );
}

export default TermsAndConditionsForm;
