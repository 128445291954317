import React from 'react';
import { Control, Controller } from 'react-hook-form';
import classNames from 'classnames';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import omit from 'lodash/omit';
import { FieldGroup } from '@ver-uds/react';
import { DropdownControl, TextInputControl } from '@ver-uds/uswds-react';
import { EMPTY_VAL_CHAR } from '../../../constants';
import { sortByKeyToMatchList } from '../../../utils/collections';
import { ValidateRules } from '../../../utils/forms';
import { CustomClassValue } from '../../../utils/types';
import FormattedDateInput, { FormattedDateInputProps } from '../FormattedDateInput/FormattedDateInput';

// TODO: figure out why ESLint thinks this is being defined multiple times
/* eslint-disable-next-line no-shadow */
export enum FieldType {
  DATE = 'DATE',
  READ_ONLY = 'READ_ONLY',
  SELECT = 'SELECT',
  TEXT = 'TEXT',
  CUSTOM = 'CUSTOM',
}

export interface FieldData {
  className?: CustomClassValue;
  key?: string;
  label?: string;
  name: string;
  fieldType: FieldType;
}

interface ReadOnlyFieldDataInterface extends FieldData {
  value?: string | boolean;
}

export type ReadOnlyFieldData = ReadOnlyFieldDataInterface & {
  fieldType: FieldType.READ_ONLY;
};

type SelectFieldRef = React.Ref<HTMLSelectElement>;
type TextFieldRef = React.Ref<HTMLInputElement>;
export type FormFieldRef = SelectFieldRef | TextFieldRef;

export interface BaseFormFieldData extends FieldData {
  defaultValue?: string;
  disabled?: boolean;
  errorMessage?: string;
  hint?: string;
  id: string;
  label: string;
  labelHint?: string;
  ref: FormFieldRef;
  required?: boolean;
}

export type TextFormFieldData = BaseFormFieldData & {
  fieldType: FieldType.TEXT;
};

export interface SelectFormFieldOption {
  label: string;
  value: string;
}

interface SelectFormFieldDataInterface extends BaseFormFieldData {
  options: SelectFormFieldOption[];
}

export type SelectFormFieldData = SelectFormFieldDataInterface & {
  fieldType: FieldType.SELECT;
};

export type DateFormFieldData = FormattedDateInputProps &
  Omit<BaseFormFieldData, 'defaultValue' | 'ref'> & {
    fieldType: FieldType.DATE;
    validate?: ValidateRules;
    control: Control;
  };

interface CustomFormFieldDataInterface extends Partial<BaseFormFieldData> {
  options?: SelectFormFieldOption[];
}

export type CustomFormFieldData = CustomFormFieldDataInterface & {
  render: (props: CustomFormFieldData) => React.JSX.Element;
  name: string;
  fieldType: FieldType.CUSTOM;
};

export type FormFieldData =
  | ReadOnlyFieldData
  | TextFormFieldData
  | SelectFormFieldData
  | DateFormFieldData
  | CustomFormFieldData;

export interface FormFieldsProps {
  data?: FormFieldData[] | undefined;
  sortOrder?: string[];
  tempDisabled?: boolean;
}

const baseClassName = 'form-fields';
const CLASS_NAMES = {
  base: baseClassName,
  field: `${baseClassName}__field`,
};

const getSortKey = (data?: FieldData[]): 'key' | 'name' => (!isUndefined(get(data, [0, 'key'])) ? 'key' : 'name');

const getSortedFields = (data: FieldData[] = [], sortOrder: string[] = []): FieldData[] =>
  sortByKeyToMatchList(data, sortOrder, getSortKey(data)) as FieldData[];

// 08-02-2024 TODO: Refactor this entire file as these should not be optional variables based on the current setup
function FormFields({
  data = undefined,
  sortOrder = undefined,
  tempDisabled = false,
}: FormFieldsProps): React.JSX.Element {
  const fields = getSortedFields(data, sortOrder);
  return (
    <>
      {fields.map(({ className: fieldClassName, key, name, fieldType, ...rest }) => (
        <div key={key || name} className={classNames(CLASS_NAMES.field, fieldClassName)}>
          {fieldType === FieldType.READ_ONLY && ( // Can assume field definition is ReadOnlyFieldData
            <FieldGroup.Field key={!isUndefined(key) ? key : name} name={name}>
              {(rest as ReadOnlyFieldData).value || EMPTY_VAL_CHAR}
            </FieldGroup.Field>
          )}
          {fieldType === FieldType.TEXT && ( // Can assume field definition is TextFormFieldData
            <TextInputControl
              id={(rest as TextFormFieldData).id}
              name={name}
              label={(rest as TextFormFieldData).label}
              labelHint={(rest as TextFormFieldData).labelHint}
              disabled={(rest as TextFormFieldData).disabled || tempDisabled}
              hint={!(rest as TextFormFieldData).errorMessage ? (rest as TextFormFieldData).hint : undefined}
              errorId={
                (rest as TextFormFieldData).errorMessage ? `${(rest as TextFormFieldData).id}--error` : undefined
              }
              errorMessage={(rest as TextFormFieldData).errorMessage}
              defaultValue={(rest as TextFormFieldData).defaultValue}
              required={(rest as DateFormFieldData).required}
              ref={(rest as TextFormFieldData).ref as TextFieldRef}
            />
          )}
          {fieldType === FieldType.SELECT && ( // Can assume field definition is SelectFormFieldData
            <DropdownControl
              id={(rest as SelectFormFieldData).id}
              name={name}
              label={(rest as SelectFormFieldData).label}
              labelHint={(rest as SelectFormFieldData).labelHint}
              disabled={isEmpty((rest as SelectFormFieldData).options) || (rest as SelectFormFieldData).disabled}
              errorId={
                (rest as SelectFormFieldData).errorMessage ? `${(rest as SelectFormFieldData).id}--error` : undefined
              }
              errorMessage={(rest as SelectFormFieldData).errorMessage}
              defaultValue={(rest as SelectFormFieldData).defaultValue}
              required={(rest as DateFormFieldData).required}
              ref={(rest as SelectFormFieldData).ref as SelectFieldRef}
            >
              <option value="">- Select -</option>
              {(rest as SelectFormFieldData).options.map(({ label, value }) => (
                <option value={value} key={value}>
                  {label}
                </option>
              ))}
            </DropdownControl>
          )}
          {fieldType === FieldType.DATE && ( // Can assume field definition is DateFormFieldData
            <Controller
              control={(rest as DateFormFieldData).control}
              name={name}
              rules={{
                required: (rest as DateFormFieldData).required,
                validate: (rest as DateFormFieldData).validate,
              }}
              render={({ field }): React.JSX.Element => (
                <FormattedDateInput
                  /* FormattedDateInput requires custom top margin on parent block: https://github.com/uswds/uswds/issues/4153 */
                  className="margin-top-3"
                  id={(rest as DateFormFieldData).id}
                  name={name}
                  label={(rest as DateFormFieldData).label}
                  hint={!(rest as DateFormFieldData).errorMessage ? (rest as DateFormFieldData).hint : undefined}
                  errorMessage={(rest as DateFormFieldData).errorMessage}
                  formattedDate={field.value}
                  onUpdate={field.onChange}
                  defaultValue={(rest as DateFormFieldData).defaultValue}
                  required={(rest as DateFormFieldData).required}
                />
              )}
            />
          )}
          {fieldType === FieldType.CUSTOM && // Can assume field definition is CustomFormFieldData
            (rest as CustomFormFieldData).render({
              ...(omit(rest, 'render') as CustomFormFieldData),
              name,
            })}
        </div>
      ))}
    </>
  );
}
export default FormFields;
