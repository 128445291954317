import React from 'react';
import classNames from 'classnames';
import { CustomClassValue } from '../../utils/types';

export interface ImageProps {
  alt: string;
  src: string;
}

export interface ImageWrapperProps {
  className?: CustomClassValue;
  images: ImageProps[];
  minColumnSize?: number;
}

const baseClassName = 'image-wrapper';
const CLASS_NAMES = {
  base: classNames(baseClassName, 'grid-row', 'grid-gap', 'margin-top-0'),
  image: classNames(`${baseClassName}__image`, 'grid-col-9', 'margin-top-2'),
  defaultColumnSize: 'tablet:grid-col-5',
};

function ImageWrapper({
  className = undefined,
  images,
  minColumnSize = undefined,
}: ImageWrapperProps): React.JSX.Element {
  return (
    <div className={classNames(CLASS_NAMES.base, className)}>
      {images.map(({ src, alt }) => (
        <img
          className={classNames(
            CLASS_NAMES.image,
            minColumnSize ? `tablet:grid-col-${minColumnSize}` : CLASS_NAMES.defaultColumnSize,
          )}
          src={src}
          alt={alt}
          key={alt}
        />
      ))}
    </div>
  );
}

export default ImageWrapper;
