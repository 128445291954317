import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { ErrorMessage, Fieldset, Form, FormGroup, Legend, RadioButton } from '@ver-uds/uswds-react';
import { CustomClassValue } from '../../../../../../../../../../utils/types';
import ActionFooter from '../../../../../../../../../ActionFooter/ActionFooter';
import { COLUMN_9_CLASS_NAME } from '../../../../../../../../../../constants';
import { getFieldError } from '../../../../../../../../../../utils/forms';
import { ActionType } from '../constants';
import SelfCheckCaseDetailPendingRefActionSectionModal from '../modal';

export const TNC_ACTION_SELECTION_REQUIRED_ERR_MSG = 'Please select an option.';

interface ActionOption {
  label: string;
  name: ActionType;
}

const ACTION_OPTIONS: ActionOption[] = [
  {
    label: `I will take action to resolve this case. I understand that I have 8 federal working days to take action.`,
    name: ActionType.TAKE_ACTION,
  },
  {
    label:
      'I will NOT take action to resolve this case. I understand this will close my case and that my employment eligibility will not be confirmed at this time.',
    name: ActionType.NO_ACTION,
  },
  {
    label: 'The information entered was incorrect. I would like to close this case so that I may open a new one.',
    name: ActionType.INCORRECT_DATA,
  },
];

export const SUBMIT_BUTTON_TEXT = 'Confirm Resolution Decision';

const tncActionRadioFieldName = 'tncAction';
interface TNCActionFormData {
  [tncActionRadioFieldName]: ActionType;
}

export interface SelfCheckCaseDetailPendingRefActionSectionFormProps {
  className?: CustomClassValue;
  onSubmit: (actionType: ActionType) => void;
}

const baseClassName = 'tnc-action-form';
const CLASS_NAMES = {
  base: classNames(baseClassName),
  form: classNames(`${baseClassName}__form`, 'maxw-none', COLUMN_9_CLASS_NAME),
  formGroupLabel: classNames(
    `${baseClassName}__form-group-label`,
    'float-left',
    'margin-top-0',
    'maxw-none',
    'font-family-heading',
  ),
  formGroupLabelWrapper: classNames(
    `${baseClassName}__form-group-label-wrapper`,
    'usa-legend',
    'margin-top-0',
    'maxw-none',
  ),
  formGroupLabelRequiredText: classNames(
    `${baseClassName}__form-group-label-required-text`,
    'usa-hint',
    'margin-left-05',
  ),
  actionSection: classNames(`${baseClassName}__action-section`, 'margin-top-4'),
};

function SelfCheckCaseDetailPendingRefActionSectionForm({
  className = undefined,
  onSubmit,
}: SelfCheckCaseDetailPendingRefActionSectionFormProps): React.JSX.Element {
  const [isConfiModalOpen, setIsConfiModalOpen] = useState<boolean>(false);
  const {
    getValues,
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<TNCActionFormData>();

  const handleConfModalClose = (): void => setIsConfiModalOpen(false);

  const handleInitialFormSubmit = handleSubmit((): void => {
    setIsConfiModalOpen(true);
  });

  const handleConfirmationFormSubmit = handleSubmit((formData: TNCActionFormData) => {
    handleConfModalClose();
    onSubmit(formData[tncActionRadioFieldName]);
  });

  const errorMessage = getFieldError(errors[tncActionRadioFieldName], {
    required: TNC_ACTION_SELECTION_REQUIRED_ERR_MSG,
  });

  return (
    <div className={classNames(CLASS_NAMES.base, className)}>
      <Form className={classNames(CLASS_NAMES.form)} onSubmit={handleInitialFormSubmit} variant="large">
        <FormGroup error={errorMessage}>
          <Fieldset>
            <div className={CLASS_NAMES.formGroupLabelWrapper}>
              <Legend className={CLASS_NAMES.formGroupLabel}>
                Step 2: How would you like to proceed with your case resolution?
              </Legend>
              <span className={CLASS_NAMES.formGroupLabelRequiredText} aria-hidden="true">
                (required)
              </span>
            </div>
            {errorMessage && <ErrorMessage id="tnc-action-error">{errorMessage}</ErrorMessage>}
            {ACTION_OPTIONS.map(({ label, name }) => (
              <RadioButton
                key={name}
                id={name}
                label={label}
                value={name}
                {...register(tncActionRadioFieldName, { required: true })}
                aria-required="true"
              />
            ))}
          </Fieldset>
        </FormGroup>
      </Form>
      <ActionFooter
        className={CLASS_NAMES.actionSection}
        hideCancelButton
        onSubmit={handleInitialFormSubmit}
        submitButtonText={SUBMIT_BUTTON_TEXT}
      />
      <SelfCheckCaseDetailPendingRefActionSectionModal
        actionType={getValues(tncActionRadioFieldName)}
        isOpen={isConfiModalOpen}
        onClose={handleConfModalClose}
        onConfirm={handleConfirmationFormSubmit}
      />
    </div>
  );
}

export default SelfCheckCaseDetailPendingRefActionSectionForm;
