import React from 'react';
import classNames from 'classnames';
import { Alert } from '@ver-uds/uswds-react';
import { Button, ButtonGroup, Modal } from '@ver-uds/react';
import { CustomClassValue } from '../../../../../../../../../../utils/types';
import { ActionType } from '../constants';

export const CANCEL_BUTTON_TEXT = 'Cancel';
export const CONFIRM_BUTTON_TEXT = 'Yes, Confirm';

export const confirmationText: Record<ActionType, string> = {
  [ActionType.TAKE_ACTION]: 'Yes, I intend to take action to resolve this case within 8 federal working days.',
  [ActionType.NO_ACTION]:
    'If you choose not to take action to resolve this case, you will receive a final case result that indicates we were unable to confirm employment eligibility.',
  [ActionType.INCORRECT_DATA]:
    'Yes, I would like to close this case and create a new one. The information entered is incorrect.',
};

export interface SelfCheckCaseDetailPendingRefActionSectionModalProps {
  actionType: ActionType;
  className?: CustomClassValue;
  isOpen?: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const baseClassName = 'tnc-action-form-confirmation-modal';
const CLASS_NAMES = {
  base: baseClassName,
};

function SelfCheckCaseDetailPendingRefActionSectionModal({
  actionType,
  className = undefined,
  isOpen = false,
  onClose,
  onConfirm,
}: SelfCheckCaseDetailPendingRefActionSectionModalProps): React.JSX.Element {
  return (
    <div className={classNames(CLASS_NAMES.base, className)}>
      <Modal id={CLASS_NAMES.base} header="Confirm Resolution Decision" open={isOpen} close={onClose}>
        <Modal.Content>
          <div role="alert" aria-atomic="true">
            <Alert status="warning">
              <Alert.Heading>Are you sure?</Alert.Heading>
              <Alert.Text>{confirmationText[actionType]}</Alert.Text>
            </Alert>
          </div>
        </Modal.Content>
        <Modal.Footer>
          <ButtonGroup>
            <Button type="button" variant="secondary" onClick={onClose}>
              {CANCEL_BUTTON_TEXT}
            </Button>
            <Button type="button" variant="primary" onClick={onConfirm}>
              {CONFIRM_BUTTON_TEXT}
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SelfCheckCaseDetailPendingRefActionSectionModal;
