import React from 'react';
import classNames from 'classnames';
import { CustomClassValue } from '../../../../../../../../../utils/types';
import { StartNewCaseActionFooter } from '../shared';

export interface SelfCheckCaseDetailUnconfirmedActionSectionProps {
  className?: CustomClassValue;
  caseNumber: string;
}

const baseClassName = 'case-detail-self-check-action-section--unconfirmed';
const CLASS_NAMES = {
  base: baseClassName,
};

function SelfCheckCaseDetailUnconfirmedActionSection({
  className = undefined,
  caseNumber,
}: SelfCheckCaseDetailUnconfirmedActionSectionProps): React.JSX.Element {
  return (
    <StartNewCaseActionFooter
      className={classNames(CLASS_NAMES.base, className)}
      caseNumber={caseNumber}
      shouldCloseCaseOnSubmit
    />
  );
}

export default SelfCheckCaseDetailUnconfirmedActionSection;
