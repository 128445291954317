import React from 'react';
import classNames from 'classnames';
import { CustomClassValue } from '../../../utils/types';

export interface DocumentToUploadLabelProps {
  className?: CustomClassValue;
}

const baseClassName = 'document-to-upload-label';
const CLASS_NAMES = {
  base: baseClassName,
};

function DocumentToUploadLabel({ className = undefined }: DocumentToUploadLabelProps): React.JSX.Element {
  return <strong className={classNames(CLASS_NAMES.base, className)}>Document to Upload: </strong>;
}

export default DocumentToUploadLabel;
