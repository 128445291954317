import get from 'lodash/get';
import React from 'react';
import { Navigate } from 'react-router-dom';
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner';
import { requestedRouteKey } from '../constants';
import { useEnvironment } from '../context/Environment/EnvironmentContext';
import { redirectTo } from '../utils/routing';
import { setLocalStorageItem } from '../utils/storage';
import paths from './paths';
import { CustomRouteProps } from './routes';

interface PrivateRouteProps extends CustomRouteProps {
  // eslint-disable-next-line react/require-default-props
  hasCompletedIdProofing?: boolean;
  isPublic: boolean;
  isAuthenticated: boolean;
  isLoading: boolean;
}

function PrivateRoute({
  path,
  hasCompletedIdProofing = undefined,
  isPublic,
  isAuthenticated,
  isLoading,
  children,
}: PrivateRouteProps): React.JSX.Element {
  const { isLoading: isEnvironmentLoading, environmentVariables } = useEnvironment();

  if (isLoading || isEnvironmentLoading) {
    return <LoadingSpinner />;
  }

  if (path === paths.createAccount && hasCompletedIdProofing) {
    return <Navigate to={paths.home} />;
  }

  const requestedRoute = localStorage.getItem(requestedRouteKey);
  if (requestedRoute) {
    localStorage.removeItem(requestedRouteKey);
    if (isAuthenticated) {
      return <Navigate to={requestedRoute} />;
    }
  }

  if (isPublic || isAuthenticated) {
    return <div>{children}</div>;
  }

  const loginRoute = get(environmentVariables, 'MYEVERIFY_WEB_SERVICE_LOGIN', paths.home);
  if (requestedRouteKey && loginRoute !== paths.home) {
    setLocalStorageItem(requestedRouteKey, path);
  }

  redirectTo(loginRoute);

  return <div />;
}

export default PrivateRoute;
