import React from 'react';
import PageContentLayout from '../layouts/PageContentLayout/PageContentLayout';
import SelfCheck from '../components/SelfCheck/SelfCheck';

function SelfCheckPage(): React.JSX.Element {
  return (
    <PageContentLayout title="Self Check">
      <SelfCheck />
    </PageContentLayout>
  );
}

export default SelfCheckPage;
