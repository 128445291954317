import React from 'react';
import classNames from 'classnames';
import { PaginationTable } from '@ver-uds/react';
import isEmpty from 'lodash/isEmpty';
import { CustomClassValue } from '../../../utils/types';
import { Case } from '../../../services/CaseApi';
import CaseHistoryEmptySection from './CaseHistoryEmptySection';
import { openCasesColumns, closedCasesColumns, TableData } from './CaseHistoryTableColumns';

export interface CaseHistoryTableProps {
  id: string;
  className?: CustomClassValue;
  cases: Case[];
  type: 'open' | 'closed';
}

const baseClassName = 'case-history-table';
const CLASS_NAMES = {
  base: baseClassName,
  emptySection: classNames(`${baseClassName}__empty`, 'margin-top-4'),
};

function CaseHistoryTable({ className = undefined, cases, type, id }: CaseHistoryTableProps): React.JSX.Element {
  const { data: visibleCases } = {
    data: cases as TableData[],
  };

  if (isEmpty(visibleCases)) {
    return <CaseHistoryEmptySection className={CLASS_NAMES.emptySection} caseDescriptor={type} />;
  }

  const initialPageSize = 5;

  return (
    <div className={classNames(CLASS_NAMES.base, className)}>
      <PaginationTable
        id={id}
        caption={type === 'open' ? 'Open Cases Table' : 'Case History Table'}
        columns={type === 'open' ? openCasesColumns : closedCasesColumns}
        data={cases as Array<any>}
        variant="default"
        sortable
        initialState={{
          pageIndex: 0,
          pageSize: initialPageSize,
        }}
        pageSizeOptions={[initialPageSize, 10, 20, 50]}
      />
    </div>
  );
}

export default CaseHistoryTable;
