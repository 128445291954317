import React from 'react';
import { Accordion } from '@ver-uds/uswds-react';
import HelpSectionContent from '../HelpSectionContent';

const baseClassName = 'uscis-number-help-section';
const CLASS_NAMES = {
  heading: `${baseClassName}__heading`,
  content: `${baseClassName}__content`,
};

function USCISANumberHelpSection(): React.JSX.Element {
  return (
    <>
      <Accordion.Heading className={CLASS_NAMES.heading} controls={baseClassName}>
        Help with USCIS A-Number
      </Accordion.Heading>
      <HelpSectionContent id={baseClassName} className={CLASS_NAMES.content}>
        <p>
          The USCIS A-Number is a unique 7-to 9-digit number assigned to a noncitizen by the Department of Homeland
          Security.
        </p>
        <p>
          The USCIS A-Number must be entered as nine (9) digits. If the "A" number is less than nine digits, you must
          prefix the number with leading zeros.
        </p>
        <p>Example: A72 735 827 should be input as 072735827</p>
      </HelpSectionContent>
    </>
  );
}

export default USCISANumberHelpSection;
