import React from 'react';
import classNames from 'classnames';
import { CustomClassValue } from '../../../../../../utils/types';
import TelephoneLink from '../../../../../Actions/TelephoneLink';

export interface SelfCheckUploadSuccessModalContentProps {
  className?: CustomClassValue;
}

const baseClassName = 'upload-success-modal-content--self-check';
const CLASS_NAMES = {
  base: baseClassName,
};

function SelfCheckUploadSuccessModalContent({
  className = undefined,
}: SelfCheckUploadSuccessModalContentProps): React.JSX.Element {
  return (
    <p className={classNames(CLASS_NAMES.base, className)}>
      We received your documents.{' '}
      <strong>You are required to call The U.S. Department of Homeland Security (DHS)</strong> at{' '}
      <TelephoneLink phoneNumber="855-804-0296" /> (TTY: <TelephoneLink phoneNumber="877-875-6028" />) to continue
      resolving this case.
    </p>
  );
}

export default SelfCheckUploadSuccessModalContent;
