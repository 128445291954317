import get from 'lodash/get';
import React from 'react';
import { CitizenshipStatus } from '../../../../services/SelfCheckApi';
import SelfCheckFormCitizenshipStatusMarkup, {
  SelfCheckFormCitizenshipStatusFormData,
} from './SelfCheckFormCitizenshipStatusMarkup';

export interface SelfCheckFormCitizenshipStatusProps {
  data?: CitizenshipStatus;
  onBack: () => void;
  onSubmit: (data: CitizenshipStatus) => void;
}

function SelfCheckFormCitizenshipStatus({
  data = undefined,
  onBack,
  onSubmit,
}: SelfCheckFormCitizenshipStatusProps): React.JSX.Element {
  return (
    <SelfCheckFormCitizenshipStatusMarkup
      data={data}
      onCancel={onBack}
      onSubmit={(formData: SelfCheckFormCitizenshipStatusFormData): void =>
        onSubmit(get(formData, 'citizenshipStatus'))
      }
    />
  );
}

export default SelfCheckFormCitizenshipStatus;
