import React, { useContext } from 'react';
import classNames from 'classnames';
import { Button } from '@ver-uds/react';
import { CustomClassValue } from '../../../utils/types';
import paths from '../../../routing/paths';
import { AuthenticationContext } from '../../../context/Authentication/AuthenticationContext';

export const RETURN_HOME_BUTTON_TEXT = 'Return Home';
export const UNAUTHENTICATED_RETURN_HOME_BUTTON_TEXT = 'Return to myE-Verify homepage';
// NOTE: To future developer, there is an issue in which both @ver-uds/react and @ver-uds/uswds-react
// both have a version of "Button". They are similary, but only one has "leftIcon", which matches
// the expectations in the Storybook. Unforturnately, this doesn't expose 'variant' in a way that
// this code was originally attempting to do. This is the workaround to mimic the intended behavior
type ButtonVariants = 'primary' | 'secondary' | 'submit' | 'destructive' | 'inverse' | 'tertiary' | undefined;

export interface ReturnHomeButtonProps {
  className?: CustomClassValue;
  variant?: ButtonVariants;
}

const baseClassName = 'return-home-button';
const CLASS_NAMES = {
  base: baseClassName,
};

function ReturnHomeButton({ className = undefined, variant = 'secondary' }: ReturnHomeButtonProps): React.JSX.Element {
  const { isAuthenticated } = useContext(AuthenticationContext);

  return (
    <Button className={classNames(CLASS_NAMES.base, className)} as="a" href={paths.home} variant={variant}>
      {!isAuthenticated() ? UNAUTHENTICATED_RETURN_HOME_BUTTON_TEXT : RETURN_HOME_BUTTON_TEXT}
    </Button>
  );
}

export default ReturnHomeButton;
