import React from 'react';
import PageContentLayout from '../layouts/PageContentLayout/PageContentLayout';
import CaseDetail from '../components/CaseDetail/CaseDetail';

function CaseDetailPage(): React.JSX.Element {
  return (
    <PageContentLayout title="Case Detail">
      <CaseDetail />
    </PageContentLayout>
  );
}

export default CaseDetailPage;
