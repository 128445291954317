import React from 'react';
import classNames from 'classnames';
import { CustomClassValue } from '../../utils/types';

export interface DividerProps {
  className?: CustomClassValue;
}

function Divider({ className = undefined }: DividerProps): React.JSX.Element {
  return (
    <div
      className={classNames(`myeverify-divider`, 'border-top-1px', 'border-base-lighter', className)}
      role="separator"
    />
  );
}

export default Divider;
